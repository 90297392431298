import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { 
  Wine, 
  LogOut, 
  BarChart3, 
  QrCode, 
  Settings, 
  Palette, 
  Award,
  Users,
  Shield,
  CreditCard,
  FileText,
  Bell,
  Lock,
  ArrowRight,
  LifeBuoy
} from 'lucide-react';
import { useAuthContext } from '../contexts/AuthContext';
import LogoutConfirmation from './LogoutConfirmation';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useTickets } from '../hooks/useTickets';
import { PLAN_FEATURES, PlanType } from '../hooks/usePlanLimits'; 
import { Crown } from 'lucide-react';
import { CuveeStatus } from '../types/CuveeStatus';

const Sidebar: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, logout } = useAuthContext();
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null);
  const [exceededCount, setExceededCount] = useState(0);
  const [userPlan, setUserPlan] = useState<PlanType>('basic');
  const { unreadCount } = useTickets(user?.uid, isAdmin || false);

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (!user?.uid) {
        setIsAdmin(false);
        return;
      }
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        setIsAdmin(userDoc.data().role === 'admin');
        const plan = userDoc.data().plan || 'basic';
        setUserPlan(plan); 

        // Vérifier les cuvées dépassées uniquement pour le plan Basic
        if (plan === 'basic') {
          const cuveesRef = collection(db, 'cuvees');
          const q = query(
            cuveesRef,
            where('userId', '==', user.uid),
            where('status', '==', CuveeStatus.EXCEEDED)
          );
          const snapshot = await getDocs(q);
          setExceededCount(snapshot.size);
        }
      } else {
        setIsAdmin(false);
      }
    };
    checkAdminStatus();
  }, [user]);

  const handleLogoutClick = () => {
    setShowLogoutConfirmation(true);
  };

  const handleLogoutConfirm = async () => {
    try {
      await logout();
      setShowLogoutConfirmation(false);
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const adminMenuItems = [
    { icon: <BarChart3 size={20} />, label: 'Dashboard', path: '/admin/dashboard' },
    { icon: <Wine size={20} />, label: 'Cuvées', path: '/admin/cuvees' },
    { icon: <Users size={20} />, label: 'Utilisateurs', path: '/users' },
    { 
      icon: <LifeBuoy size={20} />, 
      label: 'Support', 
      path: '/admin/support',
      badge: unreadCount > 0 ? unreadCount : undefined
    },
    { icon: <Bell size={20} />, label: 'Notifications', path: '/admin/notifications' },
    { icon: <FileText size={20} />, label: 'Logs serveur', path: '/admin/logs' }
  ];

  const mainMenuItems = [
    { 
      icon: <BarChart3 size={20} />,
      label: 'Statistiques',
      path: '/statistics',
      requiresFeature: 'dashboard',
      redirectTo: '/upsells'
    },
    { icon: <Wine size={20} />, label: 'Mes Cuvées', path: '/cuvees' },
    { icon: <QrCode size={20} />, label: 'QR Codes', path: '/qr-codes' },
    { 
      icon: <Palette size={20} />, 
      label: 'Design', 
      path: '/design', 
      requiresFeature: 'design',
      redirectTo: '/upsells'
    }
  ];

  const bottomMenuItems = [
    { 
      icon: <LifeBuoy size={20} />, 
      label: 'Support', 
      path: '/support',
      badge: unreadCount > 0 ? unreadCount : undefined,
      requiresFeature: 'support',
      redirectTo: '/upsells'
    },
    { icon: <CreditCard size={20} />, label: 'Abonnement', path: '/subscription' },
    { icon: <Settings size={20} />, label: 'Paramètres', path: '/settings' }
  ];

  if (isAdmin === null) {
    return null;
  }

  const menuItems = isAdmin ? adminMenuItems : mainMenuItems;

  // Remove exceeded badge from sidebar completely
  const exceededBadge = null;

  return (
    <div className="hidden md:flex md:flex-shrink-0">
      <div className="flex flex-col w-64 h-screen">
        <div className="sidebar-container bg-white border-r border-gray-200">
          <div className="flex items-center h-16 flex-shrink-0 px-4 bg-purple-700">
            <div className="flex items-center space-x-2">
              {isAdmin ? (
                <>
                  <Shield className="h-8 w-8 text-white" />
                  <span className="text-white text-xl font-semibold">Admin</span>
                </>
              ) : (
                <>
                  <Wine className="h-8 w-8 text-white" />
                  <span className="text-white text-xl font-semibold">Qvee</span>
                </>
              )}
            </div>
          </div>
          
          <div className="sidebar-content">
            <nav className="flex-1 px-2 py-4 space-y-1">
              {menuItems.map((item) => {
                const isActive = location.pathname === item.path;
                // Check if user has access based on plan
                const isLocked = !isAdmin && item.requiresFeature && !PLAN_FEATURES[userPlan as PlanType][item.requiresFeature as keyof typeof PLAN_FEATURES[PlanType]];

                return (
                  <div key={item.path} className="relative group">
                    <Link
                      to={isLocked ? (item.redirectTo || '#') : item.path}
                      className={`${
                        isActive
                          ? 'bg-purple-50 text-purple-700'
                          : isLocked
                          ? 'text-gray-400 bg-gray-50 cursor-not-allowed'
                          : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                      } group flex items-center justify-between px-2 py-2 text-sm font-medium rounded-md transition-colors duration-150`}
                    >
                      <div className="flex items-center">
                        <div className="mr-3">{item.icon}</div>
                        <span>{item.label}</span>
                        {item.path === '/cuvees' && exceededBadge}
                      </div>
                      {isLocked && <Lock className="h-4 w-4 text-[#9333EA]" />}
                      {item.badge !== undefined && !isLocked && (
                        <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                          {item.badge}
                        </span>
                      )}
                    </Link>

                    {isLocked && (
                      <div className="absolute left-full ml-2 w-64 p-4 bg-white rounded-lg shadow-lg border border-gray-200 invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-200 z-50">
                        <h4 className="text-sm font-medium text-gray-900 mb-2">
                          {item.label === 'Dashboard' ? 'Statistiques avancées' : 'Support client premium'}
                        </h4>
                        <p className="text-sm text-gray-600 mb-4">
                          {item.label === 'Dashboard' 
                            ? 'Passez au plan Standard ou supérieur pour accéder aux statistiques détaillées de vos cuvées'
                            : 'Passez au plan Standard ou supérieur pour accéder au support client prioritaire et au chat en direct.'}
                        </p>
                        <Link
                          to="/subscription"
                          className="inline-flex items-center text-sm text-purple-600 hover:text-purple-700"
                        >
                          Passer au plan Standard
                          <ArrowRight className="ml-2 h-4 w-4" />
                        </Link>
                      </div>
                    )}
                  </div>
                );
              })}
            </nav>

            {!isAdmin && (
              <div className="sidebar-bottom">
                  {bottomMenuItems.map((item) => {
                    const isActive = location.pathname === item.path;
                    const isLocked = !isAdmin && item.requiresFeature && !PLAN_FEATURES[userPlan as PlanType][item.requiresFeature as keyof typeof PLAN_FEATURES[PlanType]];

                    return (
                      <div key={item.path} className="relative group">
                        <Link
                          to={isLocked ? (item.redirectTo || '#') : item.path}
                          className={`${
                            isActive
                              ? 'bg-purple-50 text-purple-700'
                              : isLocked
                              ? 'text-gray-400 bg-gray-50 cursor-not-allowed'
                              : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                          } group flex items-center justify-between px-2 py-2 text-sm font-medium rounded-md transition-colors duration-150`}
                        >
                          <div className="flex items-center">
                            <div className="mr-3">{item.icon}</div>
                            <span>{item.label}</span>
                          </div>
                          {isLocked && <Lock className="h-4 w-4 text-[#9333EA]" />}
                          {item.badge !== undefined && !isLocked && (
                            <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                              {item.badge}
                            </span>
                          )}
                        </Link>

                        {isLocked && (
                          <div className="absolute left-full ml-2 w-64 p-4 bg-white rounded-lg shadow-lg border border-gray-200 invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-200 z-50">
                            <h4 className="text-sm font-medium text-gray-900 mb-2">
                              Support client premium
                            </h4>
                            <p className="text-sm text-gray-600 mb-4">
                              Passez au plan Standard ou supérieur pour accéder au support client prioritaire et au chat en direct.
                            </p>
                            <Link
                              to="/subscription"
                              className="inline-flex items-center text-sm text-purple-600 hover:text-purple-700"
                            >
                              Passer au plan Standard
                              <ArrowRight className="ml-2 h-4 w-4" />
                            </Link>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            )}

            {isAdmin && (
              <div className="sidebar-bottom">
                <Link
                  to="/settings"
                  className={`${
                    location.pathname === '/settings'
                      ? 'bg-purple-50 text-purple-700'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                  } group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-colors duration-150`}
                >
                  <Settings className="mr-3 h-5 w-5" />
                  Paramètres
                </Link>
              </div>
            )}

            <div className="sidebar-bottom">
              <button
                onClick={handleLogoutClick}
                className="w-full flex items-center px-2 py-2 text-sm font-medium text-red-600 hover:bg-red-50 rounded-md transition-colors duration-150"
              >
                <LogOut className="mr-3 h-5 w-5" />
                Se déconnecter
              </button>
            </div>
          </div>
        </div>
      </div>

      <LogoutConfirmation
        isOpen={showLogoutConfirmation}
        onClose={() => setShowLogoutConfirmation(false)}
        onConfirm={handleLogoutConfirm}
      />
    </div>
  );
};

export default Sidebar;