import React, { useState } from 'react';
import { X, TrendingUp, TrendingDown, Minus, Calendar, QrCode, Eye, Filter, Loader2 } from 'lucide-react';
import { format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useStatistics } from '../../hooks/useStatistics';
import { useAuthContext } from '../../contexts/AuthContext';

interface MonthlyStats {
  month: string;
  views: number;
  qrScans: number;
  directViews: number;
  trend: number;
}

interface CuveeStatsModalProps {
  isOpen: boolean;
  onClose: () => void;
  cuveeName: string;
  cuveeId: string;
}

const CuveeStatsModal: React.FC<CuveeStatsModalProps> = ({
  isOpen,
  onClose,
  cuveeName,
  cuveeId
}) => {
  const { user } = useAuthContext();
  const { stats, loading, error } = useStatistics(user?.uid);
  const [selectedYear, setSelectedYear] = useState<string>(new Date().getFullYear().toString());

  if (!isOpen) return null;
  
  // Trouver les stats de la cuvée sélectionnée
  const cuveeStats = stats.find(s => s.cuveeId === cuveeId);
  if (!cuveeStats) return null;

  // Convertir les données quotidiennes en données mensuelles
  const monthlyStats = Object.entries(cuveeStats.dailyStats).reduce((acc, [date, dayStats]) => {
    const month = date.substring(0, 7); // Format YYYY-MM
    if (!acc[month]) {
      acc[month] = {
        month,
        views: 0,
        qrScans: 0,
        directViews: 0,
        trend: 0
      };
    }
    acc[month].views += dayStats.views || 0;
    acc[month].qrScans += dayStats.qrScans || 0;
    acc[month].directViews += dayStats.directViews || 0;
    return acc;
  }, {} as Record<string, MonthlyStats>);

  // Convertir en tableau et trier par date
  const monthlyStatsArray = Object.values(monthlyStats).sort((a, b) => 
    b.month.localeCompare(a.month)
  );

  // Extraire les années uniques
  const years = [...new Set(monthlyStatsArray.map(stat => stat.month.substring(0, 4)))].sort().reverse();

  // Filtrer par année sélectionnée
  const filteredStats = monthlyStatsArray.filter(stat => stat.month.startsWith(selectedYear));

  const formatMonth = (monthStr: string) => {
    return format(parseISO(monthStr), 'MMMM yyyy', { locale: fr });
  };

  const getTrendColor = (trend: number) => {
    if (trend > 0) return 'text-green-600';
    if (trend < 0) return 'text-red-600';
    return 'text-gray-600';
  };

  const getTrendIcon = (trend: number) => {
    if (trend > 0) return <TrendingUp className="h-4 w-4" />;
    if (trend < 0) return <TrendingDown className="h-4 w-4" />;
    return <Minus className="h-4 w-4" />;
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center p-4">
        <div 
          className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm"
          onClick={onClose}
        />
        
        <div className="relative w-full max-w-4xl bg-white rounded-xl shadow-xl">
          <div className="p-6">
            <div className="flex items-center justify-between mb-6">
              <div>
                <h3 className="text-xl font-semibold text-gray-900">
                  Statistiques détaillées
                  <div className="flex items-center space-x-2 mt-2">
                    <Filter className="h-4 w-4 text-gray-400" />
                    <select
                      value={selectedYear}
                      onChange={(e) => setSelectedYear(e.target.value)}
                      className="text-sm border border-gray-300 rounded-lg px-3 py-1.5 focus:outline-none focus:ring-2 focus:ring-purple-500"
                    >
                      {years.map(year => (
                        <option key={year} value={year}>{year}</option>
                      ))}
                    </select>
                  </div>
                </h3>
                <p className="text-sm text-gray-500">{cuveeName}</p>
              </div>
              <button
                onClick={onClose}
                className="p-2 text-gray-400 hover:text-gray-500 rounded-full hover:bg-gray-100"
              >
                <X className="h-5 w-5" />
              </button>
            </div>

            {loading ? (
              <div className="flex items-center justify-center py-12">
                <Loader2 className="h-8 w-8 text-purple-600 animate-spin" />
              </div>
            ) : error ? (
              <div className="text-center py-12 text-red-600">
                Une erreur est survenue lors du chargement des statistiques
              </div>
            ) : filteredStats.length === 0 ? (
              <div className="text-center py-12 text-gray-500">
                Aucune donnée disponible pour cette période
              </div>
            ) : (
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <div className="flex items-center space-x-2">
                        <Calendar className="h-4 w-4" />
                        <span>Mois</span>
                      </div>
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <div className="flex items-center space-x-2">
                        <Eye className="h-4 w-4" />
                        <span>Visites totales</span>
                      </div>
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <div className="flex items-center space-x-2">
                        <QrCode className="h-4 w-4" />
                        <span>Scans QR</span>
                      </div>
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Vues directes
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Évolution
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredStats.map((stat, index) => (
                    <tr key={stat.month} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {formatMonth(stat.month)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <span className="text-2xl font-bold text-purple-600">
                            {stat.views.toLocaleString()}
                          </span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {stat.qrScans.toLocaleString()}
                          <span className="text-xs text-gray-500 ml-1">
                            ({((stat.qrScans / stat.views) * 100).toFixed(1)}%)
                          </span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {stat.directViews.toLocaleString()}
                          <span className="text-xs text-gray-500 ml-1">
                            ({((stat.directViews / stat.views) * 100).toFixed(1)}%)
                          </span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className={`flex items-center space-x-1 ${getTrendColor(stat.trend)}`}>
                          {getTrendIcon(stat.trend)}
                          <span className="text-sm font-medium">
                            {stat.trend > 0 ? '+' : ''}{stat.trend}%
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            <div className="mt-6 p-4 bg-gray-50 rounded-lg">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="p-4 bg-white rounded-lg border border-gray-200">
                  <div className="text-sm font-medium text-gray-500 mb-1">
                    Taux de conversion QR moyen
                  </div>
                  <div className="text-2xl font-bold text-purple-600">
                    {(filteredStats.reduce((acc, stat) => 
                      acc + (stat.qrScans / stat.views), 0) / filteredStats.length * 100
                    ).toFixed(1)}%
                  </div>
                </div>
                <div className="p-4 bg-white rounded-lg border border-gray-200">
                  <div className="text-sm font-medium text-gray-500 mb-1">
                    Meilleur mois
                  </div>
                  <div className="text-2xl font-bold text-purple-600">
                    {formatMonth(filteredStats.reduce((max, stat) => 
                      stat.views > max.views ? stat : max
                    , filteredStats[0]).month)}
                  </div>
                </div>
                <div className="p-4 bg-white rounded-lg border border-gray-200">
                  <div className="text-sm font-medium text-gray-500 mb-1">
                    Croissance moyenne
                  </div>
                  <div className="text-2xl font-bold text-purple-600">
                    {(filteredStats.reduce((acc, stat) => acc + stat.trend, 0) / filteredStats.length).toFixed(1)}%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CuveeStatsModal;