import React, { useState } from 'react';
import { HelpCircle, X, QrCode, BarChart3, TrendingUp } from 'lucide-react';

const MODAL_Z_INDEX = 9999;

const TutorialPopover: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative inline-block">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="p-2 text-gray-500 hover:text-purple-600 rounded-full hover:bg-purple-50 transition-colors relative z-10"
        title="Aide sur les statistiques"
      >
        <HelpCircle className="h-5 w-5" />
      </button>

      {isOpen && (
        <div className="fixed inset-0 overflow-y-auto" style={{ zIndex: MODAL_Z_INDEX + 1 }}>
          <div 
            className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm"
            onClick={() => setIsOpen(false)}
          />
          
          <div className="relative min-h-screen flex items-center justify-center p-4">
            <div className="relative w-full max-w-md bg-white rounded-lg shadow-xl">
              <div className="p-6">
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-lg font-medium text-gray-900">
                    Guide des statistiques
                  </h3>
                  <button
                    onClick={() => setIsOpen(false)}
                    className="text-gray-400 hover:text-gray-500 rounded-full p-1 hover:bg-gray-100 transition-colors"
                  >
                    <X className="h-5 w-5" />
                  </button>
                </div>

                <div className="space-y-6">
                  <div className="flex items-start space-x-3">
                    <div className="mt-1">
                      <QrCode className="h-5 w-5 text-purple-600" />
                    </div>
                    <div>
                      <h4 className="text-sm font-medium text-gray-900">Scans QR</h4>
                      <p className="text-sm text-gray-500">
                        Nombre de fois où vos QR codes ont été scannés par des visiteurs
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start space-x-3">
                    <div className="mt-1">
                      <BarChart3 className="h-5 w-5 text-purple-600" />
                    </div>
                    <div>
                      <h4 className="text-sm font-medium text-gray-900">Vues directes</h4>
                      <p className="text-sm text-gray-500">
                        Nombre de visites via un lien direct vers la page de la cuvée
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start space-x-3">
                    <div className="mt-1">
                      <TrendingUp className="h-5 w-5 text-purple-600" />
                    </div>
                    <div>
                      <h4 className="text-sm font-medium text-gray-900">Tendances</h4>
                      <p className="text-sm text-gray-500">
                        Évolution des visites sur les 7 derniers jours par rapport à la période précédente
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mt-6 p-4 bg-purple-50 rounded-lg">
                  <p className="text-sm text-purple-700">
                    <strong>Astuce :</strong> Utilisez les filtres de période pour analyser vos données sur différents intervalles de temps.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TutorialPopover;