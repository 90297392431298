import { collection, doc, increment, serverTimestamp, setDoc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { PageView, CuveeStats } from '../types/statistics';
import { v4 as uuidv4 } from 'uuid';

export class StatisticsService {
  private static getDeviceType(): 'mobile' | 'desktop' | 'tablet' | 'unknown' {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet';
    }
    if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
      return 'mobile';
    }
    if (!/Mobile|Android/.test(ua)) {
      return 'desktop';
    }
    return 'unknown';
  }

  private static async initializeCuveeStats(cuveeId: string): Promise<void> {
    const statsRef = doc(db, 'cuveeStats', cuveeId);
    const today = new Date().toISOString().split('T')[0];
    const initialStats: CuveeStats = {
      id: cuveeId,
      cuveeId: cuveeId,
      totalViews: 0,
      qrScans: 0,
      directViews: 0,
      lastUpdated: new Date().toISOString(),
      dailyStats: {
        [today]: {
          views: 0,
          qrScans: 0,
          directViews: 0
        }
      },
      deviceStats: {
        mobile: 0,
        desktop: 0,
        tablet: 0,
        unknown: 0
      }
    };
    await setDoc(statsRef, initialStats, { merge: true });
  }

  static async recordPageView(cuveeId: string, source: 'qr' | 'direct' | 'unknown' = 'unknown'): Promise<void> {
    try {
      // Vérifier si la vue vient d'un QR code via l'URL
      const urlParams = new URLSearchParams(window.location.search);
      const isQrScan = urlParams.get('qr') === 'true';
      if (isQrScan) {
        source = 'qr';
      }

      // 1. Créer l'enregistrement de la vue
      const pageView: PageView = {
        id: uuidv4(),
        cuveeId,
        timestamp: new Date().toISOString(),
        source,
        deviceType: this.getDeviceType()
      };

      // 2. Sauvegarder la vue
      const viewsRef = collection(db, 'pageViews');
      await setDoc(doc(viewsRef, pageView.id), pageView);

      // 3. Mettre à jour les statistiques globales
      const statsRef = doc(db, 'cuveeStats', cuveeId);
      const statsDoc = await getDoc(statsRef);

      if (!statsDoc.exists()) {
        await this.initializeCuveeStats(cuveeId);
      }

      const today = new Date().toISOString().split('T')[0];
      const updates: any = {
        totalViews: increment(1),
        [`dailyStats.${today}.views`]: increment(1) || 1,
        [`deviceStats.${pageView.deviceType}`]: increment(1),
        lastUpdated: serverTimestamp()
      };

      if (source === 'qr') {
        updates.qrScans = increment(1);
        updates[`dailyStats.${today}.qrScans`] = increment(1) || 1;
      } else if (source === 'direct') {
        updates.directViews = increment(1);
        updates[`dailyStats.${today}.directViews`] = increment(1) || 1;
      }

      await updateDoc(statsRef, updates);
    } catch (error) {
      console.error('Error recording page view:', error);
    }
  }

  static async getCuveeStats(cuveeId: string): Promise<CuveeStats | null> {
    try {
      const statsRef = doc(db, 'cuveeStats', cuveeId);
      const statsDoc = await getDoc(statsRef);

      if (!statsDoc.exists()) {
        return null;
      }

      return statsDoc.data() as CuveeStats;
    } catch (error) {
      console.error('Error getting cuvee stats:', error);
      return null;
    }
  }
}