import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';

// Auth Pages
import Login from './pages/Login';
import Signup from './pages/Signup';
import ForgotPassword from './pages/ForgotPassword';
import VerifyEmail from './pages/VerifyEmail';
import EmailVerificationHandler from './pages/EmailVerificationHandler';

// Public Pages
import PublishedCuvee from './pages/PublishedCuvee';
import TermsOfService from './pages/Legal/TermsOfService';
import PrivacyPolicy from './pages/Legal/PrivacyPolicy';
import LegalNotice from './pages/Legal/LegalNotice';

// Protected Pages
import DefaultRedirect from './pages/DefaultRedirect';
import Welcome from './pages/Welcome';
import Statistics from './pages/Statistics';
import CuveeList from './pages/Cuvees/CuveeList';
import CuveePreview from './pages/CuveePreview';
import QRCodeGenerator from './pages/QRCodes/QRCodeGenerator';
import Design from './pages/Design';
import Settings from './pages/Settings';
import Support from './pages/Support';
import Subscription from './pages/Subscription';
import SubscriptionSuccess from './components/SubscriptionSuccess';
import Exceeded from './pages/Exceeded';
import Upsells from './pages/Upsells';

// Admin Pages
import AdminDashboard from './pages/admin/Dashboard';
import AdminCuvees from './pages/admin/Cuvees';
import AdminSupport from './pages/admin/Support';
import Users from './pages/admin/Users';
import UserDetails from './pages/admin/UserDetails';
import Notifications from './pages/admin/Notifications';
import ServerLogs from './pages/admin/ServerLogs';

// Components
import PrivateRoute from './components/PrivateRoute';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Auth Action Handler */}
          <Route path="/__/auth/action" element={<EmailVerificationHandler />} />

          {/* Public routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/signup/*" element={<Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/:companySlug/:pageSlug" element={<PublishedCuvee />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/legal" element={<LegalNotice />} />

          {/* Protected routes */}
          <Route path="/" element={
            <PrivateRoute>
              <DefaultRedirect />
            </PrivateRoute>
          } />
          <Route path="/welcome" element={
            <PrivateRoute>
              <Welcome />
            </PrivateRoute>
          } />
          <Route path="/statistics" element={
            <PrivateRoute>
              <Statistics />
            </PrivateRoute>
          } />
          <Route path="/cuvees" element={
            <PrivateRoute>
              <CuveeList />
            </PrivateRoute>
          } />
          <Route path="/qr-codes" element={
            <PrivateRoute>
              <QRCodeGenerator />
            </PrivateRoute>
          } />
          <Route path="/design" element={
            <PrivateRoute>
              <Design />
            </PrivateRoute>
          } />
          <Route path="/settings" element={
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          } />
          <Route path="/support" element={
            <PrivateRoute>
              <Support />
            </PrivateRoute>
          } />
          <Route path="/subscription" element={
            <PrivateRoute>
              <Subscription />
            </PrivateRoute>
          } />
          <Route path="/subscription/success" element={
            <PrivateRoute>
              <SubscriptionSuccess />
            </PrivateRoute>
          } />
          <Route path="/upsells" element={
            <PrivateRoute>
              <Upsells />
            </PrivateRoute>
          } />
          <Route path="/exceeded" element={
            <PrivateRoute>
              <Exceeded />
            </PrivateRoute>
          } />
          <Route path="/preview/:id" element={
            <PrivateRoute>
              <CuveePreview />
            </PrivateRoute>
          } />

          {/* Admin routes */}
          <Route path="/admin/dashboard" element={
            <PrivateRoute>
              <AdminDashboard />
            </PrivateRoute>
          } />
          <Route path="/admin/cuvees" element={
            <PrivateRoute>
              <AdminCuvees />
            </PrivateRoute>
          } />
          <Route path="/admin/support" element={
            <PrivateRoute>
              <AdminSupport />
            </PrivateRoute>
          } />
          <Route path="/users" element={
            <PrivateRoute>
              <Users />
            </PrivateRoute>
          } />
          <Route path="/users/:userId" element={
            <PrivateRoute>
              <UserDetails />
            </PrivateRoute>
          } />
          <Route path="/admin/notifications" element={
            <PrivateRoute>
              <Notifications />
            </PrivateRoute>
          } />
          <Route path="/admin/logs" element={
            <PrivateRoute>
              <ServerLogs />
            </PrivateRoute>
          } />

          {/* Default redirect */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;