import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import type { LucideIcon } from 'lucide-react';

interface WelcomeCardProps {
  title: string;
  value: number;
  icon: LucideIcon;
  description: string;
  linkTo: string;
  linkText: string;
}

const WelcomeCard: React.FC<WelcomeCardProps> = ({
  title,
  value,
  icon: Icon,
  description,
  linkTo,
  linkText
}) => {
  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6 hover:border-purple-200 transition-colors">
      <div className="flex items-center space-x-4 mb-4">
        <div className="p-3 bg-purple-50 rounded-lg">
          <Icon className="h-6 w-6 text-purple-600" />
        </div>
        <div>
          <h3 className="text-lg font-medium text-gray-900">{title}</h3>
          <p className="text-sm text-gray-500">{description}</p>
        </div>
      </div>

      <div className="mb-6">
        <p className="text-3xl font-bold text-gray-900">{value}</p>
      </div>

      <Link
        to={linkTo}
        className="inline-flex items-center text-purple-600 hover:text-purple-700"
      >
        {linkText}
        <ArrowRight className="ml-2 h-4 w-4" />
      </Link>
    </div>
  );
};

export default WelcomeCard;