import React from 'react';
import { X, CreditCard } from 'lucide-react';
import { format, addMonths } from 'date-fns';
import { fr } from 'date-fns/locale';
import { STRIPE_PRODUCTS } from '../lib/stripe';

interface ChangePlanModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  currentPlan: string;
  newPlan: string | null;
  subscription?: {
    currentPeriodEnd?: string;
  };
  isProcessing: boolean;
}

const ChangePlanModal: React.FC<ChangePlanModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  currentPlan,
  newPlan,
  subscription,
  isProcessing
}) => {
  const getLostFeatures = () => {
    if (currentPlan !== 'premium' || newPlan !== 'standard') return null;
    
    return [
      'Design des cuvées personnalisées',
      'Options avancées de QR Codes',
      'Statistiques avancées',
      'Support en ligne 7j/7',
      'Assistance technique dédiée',
      'Mises à jour en avant-première'
    ];
  };

  if (!isOpen || !newPlan) return null;

  const currentPlanDetails = STRIPE_PRODUCTS[currentPlan as keyof typeof STRIPE_PRODUCTS];
  const newPlanDetails = STRIPE_PRODUCTS[newPlan as keyof typeof STRIPE_PRODUCTS];
  const isUpgrade = newPlanDetails.price > currentPlanDetails.price;
  const isDowngrade = newPlanDetails.price < currentPlanDetails.price;
  const isBasicPlan = newPlan === 'basic';
  const lostFeatures = isDowngrade ? getLostFeatures() : null;
  const isDowngradeToStandard = currentPlan === 'premium' && newPlan === 'standard';

  const nextBillingDate = subscription?.currentPeriodEnd 
    ? format(new Date(subscription.currentPeriodEnd), 'dd MMMM yyyy', { locale: fr })
    : format(addMonths(new Date(), 1), 'dd MMMM yyyy', { locale: fr });

  const formatPrice = (price: number) => `${(price / 100).toFixed(2)}€/mois`;

  const calculateProratedAmount = () => {
    if (!isUpgrade || !subscription?.currentPeriodEnd) return null;
    
    const currentDate = new Date();
    const endDate = new Date(subscription.currentPeriodEnd);
    const remainingDays = Math.ceil((endDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24));
    const totalDays = 30; // Période standard d'un mois
    
    const currentAmount = currentPlanDetails.price / 100;
    const newAmount = newPlanDetails.price / 100;
    const proratedCredit = (currentAmount * remainingDays) / totalDays;
    const proratedCharge = (newAmount * remainingDays) / totalDays;
    
    return {
      credit: proratedCredit.toFixed(2),
      charge: proratedCharge.toFixed(2),
      difference: (proratedCharge - proratedCredit).toFixed(2)
    };
  };

  const prorationDetails = calculateProratedAmount();

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center p-4">
        <div
          className="fixed inset-0 bg-black bg-opacity-25 transition-opacity"
          onClick={onClose}
        />

        <div className="relative w-full max-w-lg transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all">
          <div className="absolute right-4 top-4">
            <button
              onClick={onClose}
              className="rounded-full p-1 hover:bg-gray-100 transition-colors"
            >
              <X className="h-5 w-5 text-gray-500" />
            </button>
          </div>

          <div className="flex items-center space-x-4 mb-6">
            <div className="flex-shrink-0">
              <div className="h-10 w-10 rounded-full bg-purple-100 flex items-center justify-center">
                <CreditCard className="h-5 w-5 text-purple-600" />
              </div>
            </div>
            <div>
              <h3 className="text-lg font-medium text-gray-900">
                Changer de plan
              </h3>
              <p className="text-sm text-gray-500">
                {currentPlanDetails.name} → {newPlanDetails.name}
              </p>
            </div>
          </div>

          <div className="space-y-4">
            {isBasicPlan ? (
              <div className="bg-yellow-50 border border-yellow-100 rounded-lg p-4">
                <h4 className="text-sm font-medium text-yellow-800 mb-2">
                  Passage au plan Basic (Gratuit)
                </h4>
                <ul className="text-sm text-yellow-700 space-y-1">
                  <li>• Votre abonnement actuel sera annulé immédiatement</li>
                  <li>• Vous perdrez l'accès aux fonctionnalités premium</li>
                  <li>• Vous serez limité à 5 cuvées maximum</li>
                  <li>• Les cuvées dépassant la limite seront automatiquement désactivées</li>
                </ul>
              </div>
            ) : isUpgrade ? (
              <div className="space-y-4">
                <div className="bg-green-50 border border-green-100 rounded-lg p-4">
                  <h4 className="text-sm font-medium text-green-800 mb-2">
                    Changements immédiats
                  </h4>
                  <ul className="text-sm text-green-700 space-y-1">
                    <li>• Accès immédiat aux fonctionnalités du plan {newPlanDetails.name}</li>
                    <li>• Facturation au prorata pour la période restante</li>
                    <li>• Prochaine facturation complète le {nextBillingDate}</li>
                  </ul>
                </div>
                {prorationDetails && (
                  <div className="bg-purple-50 border border-purple-100 rounded-lg p-4">
                    <h4 className="text-sm font-medium text-purple-800 mb-2">
                      Détails du prorata
                    </h4>
                    <div className="space-y-2 text-sm text-purple-700">
                      <p>• Crédit du plan actuel : -{prorationDetails.credit}€</p>
                      <p>• Montant proratisé du nouveau plan : {prorationDetails.charge}€</p>
                      <p className="font-medium">
                        Montant total à payer aujourd'hui : {prorationDetails.difference}€
                      </p>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="space-y-4">
                <div className="bg-yellow-50 border border-yellow-100 rounded-lg p-4">
                  <h4 className="text-lg font-medium text-yellow-800 mb-4">
                    Passage du plan {currentPlanDetails.name} ({formatPrice(currentPlanDetails.price)}) 
                    au plan {newPlanDetails.name} ({formatPrice(newPlanDetails.price)})
                  </h4>
                  <div className="space-y-6">
                    <div className="bg-white bg-opacity-50 rounded-lg p-4">
                      <p className="font-medium text-yellow-800 mb-2">
                        Calendrier de transition :
                      </p>
                      <ul className="text-sm text-yellow-700 space-y-2">
                        <li className="flex items-start">
                          <span className="w-2 h-2 bg-yellow-500 rounded-full mt-1.5 mr-2 flex-shrink-0"></span>
                          Votre plan {currentPlanDetails.name} reste actif avec toutes ses fonctionnalités jusqu'au {nextBillingDate}
                        </li>
                        <li className="flex items-start">
                          <span className="w-2 h-2 bg-yellow-500 rounded-full mt-1.5 mr-2 flex-shrink-0"></span>
                          Le plan {newPlanDetails.name} à {formatPrice(newPlanDetails.price)} sera automatiquement activé le {nextBillingDate}
                        </li>
                        <li className="flex items-start">
                          <span className="w-2 h-2 bg-yellow-500 rounded-full mt-1.5 mr-2 flex-shrink-0"></span>
                          Aucun paiement n'est requis aujourd'hui
                        </li>
                      </ul>
                    </div>

                    {isDowngradeToStandard && (
                      <div className="bg-red-50 rounded-lg p-4">
                        <p className="font-medium text-red-800 mb-2">
                          Fonctionnalités qui seront désactivées le {nextBillingDate} :
                        </p>
                        <ul className="text-sm text-red-700 space-y-2">
                          {lostFeatures?.map((feature, index) => (
                            <li key={index} className="flex items-start">
                              <span className="w-2 h-2 bg-red-500 rounded-full mt-1.5 mr-2 flex-shrink-0"></span>
                              {feature}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
              disabled={isProcessing}
            >
              Annuler
            </button>
            <button
              type="button"
              onClick={onConfirm}
              disabled={isProcessing}
              className={`px-4 py-2 text-sm font-medium text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50 ${
                isDowngrade ? 'bg-yellow-600 hover:bg-yellow-700' : 'bg-purple-600 hover:bg-purple-700'
              }`}
            >
              {isProcessing ? 'Traitement...' : 
               isBasicPlan ? 'Confirmer le passage au plan Basic' : 
               isDowngrade ? 'Confirmer le changement de plan' :
               'Confirmer et procéder au paiement'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePlanModal;