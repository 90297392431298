import React, { useEffect, useState } from 'react';
import { CheckCircle2, Loader2, AlertTriangle } from 'lucide-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuthContext } from '../contexts/AuthContext';

const SubscriptionSuccess: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const [status, setStatus] = useState<'verifying' | 'success' | 'error'>('verifying');
  const [error, setError] = useState<string | null>(null);
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    const verifySession = async () => {
      try {
        const sessionId = searchParams.get('session_id');
        if (!sessionId || !user) {
          throw new Error('Session ID ou utilisateur manquant');
        }

        // Vérifier le statut de la session Stripe
        const response = await fetch(`/api/check-session`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ sessionId })
        });

        if (!response.ok) {
          throw new Error('Erreur lors de la vérification de la session');
        }

        const { status, metadata } = await response.json();

        if (status !== 'complete' && status !== 'paid') {
          throw new Error('Le paiement n\'a pas été complété');
        }

        // Mettre à jour le plan de l'utilisateur
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        
        if (!userDoc.exists()) {
          throw new Error('Utilisateur non trouvé');
        }

        await updateDoc(userRef, {
          plan: metadata.plan,
          subscription: {
            status: 'active',
            startDate: new Date().toISOString(),
            currentPeriodEnd: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString()
          }
        });

        setStatus('success');
        startCountdown();
      } catch (err: any) {
        console.error('Error in subscription success:', err);
        setStatus('error');
        setError(err.message || 'Une erreur est survenue');
      }
    };

    verifySession();
  }, [searchParams, user, navigate]);

  const startCountdown = () => {
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          navigate('/subscription');
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-xl p-8">
        {status === 'verifying' && (
          <div className="text-center">
            <div className="mx-auto w-16 h-16 mb-4">
              <Loader2 className="w-16 h-16 text-purple-600 animate-spin" />
            </div>
            <h2 className="text-2xl font-bold text-gray-900 mb-2">
              Vérification du paiement
            </h2>
            <p className="text-gray-600">
              Veuillez patienter pendant que nous vérifions votre paiement...
            </p>
          </div>
        )}

        {status === 'success' && (
          <div className="text-center">
            <div className="mx-auto w-16 h-16 mb-4">
              <CheckCircle2 className="w-16 h-16 text-green-500" />
            </div>
            <h2 className="text-2xl font-bold text-gray-900 mb-2">
              Paiement réussi !
            </h2>
            <p className="text-gray-600 mb-4">
              Votre abonnement a été mis à jour avec succès.
            </p>
            <div className="bg-gray-50 rounded-lg p-4">
              <p className="text-sm text-gray-600">
                Redirection dans {countdown} seconde{countdown > 1 ? 's' : ''}...
              </p>
              <div className="mt-2 w-full bg-gray-200 rounded-full h-2">
                <div 
                  className="bg-green-500 h-2 rounded-full transition-all duration-1000 ease-linear"
                  style={{ width: `${(countdown / 5) * 100}%` }}
                />
              </div>
            </div>
          </div>
        )}

        {status === 'error' && (
          <div className="text-center">
            <div className="mx-auto w-16 h-16 mb-4">
              <AlertTriangle className="w-16 h-16 text-red-500" />
            </div>
            <h2 className="text-2xl font-bold text-gray-900 mb-2">
              Une erreur est survenue
            </h2>
            <p className="text-red-600 mb-4">
              {error}
            </p>
            <button
              onClick={() => navigate('/subscription')}
              className="btn"
            >
              Retour aux abonnements
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubscriptionSuccess;