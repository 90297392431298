import React from 'react';
import { CreditCard, Calendar, AlertTriangle, Clock, CheckCircle2, XCircle } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { STRIPE_PRODUCTS } from '../lib/stripe';

interface BillingDetailsProps {
  subscription: {
    status: string;
    currentPeriodEnd?: string;
    canceledAt?: string;
    stripeId?: string;
    lastPaymentStatus?: string;
    lastPaymentDate?: string;
    lastPaymentFailureDate?: string;
    lastPaymentAmount?: number;
  };
  plan: string;
}

const BillingDetails: React.FC<BillingDetailsProps> = ({ subscription, plan }) => {
  const planDetails = STRIPE_PRODUCTS[plan as keyof typeof STRIPE_PRODUCTS];
  const isFreePlan = plan === 'basic';
  const isGoldPlan = plan === 'gold';
  const planPrice = plan === 'standard' ? '15€' : plan === 'premium' ? '25€' : '0€';
  const isCanceled = subscription?.status === 'canceled' || subscription?.status === 'cancelled';
  const isActive = subscription?.status === 'active';
  const isPaused = subscription?.status === 'paused';
  const isIncomplete = subscription?.status === 'incomplete';
  const isUnpaid = subscription?.status === 'unpaid';
  const nextBillingDate = subscription?.currentPeriodEnd 
    ? format(new Date(subscription.currentPeriodEnd), 'dd MMMM yyyy', { locale: fr })
    : null;

  const getStatusBadge = () => {
    if (isCanceled) {
      return (
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800">
          <Clock className="w-4 h-4 mr-1" />
          En cours d'annulation
        </span>
      );
    }
    if (isActive) {
      return (
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
          <CheckCircle2 className="w-4 h-4 mr-1" />
          Actif
        </span>
      );
    }
    if (isPaused) {
      return (
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-orange-100 text-orange-800">
          <Clock className="w-4 h-4 mr-1" />
          En pause
        </span>
      );
    }
    if (isIncomplete || isUnpaid) {
      return (
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800">
          <XCircle className="w-4 h-4 mr-1" />
          {isIncomplete ? 'Incomplet' : 'Impayé'}
        </span>
      );
    }
    return null;
  };
  if (isFreePlan) {
    return (
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
        <div className="px-6 py-5 border-b border-gray-200">
          <div className="flex items-center">
            <CreditCard className="h-5 w-5 text-gray-400 mr-3" />
            <h2 className="text-lg font-medium text-gray-900">
              Détails de facturation
            </h2>
          </div>
        </div>
        <div className="px-6 py-5">
          <p className="text-gray-600">
            Vous utilisez actuellement le plan Basic gratuit. Aucune facturation n'est en cours.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
      <div className="px-6 py-5 border-b border-gray-200">
        <div className="flex items-center">
          <CreditCard className="h-5 w-5 text-gray-400 mr-3" />
          <h2 className="text-lg font-medium text-gray-900">
            Détails de facturation
          </h2>
        </div>
      </div>

      <div className="px-6 py-5 space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div>
            <h3 className="text-sm font-medium text-gray-500">Plan actuel</h3>
            <p className="mt-1 text-lg font-medium text-gray-900">
              {planDetails.name}
            </p>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-500">Montant</h3>
            <p className="mt-1 text-lg font-medium text-gray-900">
              {planPrice}/mois
            </p>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-500">Statut</h3>
            <div className="mt-1 flex items-center">
              {getStatusBadge()}
            </div>
          </div>
        </div>

        {subscription.lastPaymentDate && (
          <div className="flex items-center space-x-2 text-sm text-gray-600 mt-4">
            <CheckCircle2 className="h-4 w-4 text-green-500" />
            <span>
              Dernier paiement le {format(new Date(subscription.lastPaymentDate), 'dd MMMM yyyy', { locale: fr })}
              {subscription.lastPaymentAmount && ` - ${(subscription.lastPaymentAmount / 100).toFixed(2)}€`}
            </span>
          </div>
        )}

        {subscription.lastPaymentFailureDate && (
          <div className="flex items-center space-x-2 text-sm text-red-600 mt-4">
            <XCircle className="h-4 w-4" />
            <span>
              Échec du dernier paiement le {format(new Date(subscription.lastPaymentFailureDate), 'dd MMMM yyyy', { locale: fr })}
            </span>
          </div>
        )}

        {nextBillingDate && (
          <div className="flex items-center space-x-2 text-sm text-gray-600">
            <Calendar className="h-4 w-4" />
            <span>
              {isCanceled ? 'Fin de l\'abonnement' : 'Prochaine facturation'} le {nextBillingDate}
            </span>
          </div>
        )}

        {subscription.stripeId && (
          <div className="mt-4 text-xs text-gray-500">
            <span className="font-medium">ID de l'abonnement :</span>{' '}
            <code className="bg-gray-100 px-1 py-0.5 rounded">
              {subscription.stripeId}
            </code>
          </div>
        )}

        {isCanceled && (
          <div className="flex items-start space-x-2 bg-yellow-50 p-4 rounded-lg">
            <AlertTriangle className="h-5 w-5 text-yellow-400 flex-shrink-0 mt-0.5" />
            <div>
              <h4 className="text-sm font-medium text-yellow-800">
                Abonnement en cours d'annulation
              </h4>
              <p className="mt-1 text-sm text-yellow-700">
                Votre abonnement restera actif jusqu'au {nextBillingDate}. Après cette date, votre compte passera automatiquement au plan Basic.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BillingDetails;