import React, { useState } from 'react';
import { CreditCard, Code, Lock } from 'lucide-react';
import { STRIPE_PRODUCTS } from '../lib/stripe';
import { useAuthContext } from '../contexts/AuthContext';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { StripeService } from '../services/stripeService';

const DEV_PAY_ENABLED = import.meta.env.VITE_DEV_PAY_ENABLE === 'true';
const DEV_PAY_PASSWORD = import.meta.env.VITE_DEV_PAY_PASSWORD;

interface SubscriptionPaymentSummaryProps {
  currentPlan: string;
  newPlan: string;
  subscription?: {
    currentPeriodEnd?: string;
    status?: string;
    stripeId?: string;
  };
  onPaymentSuccess: () => void;
  onPaymentError: (error: string) => void;
  isProcessing?: boolean;
}

const SubscriptionPaymentSummary: React.FC<SubscriptionPaymentSummaryProps> = ({
  currentPlan,
  newPlan,
  subscription,
  onPaymentSuccess,
  onPaymentError,
  setIsProcessing
}) => {
  const { user } = useAuthContext();
  const [isProcessingLocal, setIsProcessingLocal] = useState(false);
  const currentProduct = STRIPE_PRODUCTS[currentPlan as keyof typeof STRIPE_PRODUCTS];
  const newProduct = STRIPE_PRODUCTS[newPlan as keyof typeof STRIPE_PRODUCTS];
  const isDowngrade = newProduct.price < currentProduct.price;
  const isUpgrade = newProduct.price > currentProduct.price;
  const isDowngradeToBasic = newPlan === 'basic';
  const isDowngradeToStandard = currentPlan === 'premium' && newPlan === 'standard';
  const isUpgradeToStandard = currentPlan === 'basic' && newPlan === 'standard';
  const isUpgradeToPremium = newPlan === 'premium';

  const [devPassword, setDevPassword] = useState('');
  const [showDevPassword, setShowDevPassword] = useState(false);
  const [isProcessing, setIsProcessingInternal] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);

  const handleDevPayment = async () => {
    if (!DEV_PAY_ENABLED) {
      onPaymentError('Mode développement désactivé');
      return;
    }

    if (devPassword !== DEV_PAY_PASSWORD) {
      onPaymentError('Mot de passe de développement incorrect');
      return;
    }

    if (!user) {
      onPaymentError('Utilisateur non connecté');
      return;
    }

    try {
      // Mettre à jour le plan de l'utilisateur
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        plan: newPlan,
        subscription: {
          status: 'active',
          startDate: new Date().toISOString(),
          currentPeriodEnd: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString() // +30 jours
        }
      });

      onPaymentSuccess();
    } catch (err: any) {
      onPaymentError(err.message || 'Erreur lors du paiement en mode développement');
    }
  };

  const handleDowngradeToBasic = async () => {
    if (!user) {
      onPaymentError('Utilisateur non connecté');
      return;
    }

    try {
      setIsProcessing(true);

      // 1. Créer une session Stripe Customer Portal
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);
      const currentSubscriptionId = userDoc.data()?.subscription?.stripeId;

      if (currentSubscriptionId) {
        const response = await fetch('/api/stripe/create-portal-session', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            customerId: userDoc.data()?.subscription?.customerId,
            returnUrl: `${window.location.origin}/subscription`
          }),
        });

        if (!response.ok) {
          throw new Error('Erreur lors de la création de la session portal');
        }

        const { url } = await response.json();
        if (url) {
          window.location.href = url;
          return;
        }
      } else {
        throw new Error('Aucun abonnement actif trouvé');
      }
    } catch (err: any) {
      onPaymentError(err.message || 'Erreur lors du passage au plan Basic');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleStripeCheckout = async () => {
    if (!user) return;

    setIsRedirecting(true);

    setIsRedirecting(true);

    try {
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);
      if (!userDoc.exists()) {
        throw new Error('Utilisateur non trouvé');
      }

      const userProfile = userDoc.data();
      const currentSubscriptionId = subscription?.stripeId;

      // Créer une nouvelle session Stripe
      const { url } = await StripeService.createCheckoutSession({
        plan: newPlan,
        email: user.email || '',
        successUrl: `${window.location.origin}/subscription/success`,
        cancelUrl: `${window.location.origin}/subscription`,
        customerData: {
          userId: user.uid,
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          companyName: userProfile.company?.name,
          currentSubscriptionId: currentSubscriptionId || undefined
        }
      });
      
      // Redirect to Stripe Checkout
      window.location.href = url;
    } catch (error) {
      console.error('Error creating checkout session:', error);
      onPaymentError('Une erreur est survenue lors de la création de la session de paiement');
      setIsRedirecting(false);
    } finally {
      // Ne pas réinitialiser isRedirecting ici car nous voulons garder l'état de chargement jusqu'à la redirection
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6 space-y-6">
      <h3 className="text-lg font-medium text-gray-900 mb-4">
        Récapitulatif du changement de plan
      </h3>
      
      {isUpgrade && (
        <div className="bg-green-50 border border-green-100 rounded-lg p-6 space-y-6">
          <div>
            <h4 className="text-lg font-medium text-green-900 mb-2">
              Passage au plan {newProduct.name}
            </h4>
            <p className="text-green-700">
              Accédez immédiatement à toutes les fonctionnalités premium
            </p>
          </div>

          <div className="bg-white bg-opacity-50 rounded-lg p-4">
            <h5 className="font-medium text-green-900 mb-2">Nouvelles fonctionnalités :</h5>
            <ul className="space-y-2">
              {isUpgradeToPremium && [
                'Design des cuvées personnalisées',
                'Options avancées de QR Codes',
                'Statistiques avancées',
                'Support en ligne 7j/7',
                'Assistance technique dédiée'
              ].map((feature, index) => (
                <li key={index} className="flex items-start">
                  <svg className="h-5 w-5 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  <span className="text-green-800">{feature}</span>
                </li>
              ))}
              {isUpgradeToStandard && [
                'Cuvées illimitées',
                'Génération de QR Codes',
                'Support par email prioritaire'
              ].map((feature, index) => (
                <li key={index} className="flex items-start">
                  <svg className="h-5 w-5 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  <span className="text-green-800">{feature}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}

      {isDowngradeToBasic ? (
        <div className="space-y-6">
          <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-6">
            <h4 className="text-lg font-medium text-yellow-900 mb-4">
              Passage au plan Basic (Gratuit)
            </h4>
            <p className="text-yellow-800 mb-4">
              En passant au plan Basic, vous perdrez l'accès aux fonctionnalités suivantes :
            </p>
            <ul className="space-y-3">
              <li className="flex items-start">
                <Lock className="h-5 w-5 text-yellow-600 mr-2 mt-0.5" />
                <span className="text-yellow-800">Cuvées illimitées (limité à 5)</span>
              </li>
              <li className="flex items-start">
                <Lock className="h-5 w-5 text-yellow-600 mr-2 mt-0.5" />
                <span className="text-yellow-800">Statistiques avancées</span>
              </li>
              <li className="flex items-start">
                <Lock className="h-5 w-5 text-yellow-600 mr-2 mt-0.5" />
                <span className="text-yellow-800">Design personnalisé</span>
              </li>
              <li className="flex items-start">
                <Lock className="h-5 w-5 text-yellow-600 mr-2 mt-0.5" />
                <span className="text-yellow-800">Support prioritaire</span>
              </li>
            </ul>
          </div>

          <div className="bg-red-50 border border-red-200 rounded-lg p-4">
            <p className="text-sm text-red-800">
              <strong>Important :</strong> Si vous avez plus de 5 cuvées publiées, les plus récentes seront automatiquement désactivées.
            </p>
          </div>

          <button
            onClick={handleDowngradeToBasic}
            disabled={isProcessing}
            className="w-full btn bg-yellow- 600 hover:bg-yellow-700 flex items-center justify-center space-x-2"
          >
            <span>
              {isProcessing ? 'Traitement en cours...' : 'J\'ai compris, passer au plan Basic'}
            </span>
          </button>
        </div>
      ) : (
        <div className="space-y-4">
          {isDowngradeToStandard ? (
            <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-6 space-y-6">
              <div>
                <h4 className="text-lg font-medium text-yellow-900 mb-2">
                  Passage du plan Premium au plan Standard
                </h4>
                <p className="text-yellow-700">
                  Ce changement sera effectif à la fin de votre période de facturation actuelle.
                </p>
              </div>

              <div className="bg-white bg-opacity-50 rounded-lg p-4">
                <h5 className="font-medium text-yellow-900 mb-2">Fonctionnalités que vous perdrez :</h5>
                <ul className="space-y-2">
                  <li className="flex items-start">
                    <Lock className="h-5 w-5 text-yellow-600 mr-2 mt-0.5" />
                    <span className="text-yellow-800">Design des cuvées personnalisées</span>
                  </li>
                  <li className="flex items-start">
                    <Lock className="h-5 w-5 text-yellow-600 mr-2 mt-0.5" />
                    <span className="text-yellow-800">Options avancées de QR Codes</span>
                  </li>
                  <li className="flex items-start">
                    <Lock className="h-5 w-5 text-yellow-600 mr-2 mt-0.5" />
                    <span className="text-yellow-800">Statistiques avancées</span>
                  </li>
                  <li className="flex items-start">
                    <Lock className="h-5 w-5 text-yellow-600 mr-2 mt-0.5" />
                    <span className="text-yellow-800">Support en ligne 7j/7</span>
                  </li>
                </ul>
              </div>

              <div className="bg-white bg-opacity-50 rounded-lg p-4">
                <h5 className="font-medium text-yellow-900 mb-2">Calendrier de transition :</h5>
                <ul className="space-y-2">
                  <li className="flex items-start">
                    <span className="w-2 h-2 bg-yellow-500 rounded-full mt-2 mr-2" />
                    <span className="text-yellow-800">
                      Votre plan Premium reste actif avec toutes ses fonctionnalités jusqu'à la fin de la période en cours
                    </span>
                  </li>
                  <li className="flex items-start">
                    <span className="w-2 h-2 bg-yellow-500 rounded-full mt-2 mr-2" />
                    <span className="text-yellow-800">
                      Le passage au plan Standard sera automatique à la prochaine date de facturation
                    </span>
                  </li>
                  <li className="flex items-start">
                    <span className="w-2 h-2 bg-yellow-500 rounded-full mt-2 mr-2" />
                    <span className="text-yellow-800">
                      Aucun paiement n'est requis aujourd'hui
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            <>
              <div className="flex justify-between items-center py-3 border-b border-gray-200">
                <div>
                  <p className="text-sm text-gray-500">
                    Vous allez passer du plan {currentProduct.name} au plan {newProduct.name}
                  </p>
                </div>
              </div>
            </>
          )}

          <div className="mb-6">
            <button
              className="w-full btn flex items-center justify-center space-x-2"
              onClick={handleStripeCheckout}
              disabled={isProcessing || isProcessingLocal || isRedirecting}
            >
              <CreditCard className="h-4 w-4" />
              {isRedirecting ? (
                <div className="flex items-center space-x-2">
                  <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent"></div>
                  <span>Redirection en cours...</span>
                </div>
              ) : (
                <span>Payer avec Stripe</span>
              )}
            </button>
          </div>

        </div>
      )}

      <p className="text-sm text-gray-500 text-center">
        {isDowngrade 
          ? 'Le changement de plan sera effectif à la prochaine date de facturation'
          : 'Le changement de plan sera effectif immédiatement après le paiement'
        }
      </p>
    </div>
  );
};

export default SubscriptionPaymentSummary;