import React, { useState } from 'react';
import { Clock, Filter, Search, ArrowUpDown } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';
import type { ActivityLog } from '../types/activity';
import ActivityLogIcon from './ActivityLogIcon';

const ITEMS_PER_PAGE = 20;

interface RecentActivityProps {
  logs: ActivityLog[];
  loading: boolean;
}

type ActivityFilter = 'all' | 'cuvee' | 'qrcode' | 'user';

const getActivityMessage = (log: ActivityLog): string => {
  switch (log.type) {
    case 'cuvee_create':
      return `Nouvelle cuvée créée : ${log.details.cuveeName || ''}`;
    case 'cuvee_update':
      return `Cuvée modifiée : ${log.details.cuveeName || ''}`;
    case 'cuvee_delete':
      return `Cuvée supprimée : ${log.details.cuveeName || ''}`;
    case 'cuvee_publish':
      return `Cuvée publiée : ${log.details.cuveeName || ''}`;
    case 'qrcode_create':
      return `QR Code généré pour : ${log.details.cuveeName || ''}`;
    case 'qrcode_download':
      return `QR Code téléchargé pour : ${log.details.cuveeName || ''}`;
    case 'qrcode_delete':
      return `QR Code supprimé pour : ${log.details.cuveeName || ''}`;
    case 'user_login':
      return 'Connexion au compte';
    default:
      return log.details || 'Action inconnue';
  }
};

const RecentActivity: React.FC<RecentActivityProps> = ({ logs, loading }) => {
  const [filter, setFilter] = useState<ActivityFilter>('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [currentPage, setCurrentPage] = useState(1);

  const filteredLogs = logs.filter(log => {
    const matchesSearch = searchTerm === '' || 
      getActivityMessage(log).toLowerCase().includes(searchTerm.toLowerCase());

    const matchesFilter = filter === 'all' || (
      filter === 'cuvee' && log.type.startsWith('cuvee_') ||
      filter === 'qrcode' && log.type.startsWith('qrcode_') ||
      filter === 'user' && log.type === 'user_login'
    );

    return matchesSearch && matchesFilter;
  }).sort((a, b) => {
    const dateA = new Date(a.timestamp).getTime();
    const dateB = new Date(b.timestamp).getTime();
    return sortOrder === 'asc' 
      ? dateA - dateB 
      : dateB - dateA;
  });

  const totalPages = Math.ceil(filteredLogs.length / ITEMS_PER_PAGE);
  const paginatedLogs = filteredLogs.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  if (loading) {
    return (
      <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
        <div className="animate-pulse space-y-4">
          <div className="h-6 w-32 bg-gray-200 rounded"></div>
          <div className="space-y-3">
            {[1, 2, 3].map((i) => (
              <div key={i} className="flex items-center space-x-3">
                <div className="h-8 w-8 bg-gray-200 rounded-full"></div>
                <div className="flex-1 space-y-2">
                  <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                  <div className="h-3 bg-gray-200 rounded w-1/2"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
      <div className="mb-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Activité récente</h3>
        
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="flex-1 relative">
            <input
              type="text"
              placeholder="Rechercher une action..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
          
          <div className="relative">
            <select
              value={filter}
              onChange={(e) => setFilter(e.target.value as ActivityFilter)}
              className="appearance-none pl-10 pr-8 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
            >
              <option value="all">Toutes les actions</option>
              <option value="cuvee">Cuvées</option>
              <option value="qrcode">QR Codes</option>
              <option value="user">Connexions</option>
            </select>
            <Filter className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
        </div>
      </div>

      <div className="overflow-x-auto">
        {paginatedLogs.length === 0 ? (
          <div className="text-center py-6">
            <Clock className="h-8 w-8 text-gray-400 mx-auto mb-2" />
            <p className="text-gray-500">Aucune activité récente</p>
          </div>
        ) : (
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Type
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Action
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Date
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {paginatedLogs.map((log) => (
                <tr key={log.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="h-8 w-8 rounded-full bg-purple-100 flex items-center justify-center">
                        <ActivityLogIcon type={log.type} className="h-4 w-4 text-purple-600" />
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900">
                      {getActivityMessage(log)}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {formatDistanceToNow(new Date(log.timestamp), { 
                      addSuffix: true,
                      locale: fr 
                    })}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {totalPages > 1 && (
          <div className="mt-4 flex items-center justify-between border-t border-gray-200 pt-4">
            <div className="text-sm text-gray-700">
              Affichage de {(currentPage - 1) * ITEMS_PER_PAGE + 1} à{' '}
              {Math.min(currentPage * ITEMS_PER_PAGE, filteredLogs.length)} sur{' '}
              {filteredLogs.length} entrées
            </div>
            <div className="flex space-x-2">
              <button
                onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                disabled={currentPage === 1}
                className="px-3 py-1 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Précédent
              </button>
              <button
                onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
                disabled={currentPage === totalPages}
                className="px-3 py-1 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Suivant
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RecentActivity;