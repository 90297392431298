import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Lock, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import StatisticsHeader from '../components/Statistics/StatisticsHeader';
import CuveeVisitsChart from '../components/Statistics/CuveeVisitsChart';
import CuveeEvolutionChart from '../components/Statistics/CuveeEvolutionChart';
import RecentActivity from '../components/RecentActivity';
import { useActivityLogs } from '../hooks/useActivityLogs';

const Statistics: React.FC = () => {
  const { user } = useAuthContext();
  const { logs, loading: logsLoading } = useActivityLogs(user?.uid, 5);
  const [userPlan, setUserPlan] = useState<'basic' | 'standard' | 'premium' | 'gold'>('basic');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!user?.uid) return;
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        setUserPlan(userDoc.data().plan || 'basic');
      }
      setLoading(false);
    };

    fetchUserData();
  }, [user]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  const isBasicPlan = userPlan === 'basic' || userPlan === 'standard';

  if (isBasicPlan) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="bg-white rounded-lg shadow-lg p-8 text-center">
          <div className="mx-auto flex h-20 w-20 items-center justify-center rounded-full bg-purple-100 mb-6">
            <Lock className="h-10 w-10 text-purple-600" />
          </div>
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Statistiques avancées
          </h2>
          <p className="text-gray-600 mb-8 max-w-md mx-auto">
            Passez au plan Premium pour accéder aux statistiques détaillées de vos cuvées et suivre leur performance dans le temps.
          </p>
          <Link
            to="/subscription"
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700"
          >
            Passer au plan Premium
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 space-y-8">
      <StatisticsHeader />
      
      {/* Full width visits chart */}
      <div className="bg-white rounded-lg shadow-sm p-6">
        <CuveeVisitsChart />
      </div>

      {/* Evolution chart */}
      <div className="bg-white rounded-lg shadow-sm p-6">
        <CuveeEvolutionChart />
      </div>

      <div className="bg-white rounded-lg shadow-sm">
        <RecentActivity logs={logs} loading={logsLoading} />
      </div>
    </div>
  );
};

export default Statistics;