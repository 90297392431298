import React, { useState, useEffect } from 'react';
import { Wine, Search, Filter, ArrowUpDown, Eye, Ban, Unlock } from 'lucide-react';
import { collection, query, getDocs, doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { useNavigate } from 'react-router-dom';
import Toast, { ToastType } from '../../components/Toast';
import BlockCuveeModal from '../../components/BlockCuveeModal';
import UnblockCuveeModal from '../../components/UnblockCuveeModal';
import { sendBlockedCuveeEmail } from '../../lib/brevo';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import CuveesFilterModal from '../../components/admin/CuveesFilterModal';
import { AOC_BY_REGION } from '../../constants/wineData';
import { PagePublisher } from '../../services/pagePublisher';

interface Cuvee {
  id: string;
  name: string;
  type: string;
  vintage: number;
  appellation: string;
  status: 'draft' | 'published' | 'blocked' | 'exceeded';
  userId: string;
  companyName?: string;
  lastModified: string;
  region?: string;
}

interface CompanyInfo {
  name: string;
  userId: string;
}

const formatDateTime = (dateString: string): string => {
  try {
    return format(new Date(dateString), 'dd/MM/yyyy HH:mm', { locale: fr });
  } catch (error) {
    return 'N/A';
  }
};

const AdminCuvees: React.FC = () => {
  const navigate = useNavigate();
  const [cuvees, setCuvees] = useState<Cuvee[]>([]);
  const [loading, setLoading] = useState(true);
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [showUnblockModal, setShowUnblockModal] = useState(false);
  const [selectedCuvee, setSelectedCuvee] = useState<Cuvee | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [toast, setToast] = useState<{ message: string; type: ToastType } | null>(null);
  const [companies, setCompanies] = useState<Record<string, CompanyInfo>>({});
  const [showFilters, setShowFilters] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState<keyof Cuvee>('lastModified');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [filters, setFilters] = useState({
    type: '',
    status: '',
    region: ''
  });

  const regions = Object.keys(AOC_BY_REGION);

  const fetchCompanyInfo = async (userId: string) => {
    try {
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        return {
          name: userData.company?.name || 'N/A',
          userId
        };
      }
      return null;
    } catch (error) {
      console.error('Error fetching company info:', error);
      return null;
    }
  };

  const fetchCuvees = async () => {
    try {
      setLoading(true);
      const cuveesRef = collection(db, 'cuvees');
      const q = query(cuveesRef);
      const snapshot = await getDocs(q);
      
      const cuveesData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Cuvee[];

      // Fetch company info for each unique userId
      const uniqueUserIds = [...new Set(cuveesData.map(cuvee => cuvee.userId))];
      const companyData: Record<string, CompanyInfo> = {};
      
      await Promise.all(
        uniqueUserIds.map(async (userId) => {
          const companyInfo = await fetchCompanyInfo(userId);
          if (companyInfo) {
            companyData[userId] = companyInfo;
          }
        })
      );

      setCompanies(companyData);

      // Add company names to cuvees
      const cuveesWithCompany = cuveesData.map(cuvee => ({
        ...cuvee,
        companyName: companyData[cuvee.userId]?.name || 'N/A'
      }));

      setCuvees(cuveesWithCompany);
    } catch (error) {
      console.error('Error fetching cuvees:', error);
      showToast('Erreur lors de la récupération des cuvées', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCuvees();
  }, []);

  const showToast = (message: string, type: ToastType) => {
    setToast({ message, type });
  };

  const handleSort = (field: keyof Cuvee) => {
    if (field === sortField) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('desc');
    }
  };

  const handlePreview = (cuvee: Cuvee) => {
    navigate(`/preview/${cuvee.id}`);
  };

  const handleBlock = (cuvee: Cuvee) => {
    setSelectedCuvee(cuvee);
    setShowBlockModal(true);
  };

  const handleUnblock = (cuvee: Cuvee) => {
    setSelectedCuvee(cuvee);
    setShowUnblockModal(true);
  };

  const handleBlockConfirm = async () => {
    if (!selectedCuvee) return;

    try {
      setIsProcessing(true);
      
      // Récupérer les informations de l'utilisateur pour l'email
      const userDoc = await getDoc(doc(db, 'users', selectedCuvee.userId));
      if (!userDoc.exists()) {
        throw new Error('Utilisateur non trouvé');
      }
      const userData = userDoc.data();

      // Bloquer la cuvée
      await updateDoc(doc(db, 'cuvees', selectedCuvee.id), {
        status: 'blocked',
        lastModified: new Date().toISOString()
      });

      // Dépublier la page
      await PagePublisher.handleCuveeBlocking(selectedCuvee.id);

      // Envoyer l'email de notification
      await sendBlockedCuveeEmail(
        userData.email,
        userData.firstName,
        selectedCuvee.name
      );

      showToast('La cuvée a été bloquée avec succès', 'success');
      setShowBlockModal(false);
      setSelectedCuvee(null);
      fetchCuvees();
    } catch (error) {
      console.error('Error blocking cuvee:', error);
      showToast('Erreur lors du blocage de la cuvée', 'error');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleUnblockConfirm = async (newStatus: 'draft' | 'published') => {
    if (!selectedCuvee) return;

    try {
      setIsProcessing(true);
      
      // Mettre à jour le statut de la cuvée
      await updateDoc(doc(db, 'cuvees', selectedCuvee.id), {
        status: newStatus,
        lastModified: new Date().toISOString()
      });

      // Si la cuvée doit être publiée, la republier
      if (newStatus === 'published') {
        const updatedCuvee = {
          ...selectedCuvee,
          status: newStatus
        };
        await PagePublisher.handleCuveeUnblocking(updatedCuvee, selectedCuvee.userId, true);
      }

      showToast('La cuvée a été débloquée avec succès', 'success');
      setShowUnblockModal(false);
      setSelectedCuvee(null);
      fetchCuvees();
    } catch (error) {
      console.error('Error unblocking cuvee:', error);
      showToast('Erreur lors du déblocage de la cuvée', 'error');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleFilterChange = (newFilters: typeof filters) => {
    setFilters(newFilters);
  };

  const handleResetFilters = () => {
    setFilters({
      type: '',
      status: '',
      region: ''
    });
  };

  const filteredAndSortedCuvees = cuvees
    .filter(cuvee => {
      const searchLower = searchTerm.toLowerCase();
      const matchesSearch = (
        (cuvee.name?.toLowerCase() || '').includes(searchLower) ||
        (cuvee.type?.toLowerCase() || '').includes(searchLower) ||
        (cuvee.appellation?.toLowerCase() || '').includes(searchLower) ||
        (cuvee.companyName?.toLowerCase() || '').includes(searchLower)
      );

      const matchesType = !filters.type || cuvee.type === filters.type;
      const matchesStatus = !filters.status || cuvee.status === filters.status;
      const matchesRegion = !filters.region || cuvee.region === filters.region;

      return matchesSearch && matchesType && matchesStatus && matchesRegion;
    })
    .sort((a, b) => {
      const aValue = a[sortField];
      const bValue = b[sortField];
      
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return sortOrder === 'asc' 
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }
      
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
      }
      
      return 0;
    });

  const activeFiltersCount = Object.values(filters).filter(Boolean).length;

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 space-y-8">
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}

      <div>
        <h1 className="text-2xl font-bold text-gray-900">Cuvées</h1>
        <p className="mt-1 text-sm text-gray-600">
          Gérez toutes les cuvées de la plateforme
        </p>
      </div>

      <div className="bg-white rounded-lg shadow">
        <div className="p-4 border-b border-gray-200">
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="flex-1 relative">
              <input
                type="text"
                placeholder="Rechercher une cuvée..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
              />
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
            <div className="flex gap-2">
              <button 
                onClick={() => setShowFilters(true)}
                className="btn-secondary flex items-center space-x-2 relative"
              >
                <Filter className="h-4 w-4" />
                <span>Filtres</span>
                {activeFiltersCount > 0 && (
                  <span className="absolute -top-2 -right-2 bg-purple-600 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
                    {activeFiltersCount}
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:text-gray-700"
                  onClick={() => handleSort('name')}
                >
                  <div className="flex items-center space-x-1">
                    <span>Cuvée</span>
                    <ArrowUpDown className={`h-4 w-4 ${
                      sortField === 'name' ? 'text-purple-600' : 'text-gray-400'
                    }`} />
                  </div>
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:text-gray-700"
                  onClick={() => handleSort('companyName')}
                >
                  <div className="flex items-center space-x-1">
                    <span>Entreprise</span>
                    <ArrowUpDown className={`h-4 w-4 ${
                      sortField === 'companyName' ? 'text-purple-600' : 'text-gray-400'
                    }`} />
                  </div>
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:text-gray-700"
                  onClick={() => handleSort('type')}
                >
                  <div className="flex items-center space-x-1">
                    <span>Type</span>
                    <ArrowUpDown className={`h-4 w-4 ${
                      sortField === 'type' ? 'text-purple-600' : 'text-gray-400'
                    }`} />
                  </div>
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:text-gray-700"
                  onClick={() => handleSort('status')}
                >
                  <div className="flex items-center space-x-1">
                    <span>Statut</span>
                    <ArrowUpDown className={`h-4 w-4 ${
                      sortField === 'status' ? 'text-purple-600' : 'text-gray-400'
                    }`} />
                  </div>
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:text-gray-700"
                  onClick={() => handleSort('lastModified')}
                >
                  <div className="flex items-center space-x-1">
                    <span>Dernière modification</span>
                    <ArrowUpDown className={`h-4 w-4 ${
                      sortField === 'lastModified' ? 'text-purple-600' : 'text-gray-400'
                    }`} />
                  </div>
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredAndSortedCuvees.map((cuvee) => (
                <tr key={cuvee.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="h-10 w-10 flex-shrink-0 rounded-full bg-purple-100 flex items-center justify-center">
                        <Wine className="h-5 w-5 text-purple-600" />
                      </div>
                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                          {cuvee.name}
                        </div>
                        <div className="text-sm text-gray-500">
                          {cuvee.vintage}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{cuvee.companyName}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{cuvee.type}</div>
                    <div className="text-sm text-gray-500">{cuvee.appellation}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                      cuvee.status === 'published' 
                        ? 'bg-green-100 text-green-800'
                        : cuvee.status === 'blocked'
                        ? 'bg-red-100 text-red-800'
                        : cuvee.status === 'exceeded'
                        ? 'bg-yellow-100 text-yellow-800'
                        : 'bg-gray-100 text-gray-800'
                    }`}>
                      {cuvee.status === 'published' ? 'Publiée' : 
                       cuvee.status === 'blocked' ? 'Bloquée' :
                       cuvee.status === 'exceeded' ? 'Dépassée' :
                       'Brouillon'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {formatDateTime(cuvee.lastModified)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button
                      onClick={() => handlePreview(cuvee)}
                      className="text-purple-600 hover:text-purple-900 mr-3"
                      title="Aperçu"
                    >
                      <Eye className="h-4 w-4" />
                    </button>
                    {cuvee.status === 'blocked' ? (
                      <button
                        onClick={() => handleUnblock(cuvee)}
                        className="text-green-600 hover:text-green-900"
                        title="Débloquer"
                      >
                        <Unlock className="h-4 w-4" />
                      </button>
                    ) : (
                      <button
                        onClick={() => handleBlock(cuvee)}
                        className="text-red-600 hover:text-red-900"
                        title="Bloquer"
                      >
                        <Ban className="h-4 w-4" /> </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="px-4 py-3 border-t border-gray-200">
          <div className="flex items-center justify-between">
            <div className="text-sm text-gray-700">
              Affichage de <span className="font-medium">{filteredAndSortedCuvees.length}</span> sur{' '}
              <span className="font-medium">{cuvees.length}</span> cuvées
            </div>
          </div>
        </div>
      </div>

      <CuveesFilterModal
        isOpen={showFilters}
        onClose={() => setShowFilters(false)}
        filters={filters}
        onFilterChange={handleFilterChange}
        onReset={handleResetFilters}
        regions={regions}
      />

      {selectedCuvee && (
        <>
          <BlockCuveeModal
            isOpen={showBlockModal}
            onClose={() => {
              setShowBlockModal(false);
              setSelectedCuvee(null);
            }}
            onConfirm={handleBlockConfirm}
            cuveeName={selectedCuvee.name}
            isProcessing={isProcessing}
          />
          <UnblockCuveeModal
            isOpen={showUnblockModal}
            onClose={() => {
              setShowUnblockModal(false);
              setSelectedCuvee(null);
            }}
            onConfirm={handleUnblockConfirm}
            cuveeName={selectedCuvee.name}
            isProcessing={isProcessing}
          />
        </>
      )}
    </div>
  );
};

export default AdminCuvees;