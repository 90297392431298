import React, { useState } from 'react';
import { Check, Wine, ChevronRight, ChevronLeft } from 'lucide-react';
import { SignupData } from '..';
import { STRIPE_PRODUCTS } from '../../../lib/stripe';

interface PlanSelectionProps {
  data: SignupData;
  onComplete: (data: Partial<SignupData>) => void;
  onBack: () => void;
}

const plans = [
  {
    name: 'Basic',
    price: 'Gratuit',
    monthlyPrice: '0€/mois',
    description: 'Pour démarrer avec la gestion de vos cuvées',
    features: [
      'Jusqu\'à 5 cuvées',
      'Génération de QR codes',
      'Traduction 24 langues',
      'Design simplifié',
      'Support par email'
    ],
    value: 'basic',
    priceDetails: 'Frais de création uniques : 10€'
  },
  {
    name: 'Standard',
    price: '15€/mois',
    description: 'Pour les vignerons qui veulent plus de flexibilité',
    features: [
      'Cuvées illimitées',
      'Génération de QR Codes',
      'Traduction 24 langues',
      'Design simplifié',
      'Support par email'
    ],
    value: 'standard',
    priceDetails: 'Facturation mensuelle'
  },
  {
    name: 'Premium',
    price: '25€/mois',
    description: 'Pour une gestion professionnelle complète',
    features: [
      'Cuvées illimitées',
      'Génération de QR Codes',
      'Traduction 24 langues',
      'Support en ligne 7j/7',
      'Design des cuvées personnalisées',
      'Options avancées de QR Codes',
      'Statistiques avancées',
      'Assistance technique dédiée',
      'Mises à jour en avant-première'
    ],
    value: 'premium',
    priceDetails: 'Facturation mensuelle'
  }
];

const PlanSelection: React.FC<PlanSelectionProps> = ({ data, onComplete, onBack }) => {
  const [selectedPlan, setSelectedPlan] = useState<string>(data.plan || '');

  const handleSelectPlan = (plan: string) => {
    setSelectedPlan(plan);
  };

  const handleContinue = () => {
    if (selectedPlan) {
      onComplete({ plan: selectedPlan as 'basic' | 'standard' | 'premium' });
    }
  };

  return (
    <div className="space-y-8">
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
        {Object.entries(STRIPE_PRODUCTS)
          .filter(([_, plan]) => !plan.partnerOnly)
          .map(([key, plan]) => {
            const planPrice = key === 'basic' ? 'Gratuit' : 
                            key === 'standard' ? '15€/mois' : 
                            '25€/mois';
            return (
              <div
                key={key}
                className={`relative rounded-lg border-2 p-6 hover:border-purple-500 transition-colors cursor-pointer flex flex-col ${
                  selectedPlan === key
                    ? 'border-purple-500 bg-purple-50'
                    : 'border-gray-200'
                }`}
                onClick={() => handleSelectPlan(key)}
              >
            {selectedPlan === key && (
              <div className="absolute top-4 right-4">
                <div className="h-6 w-6 rounded-full bg-purple-500 flex items-center justify-center">
                  <Check className="h-4 w-4 text-white" />
                </div>
              </div>
            )}

            <div className="flex items-center space-x-3">
              <Wine className={`h-6 w-6 ${
                selectedPlan === key ? 'text-purple-600' : 'text-gray-400'
              }`} />
              <h3 className="text-lg font-semibold text-gray-900">{plan.name}</h3>
            </div>

            <p className="mt-4 text-2xl font-bold text-gray-900">
              {key === 'basic' ? 'Gratuit' : key === 'standard' ? '15€/mois' : '25€/mois'}
            </p>
            <p className="mt-1 text-sm text-gray-500">
              {key === 'basic' ? 'Frais de création uniques : 10€' : 'Facturation mensuelle'}
            </p>
            <p className="mt-2 text-sm text-gray-500 mb-4">{plan.description}</p>

            <ul className="mt-auto space-y-4 flex-1">
              {plan.features.map((feature, index) => (
                <li key={index} className="flex items-start">
                  <Check className="h-5 w-5 text-green-500 mr-2" />
                  <span className="text-sm text-gray-600">{feature}</span>
                </li>
              ))}
            </ul>
              </div>
            );
          })}
      </div>

      <div className="flex justify-between">
        <button
          type="button"
          onClick={onBack}
          className="btn-secondary flex items-center space-x-2"
        >
          <ChevronLeft className="h-4 w-4" />
          <span>Retour</span>
        </button>

        <button
          type="button"
          onClick={handleContinue}
          className="btn flex items-center space-x-2 disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={!selectedPlan}
        >
          <span>Continuer</span>
          <ChevronRight className="h-4 w-4" />
        </button>
      </div>
    </div>
  );
};

export default PlanSelection;