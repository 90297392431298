import React, { useState } from 'react';
import { Wine, Plus, Search, Filter, ArrowUpDown, Pencil, Trash2, Eye, Ban, Clock, Lock, RefreshCw, ArrowRight } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { useCuvees } from '../../hooks/useCuvees';
import { PagePublisher } from '../../services/pagePublisher';
import { useAuthContext } from '../../contexts/AuthContext';
import { usePlanLimits } from '../../hooks/usePlanLimits';
import Modal from '../../components/Modal';
import CuveeForm from '../../components/CuveeForm';
import DeleteCuveeModal from '../../components/DeleteCuveeModal';
import Toast, { ToastType } from '../../components/Toast';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import CuveeModerationModal from '../../components/CuveeModerationModal';
import { CuveeStatus } from '../../types/cuvee';

export const CuveeList = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { cuvees, loading, error: cuveeError, addCuvee, updateCuvee, deleteCuvee } = useCuvees(user?.uid);
  const { 
    canPublishMore, 
    getRemainingSlots, 
    getCurrentLimit, 
    currentPlan, 
    exceededCount,
    hasCheckedExceeded,
    hasUnlimitedCuvees,
    publishedCuvees 
  } = usePlanLimits(user?.uid);
  const [showExceededBanner, setShowExceededBanner] = useState(false);

  // Effect to handle exceeded banner visibility
  React.useEffect(() => {
    if (currentPlan === 'basic' && hasCheckedExceeded && exceededCount > 0 && !hasUnlimitedCuvees(currentPlan)) {
      setShowExceededBanner(true);
    }
  }, [currentPlan, hasCheckedExceeded, exceededCount, hasUnlimitedCuvees]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCuvee, setSelectedCuvee] = useState<any>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [toast, setToast] = useState<{ message: string | React.ReactNode; type: ToastType } | null>(null);
  const [sortField, setSortField] = useState<'name' | 'vintage' | 'type' | 'appellation' | 'status' | 'lastModified'>('lastModified');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [userStatus, setUserStatus] = useState<string>('active');
  const [showModerationModal, setShowModerationModal] = useState(false);
  const [pendingCuveeData, setPendingCuveeData] = useState<any>(null);

  const hasExceededCuvees = hasCheckedExceeded && currentPlan === 'basic' && exceededCount > 0;
  const isGoldPlan = currentPlan === 'gold';

  React.useEffect(() => {
    const fetchUserStatus = async () => {
      if (!user?.uid) return;
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        setUserStatus(userDoc.data().status || 'active');
      }
    };
    fetchUserStatus();
  }, [user]);

  const showToast = (message: string | React.ReactNode, type: ToastType) => {
    setToast({ message, type });
  };

  const handleOpenModal = (cuvee?: any) => {
    if (cuvee?.status === CuveeStatus.BLOCKED) return;
    if (!cuvee && !canPublishMore()) {
      showToast(`Vous avez atteint la limite de ${getCurrentLimit()} cuvées publiées pour votre plan`, 'error');
      return;
    }
    setSelectedCuvee(cuvee);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedCuvee(null);
    setIsModalOpen(false);
  };

  const handleDelete = (id: string) => {
    const cuvee = cuvees.find(c => c.id === id);
    if (!cuvee || cuvee.status === CuveeStatus.BLOCKED) return;

    setSelectedCuvee(cuvee);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    if (!selectedCuvee) return;

    try {
      await deleteCuvee(selectedCuvee.id);
      setShowDeleteModal(false);
      setSelectedCuvee(null);
      showToast('La cuvée a été supprimée avec succès', 'success');
    } catch (err: any) {
      console.error('Error deleting cuvee:', err);
      showToast(err.message || 'Une erreur est survenue lors de la suppression', 'error');
    }
  };

  const handlePreview = (cuvee: any) => {
    if (cuvee.status === CuveeStatus.BLOCKED) return;
    navigate(`/preview/${cuvee.id}`);
  };

  const handleRepublish = async (cuvee: any) => {
    if (!user?.uid) return;
    
    if (!canPublishMore()) {
      showToast('Vous devez d\'abord supprimer une cuvée publiée avant de pouvoir en republier une', 'error');
      return;
    }

    try {
      setIsProcessing(true);
      
      // Mettre à jour le statut de la cuvée
      const updatedCuvee = {
        ...cuvee,
        status: CuveeStatus.PUBLISHED,
        lastModified: new Date().toISOString(),
        exceededAt: null
      };
      
      await updateCuvee(cuvee.id, updatedCuvee);

      // Republier la page
      await PagePublisher.handleCuveePublication(updatedCuvee, user.uid);

      showToast('La cuvée a été republiée avec succès', 'success');
    } catch (err: any) {
      console.error('Error republishing cuvee:', err);
      showToast(err.message || 'Erreur lors de la republication', 'error');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleSort = (field: typeof sortField) => {
    if (field === sortField) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('desc');
    }
  };

  const handleSubmit = async (formData: any) => {
    try {
      setIsSubmitting(true);
      
      let result;
      if (selectedCuvee) {
        result = await updateCuvee(selectedCuvee.id, { ...formData, userId: user?.uid });
      } else {
        result = await addCuvee({ ...formData, userId: user?.uid });
      }

      handleCloseModal();

      // Si la cuvée est publiée, lancer la modération
      if (formData.status === CuveeStatus.PUBLISHED) {
        setPendingCuveeData(result);
        setShowModerationModal(true);
      } else {
        showToast(
          selectedCuvee 
            ? 'La cuvée a été mise à jour avec succès' 
            : 'La cuvée a été créée avec succès',
          'success'
        );
      }

      return result;
    } catch (err: any) {
      showToast(err.message || 'Une erreur est survenue lors de l\'enregistrement', 'error');
      throw err;
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleModerationComplete = async (success: boolean) => {
    if (!pendingCuveeData) return;

    try {
      if (!success) {
        // Si la modération échoue, bloquer la cuvée
        await updateCuvee(pendingCuveeData.id, { 
          status: CuveeStatus.BLOCKED,
          userId: user?.uid 
        });
      } else {
        showToast('La cuvée a été publiée avec succès', 'success');
      }
    } catch (err: any) {
      showToast(err.message || 'Une erreur est survenue', 'error');
    } finally {
      setPendingCuveeData(null);
      setShowModerationModal(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  if (cuveeError) {
    return (
      <div className="p-4 bg-red-50 text-red-700 rounded-lg">
        Une erreur est survenue: {cuveeError}
      </div>
    );
  }

  const filteredAndSortedCuvees = cuvees
    .filter(cuvee => {
      const searchLower = searchTerm.toLowerCase();
      const matchesSearch = (
        cuvee.name.toLowerCase().includes(searchLower) ||
        cuvee.type?.toLowerCase().includes(searchLower) ||
        cuvee.appellation?.toLowerCase().includes(searchLower)
      );
      return matchesSearch;
    })
    .sort((a, b) => {
      const aValue = a[sortField];
      const bValue = b[sortField];
      
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return sortOrder === 'asc' 
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }
      
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
      }
      
      return 0;
    });

  return (
    <div className="space-y-6">
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}

      {showExceededBanner && (
        <div className="bg-gradient-to-r from-yellow-50 to-amber-50 rounded-lg shadow-sm border border-yellow-200 overflow-hidden animate-fadeIn">
          <div className="p-6">
            <div className="flex items-start space-x-4">
              <div className="flex-shrink-0">
                <div className="h-12 w-12 bg-yellow-100 rounded-full flex items-center justify-center">
                  <Lock className="h-6 w-6 text-yellow-600" />
                </div>
              </div>
              <div className="flex-1">
                <div className="flex items-center">
                  <h3 className="text-lg font-semibold text-yellow-900">
                    {publishedCuvees} cuvées publiées sur 5 autorisées
                  </h3>
                  <span className="ml-3 inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800">
                    {exceededCount} cuvée{exceededCount > 1 ? 's' : ''} dépassée{exceededCount > 1 ? 's' : ''}
                  </span>
                </div>
                <p className="mt-2 text-sm text-yellow-700">
                  Votre plan Basic vous permet de publier jusqu'à 5 cuvées. Les cuvées dépassant cette limite 
                  ont été automatiquement désactivées, en commençant par les plus récentes.
                </p>
                <div className="mt-4 flex items-center space-x-4">
                  <Link
                    to="/subscription"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                  >
                    Passer à un plan supérieur
                    <ArrowRight className="ml-2 h-4 w-4" />
                  </Link>
                  <span className="text-sm text-yellow-600">
                    pour réactiver toutes vos cuvées
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-yellow-100/50 px-6 py-4 border-t border-yellow-200">
            <div className="flex items-center text-sm text-yellow-700">
              <Wine className="h-4 w-4 mr-2" />
              <span>
                Les cuvées dépassées ne sont plus accessibles en ligne et ne peuvent plus être modifiées
              </span>
            </div>
          </div>
        </div>
      )}

      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Mes Cuvées</h1>
          <p className="mt-1 text-sm text-gray-600">
            {isGoldPlan ? (
              'Cuvées illimitées (Plan Gold)'
            ) : currentPlan === 'basic' ? (
              `${getRemainingSlots()} cuvée${getRemainingSlots() !== 1 ? 's' : ''} restante${getRemainingSlots() !== 1 ? 's' : ''} sur ${getCurrentLimit()}`
            ) : (
              'Cuvées illimitées disponibles'
            )}
          </p>
        </div>
        <div className="flex items-center space-x-4">
          {!canPublishMore() && currentPlan !== 'premium' && (
            <Link
              to="/subscription"
              className="text-purple-600 hover:text-purple-700 flex items-center"
            >
              <span className="mr-2">Augmenter la limite</span>
              <ArrowRight className="h-4 w-4" />
            </Link>
          )}
          <button 
            onClick={() => handleOpenModal()} 
            disabled={userStatus === 'pending_deletion' || (!canPublishMore() && currentPlan !== 'premium')}
            className={`btn flex items-center space-x-2 ${
              userStatus === 'pending_deletion' || (!canPublishMore() && currentPlan !== 'premium')
                ? 'opacity-50 cursor-not-allowed bg-gray-400 hover:bg-gray-400'
                : ''
            }`}
          >
            {userStatus === 'pending_deletion' ? (
              <>
                <Clock className="h-4 w-4" />
                <span>Compte en suppression</span>
              </>
            ) : !canPublishMore() && currentPlan !== 'premium' ? (
              <>
                <Lock className="h-4 w-4" />
                <span>Limite atteinte</span>
              </>
            ) : (
              <>
                <Plus className="h-4 w-4" />
                <span>Nouvelle Cuvée</span>
              </>
            )}
          </button>
        </div>
      </div>

      {userStatus === 'pending_deletion' && (
        <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded-lg">
          <div className="flex">
            <div className="flex-shrink-0">
              <Clock className="h-5 w-5 text-red-400" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">
                Votre compte est en cours de suppression. La création de nouvelles cuvées est désactivée.
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="bg-white rounded-lg shadow">
        <div className="p-4 border-b border-gray-200">
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="flex-1 relative">
              <input
                type="text"
                placeholder="Rechercher une cuvée..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
              />
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
            <div className="flex gap-2">
              <button 
                className="btn-secondary flex items-center space-x-2"
              >
                <Filter className="h-4 w-4" />
                <span>Filtres</span>
              </button>
            </div>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:text-gray-700"
                  onClick={() => handleSort('name')}
                >
                  <div className="flex items-center space-x-1">
                    <span>Nom</span>
                    <ArrowUpDown className={`h-4 w-4 ${
                      sortField === 'name' ? 'text-purple-600' : 'text-gray-400'
                    }`} />
                  </div>
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:text-gray-700"
                  onClick={() => handleSort('vintage')}
                >
                  <div className="flex items-center space-x-1">
                    <span>Année</span>
                    <ArrowUpDown className={`h-4 w-4 ${
                      sortField === 'vintage' ? 'text-purple-600' : 'text-gray-400'
                    }`} />
                  </div>
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:text-gray-700"
                  onClick={() => handleSort('type')}
                >
                  <div className="flex items-center space-x-1">
                    <span>Type</span>
                    <ArrowUpDown className={`h-4 w-4 ${
                      sortField === 'type' ? 'text-purple-600' : 'text-gray-400'
                    }`} />
                  </div>
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:text-gray-700"
                  onClick={() => handleSort('status')}
                >
                  <div className="flex items-center space-x-1">
                    <span>Statut</span>
                    <ArrowUpDown className={`h-4 w-4 ${
                      sortField === 'status' ? 'text-purple-600' : 'text-gray-400'
                    }`} />
                  </div>
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredAndSortedCuvees.map((cuvee) => (
                <tr key={cuvee.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="h-10 w-10 flex-shrink-0 rounded-full bg-purple-100 flex items-center justify-center">
                        <Wine className="h-5 w-5 text-purple-600" />
                      </div>
                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                          {cuvee.name}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {cuvee.vintage}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {cuvee.type}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div>
                      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                        cuvee.status === CuveeStatus.PUBLISHED 
                          ? 'bg-green-100 text-green-800'
                          : cuvee.status === CuveeStatus.BLOCKED
                          ? 'bg-red-100 text-red-800'
                          : cuvee.status === CuveeStatus.EXCEEDED
                          ? 'bg-yellow-100 text-yellow-800 flex items-center space-x-1'
                          : 'bg-gray-100 text-gray-800'
                      }`}>
                        <div className="flex items-center space-x-1">
                          {cuvee.status === CuveeStatus.BLOCKED && <Ban className="h-3 w-3 mr-1" />}
                          {cuvee.status === CuveeStatus.EXCEEDED && <Lock className="h-3 w-3" />}
                          {(() => {
                            switch (cuvee.status) {
                              case CuveeStatus.PUBLISHED:
                                return 'Publiée';
                              case CuveeStatus.BLOCKED:
                                return 'Bloquée';
                              case CuveeStatus.EXCEEDED:
                                return 'Dépassée';
                              default:
                                return 'Brouillon';
                            }
                          })()}
                        </div>
                      </span>
                      {cuvee.status === CuveeStatus.BLOCKED && (
                        <p className="mt-1 text-xs text-red-600">
                          Cette cuvée a été bloquée par un administrateur
                        </p>
                      )}
                      {cuvee.status === CuveeStatus.EXCEEDED && (
                        <p className="mt-1 text-xs text-yellow-600">
                          Cette cuvée n'est plus accessible avec le plan Basic
                        </p>
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    {cuvee.status === CuveeStatus.EXCEEDED ? (
                      <div className="flex items-center justify-end space-x-2">
                        {hasUnlimitedCuvees(currentPlan) ? (
                          <button
                            onClick={() => handleRepublish(cuvee)}
                            disabled={isProcessing}
                            className={`inline-flex items-center justify-center w-8 h-8 text-green-600 hover:text-green-900 hover:bg-green-50 rounded-full transition-colors ${
                              isProcessing ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                            title="Republier la cuvée"
                          >
                            <RefreshCw className={`h-4 w-4 ${isProcessing ? 'animate-spin' : ''}`} />
                          </button>
                        ) : canPublishMore() ? (
                          <button
                            onClick={() => handleRepublish(cuvee)}
                            disabled={isProcessing}
                            className={`inline-flex items-center justify-center w-8 h-8 text-green-600 hover:text-green-900 hover:bg-green-50 rounded-full transition-colors ${
                              isProcessing ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                            title="Republier la cuvée"
                          >
                            <RefreshCw className={`h-4 w-4 ${isProcessing ? 'animate-spin' : ''}`} />
                          </button>
                        ) : currentPlan === 'basic' && (
                          <Link
                            to="/exceeded"
                            className="inline-flex items-center justify-center w-8 h-8 text-yellow-600 hover:text-yellow-900 hover:bg-yellow-50 rounded-full transition-colors"
                            title="Voir les cuvées dépassées"
                          >
                            <Lock className="h-4 w-4" />
                          </Link>
                        )}
                      </div>
                    ) : cuvee.status !== CuveeStatus.BLOCKED && (
                      <>
                        <button
                          onClick={() => handlePreview(cuvee)}
                          className="inline-flex items-center justify-center w-8 h-8 text-purple-600 hover:text-purple-900 hover:bg-purple-50 rounded-full transition-colors mr-2"
                          title="Aperçu"
                        >
                          <Eye className="h-4 w-4" />
                        </button>
                        <button
                          onClick={() => handleOpenModal(cuvee)}
                          className="inline-flex items-center justify-center w-8 h-8 text-purple-600 hover:text-purple-900 hover:bg-purple-50 rounded-full transition-colors mr-2"
                          title="Modifier"
                        >
                          <Pencil className="h-4 w-4" />
                        </button>
                        <button
                          onClick={() => handleDelete(cuvee.id)}
                          className="inline-flex items-center justify-center w-8 h-8 text-red-600 hover:text-red-900 hover:bg-red-50 rounded-full transition-colors"
                          title="Supprimer"
                        >
                          <Trash2 className="h-4 w-4" />
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="px-4 py-3 border-t border-gray-200">
          <div className="flex items-center justify-between">
            <div className="text-sm text-gray-700">
              Affichage de <span className="font-medium">{filteredAndSortedCuvees.length}</span> sur <span className="font-medium">{cuvees.length}</span> cuvées
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title={selectedCuvee ? 'Modifier la cuvée' : 'Nouvelle cuvée'}
      >
        <CuveeForm
          initialData={selectedCuvee || { status: CuveeStatus.DRAFT }}
          onSubmit={handleSubmit}
          onCancel={handleCloseModal}
          isSubmitting={isSubmitting}
          userId={user?.uid}
        />
      </Modal>

      <DeleteCuveeModal
        isOpen={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
          setSelectedCuvee(null);
        }}
        onConfirm={handleConfirmDelete}
        cuveeName={selectedCuvee?.name || ''}
        userId={user?.uid || ''}
      />

      <CuveeModerationModal
        isOpen={showModerationModal}
        onClose={() => setShowModerationModal(false)}
        onComplete={handleModerationComplete}
        isUpdate={!!selectedCuvee?.id}
        cuveeData={pendingCuveeData}
      />
    </div>
  );
};

export default CuveeList;