export enum CuveeStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  BLOCKED = 'blocked',
  EXCEEDED = 'exceeded'
}

export interface Cuvee {
  id: string;
  name: string;
  status: CuveeStatus;
  createdAt: string;
  updatedAt: string;
  certifications?: string[];
}