import React, { useState } from 'react';
import { Languages } from 'lucide-react';
import { TranslationService } from '../services/translationService';
import Toast from './Toast';

interface Language {
  code: string;
  name: string;
  flag: string;
}

const LANGUAGES: Language[] = [
  { code: 'fr', name: 'Français', flag: '🇫🇷' },
  { code: 'en', name: 'English', flag: '🇬🇧' },
  { code: 'de', name: 'Deutsch', flag: '🇩🇪' },
  { code: 'es', name: 'Español', flag: '🇪🇸' },
  { code: 'it', name: 'Italiano', flag: '🇮🇹' },
  { code: 'nl', name: 'Nederlands', flag: '🇳🇱' },
  { code: 'pt', name: 'Português', flag: '🇵🇹' },
  { code: 'pl', name: 'Polski', flag: '🇵🇱' },
  { code: 'ru', name: 'Русский', flag: '🇷🇺' },
  { code: 'uk', name: 'Українська', flag: '🇺🇦' },
  { code: 'ro', name: 'Română', flag: '🇷🇴' },
  { code: 'bg', name: 'Български', flag: '🇧🇬' },
  { code: 'el', name: 'Ελληνικά', flag: '🇬🇷' },
  { code: 'cs', name: 'Čeština', flag: '🇨🇿' },
  { code: 'sk', name: 'Slovenčina', flag: '🇸🇰' },
  { code: 'hu', name: 'Magyar', flag: '🇭🇺' },
  { code: 'hr', name: 'Hrvatski', flag: '🇭🇷' },
  { code: 'sl', name: 'Slovenščina', flag: '🇸🇮' },
  { code: 'et', name: 'Eesti', flag: '🇪🇪' },
  { code: 'lv', name: 'Latviešu', flag: '🇱🇻' },
  { code: 'lt', name: 'Lietuvių', flag: '🇱🇹' },
  { code: 'da', name: 'Dansk', flag: '🇩🇰' },
  { code: 'sv', name: 'Svenska', flag: '🇸🇪' },
  { code: 'fi', name: 'Suomi', flag: '🇫🇮' },
  { code: 'no', name: 'Norsk', flag: '🇳🇴' }
];

const LanguageSelector: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState({ code: 'fr', name: 'Français', flag: '🇫🇷' });
  const [isTranslating, setIsTranslating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [progress, setProgress] = useState(0);

  const handleLanguageSelect = async (language: Language) => {
    if (language.code === currentLanguage.code) {
      setIsOpen(false);
      return;
    }

    try {
      setIsTranslating(true);
      setError(null);
      setProgress(0);
      
      // Get all text elements
      const textElements = document.querySelectorAll('p, h1, h2, h3, h4, h5, h6, span, a, button, label, li');
      const textsToTranslate: string[] = [];
      const elementMap = new Map<number, Element>();

      // Collect texts and map elements
      textElements.forEach((element, index) => {
        const text = element.textContent?.trim();
        if (text) {
          textsToTranslate.push(text);
          elementMap.set(index, element);
        }
      });

      const totalTexts = textsToTranslate.length;
      let translatedCount = 0;

      // Translate all texts in batches
      const translations = await TranslationService.translateBatch(
        textsToTranslate, 
        language.code,
        (progress) => {
          setProgress(progress);
          translatedCount++;
          setProgress((translatedCount / totalTexts) * 100);
        }
      );

      // Update elements with translations
      translations.forEach((result, index) => {
        const element = elementMap.get(index);
        if (element && result.success && result.translatedText) {
          element.textContent = result.translatedText;
        }
      });

      setCurrentLanguage(language);
      setIsOpen(false);
      
      document.documentElement.lang = language.code;
      localStorage.setItem('preferredLanguage', language.code);

    } catch (err) {
      console.error('Error changing language:', err);
      setError('Une erreur est survenue lors de la traduction');
    } finally {
      setIsTranslating(false);
      setProgress(0);
    }
  };

  return (
    <>
      <div className="relative">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className={`flex items-center justify-center space-x-2 px-4 py-2 bg-white rounded-lg border border-gray-200 hover:border-purple-200 transition-colors duration-200 min-w-[180px] ${
            isTranslating ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          disabled={isTranslating}
          aria-label="Sélectionner la langue"
        >
          <span className="text-lg" role="img" aria-label={currentLanguage.name}>
            {currentLanguage.flag}
          </span>
          <span className="text-sm text-gray-600">
            {isTranslating ? 'Traduction...' : currentLanguage.name}
          </span>
          <Languages className={`h-4 w-4 text-gray-400 ${isTranslating ? 'animate-spin' : ''}`} />
        </button>

        {isTranslating && (
          <div className="absolute -bottom-1 left-0 right-0 h-1 bg-gray-200 rounded-full overflow-hidden transform translate-y-full">
            <div 
              className="h-full bg-purple-600 transition-all duration-200"
              style={{ width: `${progress}%` }}
            />
          </div>
        )}

        {isOpen && (
          <div className="fixed md:absolute left-1/2 -translate-x-1/2 md:translate-x-0 md:left-auto right-4 mt-2 w-[280px] md:w-56 bg-white rounded-lg shadow-lg border border-gray-200 py-2 z-50">
            <div className="max-h-96 overflow-y-auto">
              {LANGUAGES.map((language) => (
                <button
                  key={language.code}
                  onClick={() => handleLanguageSelect(language)}
                  disabled={isTranslating}
                  className={`w-full px-4 py-2 text-left hover:bg-purple-50 flex items-center space-x-3 ${
                    currentLanguage.code === language.code ? 'bg-purple-50' : ''
                  } ${isTranslating ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  <span className="text-lg" role="img" aria-label={language.name}>
                    {language.flag}
                  </span>
                  <span className="text-sm text-gray-700">{language.name}</span>
                </button>
              ))}
            </div>
          </div>
        )}
      </div>

      {error && (
        <Toast
          message={error}
          type="error"
          onClose={() => setError(null)}
        />
      )}
    </>
  );
};

export default LanguageSelector;