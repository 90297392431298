import React, { useState, useEffect } from 'react';
import { Wine, QrCode, ArrowRight } from 'lucide-react';
import { useAuthContext } from '../contexts/AuthContext';
import { useWelcomeStats } from '../hooks/useWelcomeStats';
import WelcomeCard from '../components/Welcome/WelcomeCard';
import WelcomeHeader from '../components/Welcome/WelcomeHeader';
import { Link } from 'react-router-dom';

const Welcome: React.FC = () => {
  const { user } = useAuthContext();
  const { stats, loading } = useWelcomeStats(user?.uid);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <WelcomeHeader userName={stats.firstName} />

      <div className="mt-12 grid grid-cols-1 md:grid-cols-2 gap-8">
        <WelcomeCard
          title="Cuvées publiées"
          value={stats.publishedCuvees}
          icon={Wine}
          description="Nombre total de cuvées publiées"
          linkTo="/cuvees"
          linkText="Gérer mes cuvées"
        />

        <WelcomeCard
          title="QR Codes générés"
          value={stats.totalQRCodes}
          icon={QrCode}
          description="Nombre total de QR codes générés"
          linkTo="/qr-codes"
          linkText="Gérer mes QR codes"
        />
      </div>

      {stats.publishedCuvees === 0 && (
        <div className="mt-12 bg-gradient-to-br from-purple-50 to-white rounded-xl p-8 border border-purple-100">
          <h3 className="text-xl font-semibold text-gray-900 mb-4">
            Commencez votre voyage avec Qvee
          </h3>
          <p className="text-gray-600 mb-6">
            Créez votre première cuvée et générez des QR codes pour offrir une expérience unique à vos clients.
          </p>
          <Link
            to="/cuvees"
            className="inline-flex items-center text-purple-600 hover:text-purple-700"
          >
            Créer ma première cuvée
            <ArrowRight className="ml-2 h-4 w-4" />
          </Link>
        </div>
      )}

      {stats.publishedCuvees > 0 && stats.totalQRCodes === 0 && (
        <div className="mt-12 bg-gradient-to-br from-purple-50 to-white rounded-xl p-8 border border-purple-100">
          <h3 className="text-xl font-semibold text-gray-900 mb-4">
            Générez votre premier QR Code
          </h3>
          <p className="text-gray-600 mb-6">
            Vous avez déjà publié des cuvées, il est temps de créer vos premiers QR codes pour les rendre accessibles à vos clients.
          </p>
          <Link
            to="/qr-codes"
            className="inline-flex items-center text-purple-600 hover:text-purple-700"
          >
            Générer mon premier QR code
            <ArrowRight className="ml-2 h-4 w-4" />
          </Link>
        </div>
      )}
    </div>
  );
};

export default Welcome;