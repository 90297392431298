import React, { useEffect, useState, useRef } from 'react';
import { CheckCircle2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthContext';
import { createUserAccount, checkEmailExists } from '../../../lib/firebase';

interface SuccessProps {
  data: {
    firstName: string;
    email: string;
    password: string;
    lastName: string;
    companyName: string;
    plan: string;
  };
}

const Success: React.FC<SuccessProps> = ({ data }) => {
  const navigate = useNavigate();
  const { login } = useAuthContext();
  const [status, setStatus] = useState<'creating' | 'success' | 'error'>('creating');
  const [error, setError] = useState<string | null>(null);
  const [redirectCountdown, setRedirectCountdown] = useState(5);
  const accountCreatedRef = useRef(false);
  const [messageIndex, setMessageIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  // Vérifier si on vient d'un paiement Stripe réussi
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const sessionId = searchParams.get('session_id');
    
    if (sessionId) {
      // Récupérer les données d'inscription sauvegardées
      const savedData = sessionStorage.getItem('signupData');
      if (savedData) {
        const parsedData = JSON.parse(savedData);
        Object.assign(data, parsedData);
      }
    }
  }, [data]);

  const statusMessages = [
    "Préparation de votre cave virtuelle... 🏰",
    "Mise en place de vos premières bouteilles... 🍷",
    "Configuration de votre espace... ⚙️",
    "Dernières finitions... ✨",
    "C'est presque prêt ! 🎉"
  ];

  const startCountdown = () => {
    const countdownTimer = setInterval(() => {
      setRedirectCountdown(prev => {
        if (prev <= 1) {
          clearInterval(countdownTimer);
          navigate('/login');
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  // Gérer la création du compte une seule fois
  useEffect(() => {
    let progressTimer: NodeJS.Timeout;
    let messageTimer: NodeJS.Timeout;

    // Mettre à jour les messages toutes les 2 secondes
    messageTimer = setInterval(() => {
      if (status === 'creating') {
        setMessageIndex(prev => (prev + 1) % statusMessages.length);
      }
    }, 2000);

    // Simuler la progression
    progressTimer = setInterval(() => {
      if (status === 'creating') {
        setProgress(prev => {
          if (prev >= 100) {
            clearInterval(progressTimer);
            return 100;
          }
          return prev + 2;
        });
      }
    }, 200);

    const createAccount = async () => {
      if (status !== 'creating' || accountCreatedRef.current) return;

      try {
        accountCreatedRef.current = true;
        console.log('Creating account with data:', data);

        // Check if email already exists
        const emailExists = await checkEmailExists(data.email);
        if (emailExists) {
          throw new Error('Un compte existe déjà avec cet email');
        }

        if (!data.email || !data.password || !data.firstName || !data.lastName || !data.companyName || !data.plan) {
          throw new Error('Informations manquantes pour la création du compte');
        }

        // Create user account
        await createUserAccount(data.email, data.password, {
          firstName: data.firstName,
          lastName: data.lastName,
          company: {
            name: data.companyName,
            slug: data.companyName.toLowerCase().replace(/[^a-z0-9]+/g, '-')
          },
          plan: data.plan,
          subscription: {
            status: data.plan === 'basic' ? 'inactive' : 'active',
            startDate: new Date().toISOString(),
            currentPeriodEnd: data.plan === 'basic' ? null : 
              new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString() // +30 jours
          },
          createdAt: new Date().toISOString(),
          isEmailVerified: false,
          twoFactorEnabled: false
        });

        // Wait before login attempt
        await new Promise(resolve => setTimeout(resolve, 1000));
        await login(data.email, data.password);
        
        setStatus('success');
        setTimeout(startCountdown, 1000);

        // Nettoyer les données de session après création réussie
        sessionStorage.removeItem('signupData');

      } catch (err: any) {
        console.error('Error in signup flow:', err);
        setStatus('error');
        setError(err.message || 'Une erreur est survenue lors de la création de votre compte');
      }
    };

    // Start account creation if we have all required data
    const hasRequiredData = data.email && data.password && data.firstName && 
                          data.lastName && data.companyName && data.plan;
    
    if (hasRequiredData && !accountCreatedRef.current) {
      createAccount();
    } else if (!hasRequiredData) {
      setStatus('error');
      setError('Informations manquantes pour la création du compte');
    }

    return () => {
      clearInterval(messageTimer);
      clearInterval(progressTimer);
    };
  }, [data, login, navigate, status, statusMessages]);

  return (
    <div className="text-center py-8 animate-fadeIn">
      <div className="flex justify-center mb-6">
        <div className="rounded-full bg-green-100 p-3">
          <CheckCircle2 className="h-12 w-12 text-green-500 animate-[bounce_1s_ease-in-out]" />
        </div>
      </div>

      <h2 className="text-2xl font-bold text-gray-900 mb-4">
        Bienvenue {data.firstName} !
      </h2>

      <div className="max-w-md mx-auto">
        {status === 'success' ? (
          <div className="bg-green-50 border border-green-200 rounded-lg p-4 mb-6">
            <p className="text-green-700 font-medium">
              Votre compte a bien été créé ! 🎉
            </p>
            <p className="text-sm text-green-600 mt-2 space-y-2">
              <span className="block">Redirection dans {redirectCountdown} seconde{redirectCountdown > 1 ? 's' : ''}...</span>
              <div className="w-full bg-gray-200 rounded-full h-2 mt-4">
                <div 
                  className="h-full bg-green-500 rounded-full transition-all duration-1000 ease-linear"
                  style={{ width: `${(redirectCountdown / 5) * 100}%` }}
                />
              </div>
            </p>
          </div>
        ) : (
          <p className="text-gray-600 mb-6 min-h-[24px] transition-all duration-300">
            {status === 'creating' && statusMessages[messageIndex]}
            {status === 'connecting' && 'Connexion en cours...'}
            {status === 'error' && 'Une erreur est survenue lors de la création du compte.'}
          </p>
        )}

        {error ? (
          <div className="p-4 bg-red-50 border border-red-200 rounded-lg text-sm text-red-600 mb-6">
            {error}
            <button
              onClick={() => window.location.reload()}
              className="block mt-2 text-purple-600 hover:text-purple-700"
            >
              Réessayer
            </button>
            <button
              onClick={() => navigate('/login')}
              className="block mt-2 text-purple-600 hover:text-purple-700"
            >
              Retour à la connexion
            </button>
          </div>
        ) : (
          <div className="relative h-2 bg-gray-200 rounded-full overflow-hidden">
            <div 
              className="absolute top-0 left-0 h-full bg-gradient-to-r from-purple-500 to-purple-600 transition-all duration-300 ease-linear"
              style={{ width: `${progress}%` }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Success;