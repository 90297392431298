import React, { useState, useEffect } from 'react';
import { TrendingUp } from 'lucide-react';
import { useAuthContext } from '../../contexts/AuthContext';
import { useStatistics } from '../../hooks/useStatistics';

const CuveeEvolutionChart: React.FC = () => {
  const { user } = useAuthContext();
  const { stats, loading, error } = useStatistics(user?.uid);
  const [metric, setMetric] = useState<'visits' | 'scans' | 'unique'>('visits');

  // Préparer les données pour le graphique d'évolution
  const prepareChartData = () => {
    if (!stats.length) return null;

    // Obtenir les 30 derniers jours
    const dates = Array.from({length: 30}, (_, i) => {
      const date = new Date();
      date.setDate(date.getDate() - i);
      return date.toISOString().split('T')[0];
    }).reverse();

    // Agréger les données de toutes les cuvées par jour
    const dailyTotals = dates.map(date => {
      const dayTotal = stats.reduce((total, cuveeStats) => {
        const dayStats = cuveeStats.dailyStats[date];
        if (!dayStats) return total;

        switch (metric) {
          case 'scans':
            return total + (dayStats.qrScans || 0);
          case 'visits':
            return total + (dayStats.views || 0);
          case 'unique':
            // Pour l'instant, nous n'avons pas de vraies données de visiteurs uniques
            return total + Math.floor((dayStats.views || 0) * 0.7);
          default:
            return total;
        }
      }, 0);

      return {
        date,
        value: dayTotal
      };
    });

    return dailyTotals;
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-80">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-600 py-8">
        Une erreur est survenue lors du chargement des statistiques
      </div>
    );
  }

  const chartData = prepareChartData();
  if (!chartData) {
    return (
      <div className="text-center py-8">
        <TrendingUp className="h-12 w-12 mx-auto text-gray-400 mb-4" />
        <p className="text-gray-500">Aucune donnée disponible</p>
      </div>
    );
  }

  // Trouver les valeurs min et max pour l'échelle
  const maxValue = Math.max(...chartData.map(d => d.value));

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-medium text-gray-900">
          Évolution des visites
        </h3>
        <select
          value={metric}
          onChange={(e) => setMetric(e.target.value as 'visits' | 'scans' | 'unique')}
          className="form-select rounded-lg border-gray-300 text-sm focus:border-purple-500 focus:ring-purple-500"
        >
          <option value="visits">Visites</option>
          <option value="scans">Scans QR</option>
          <option value="unique">Visiteurs uniques</option>
        </select>
      </div>

      <div className="relative h-80">
        <div className="absolute inset-0 flex items-end">
          {chartData.map((data, index) => (
            <div
              key={data.date}
              className="flex-1 flex flex-col justify-end mx-0.5"
              title={`${data.date}: ${data.value} ${
                metric === 'visits' ? 'visites' :
                metric === 'scans' ? 'scans' : 'visiteurs uniques'
              }`}
            >
              <div
                className="bg-purple-600 rounded-t transition-all duration-500"
                style={{
                  height: `${(data.value / maxValue) * 100}%`,
                  opacity: data.value > 0 ? 1 : 0.3
                }}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="flex justify-between text-xs text-gray-500">
        <span>{chartData[0].date}</span>
        <span>{chartData[chartData.length - 1].date}</span>
      </div>
    </div>
  );
};

export default CuveeEvolutionChart;