import React from 'react';
import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';

const LegalNotice = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <div className="bg-white rounded-xl shadow-sm p-8">
          <Link 
            to="/"
            className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 mb-6"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Retour
          </Link>

          <div className="border-b border-gray-200 pb-8 mb-8">
            <h1 className="text-3xl font-extrabold text-gray-900">
              Mentions Légales
            </h1>
            <p className="mt-2 text-sm text-gray-600">
              Dernière mise à jour : {new Date().toLocaleDateString()}
            </p>
          </div>

          <div className="prose prose-purple max-w-none space-y-8">
            <section className="bg-gray-50 p-6 rounded-lg">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">1. Éditeur du site</h2>
              <p className="text-gray-700">
                Le site Qvee est édité par :
              </p>
              <ul className="list-none space-y-2 text-gray-700">
                <li>Raison sociale : JR Informatique</li>
                <li>Forme juridique : Auto-entreprise</li>
                <li>Adresse : 36 rue du ranc, 30630 Saint André de Roquepertuis</li>
                <li>Téléphone : 09 77 21 99 98</li>
                <li>Email : contact@qvee.fr</li>
                <li>SIRET : 123 456 789 00012</li>
              </ul>
            </section>

            <section className="bg-gray-50 p-6 rounded-lg">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">2. Hébergement</h2>
              <p className="text-gray-700">
                Le site est hébergé par Firebase (Google Cloud Platform), dont le siège social est situé au :
              </p>
              <p className="text-gray-700">
                Google LLC<br />
                1600 Amphitheatre Parkway<br />
                Mountain View, CA 94043<br />
                États-Unis
              </p>
            </section>

            <section className="bg-gray-50 p-6 rounded-lg">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">3. Propriété intellectuelle</h2>
              <p className="text-gray-700">
                L'ensemble du contenu du site Qvee (textes, images, vidéos, etc.) est protégé par le droit d'auteur. 
                Toute reproduction ou représentation totale ou partielle de ce site par quelque procédé que ce soit, 
                sans autorisation expresse, est interdite et constituerait une contrefaçon sanctionnée par les articles 
                L.335-2 et suivants du Code de la propriété intellectuelle.
              </p>
            </section>

            <section className="bg-gray-50 p-6 rounded-lg">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">4. Protection des données personnelles</h2>
              <p className="text-gray-700">
                Conformément à la loi "Informatique et Libertés" du 6 janvier 1978 modifiée et au Règlement Général sur 
                la Protection des Données (RGPD), vous disposez d'un droit d'accès, de rectification, de suppression et 
                d'opposition aux données personnelles vous concernant.
              </p>
              <p className="text-gray-700 mt-4">
                Pour exercer ces droits ou pour toute question sur le traitement de vos données, vous pouvez nous 
                contacter à l'adresse : contact@qvee.fr
              </p>
            </section>

            <section className="bg-gray-50 p-6 rounded-lg">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">5. Cookies</h2>
              <p className="text-gray-700">
                Le site Qvee utilise des cookies techniques essentiels au fonctionnement du service. Ces cookies ne 
                collectent aucune donnée personnelle et sont utilisés uniquement pour assurer le bon fonctionnement 
                de la plateforme.
              </p>
            </section>

            <section className="bg-gray-50 p-6 rounded-lg">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">6. Responsabilité</h2>
              <p className="text-gray-700">
                Les informations fournies sur le site sont données à titre indicatif. L'exactitude, la complétude, 
                l'actualité des informations diffusées sur le site ne sauraient être garanties. En conséquence, 
                l'utilisateur reconnaît utiliser ces informations sous sa responsabilité exclusive.
              </p>
            </section>

            <section className="bg-gray-50 p-6 rounded-lg">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">7. Contact</h2>
              <p className="text-gray-700">
                Pour toute question concernant ces mentions légales ou pour signaler un contenu inapproprié, 
                vous pouvez nous contacter :
              </p>
              <ul className="list-none space-y-2 text-gray-700 mt-4">
                <li>Par email : contact@qvee.fr</li>
                <li>Par téléphone : 09 77 21 99 98</li>
                <li>Par courrier : 36 rue du ranc, 30630 Saint André de Roquepertuis</li>
              </ul>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LegalNotice;