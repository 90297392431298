import React from 'react';
import { Settings, Palette, QrCode, Lock, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

interface QRCodeSettingsProps {
  selectedCuvee: string;
  cuvees: Array<{ id: string; name: string; year: number; status: string }>;
  qrColor: string;
  bgColor: string;
  size: string;
  onCuveeChange: (value: string) => void;
  onQRColorChange: (value: string) => void;
  onBGColorChange: (value: string) => void;
  onSizeChange: (value: string) => void;
  userPlan: 'basic' | 'standard' | 'premium';
}

const QRCodeSettings: React.FC<QRCodeSettingsProps> = ({
  selectedCuvee,
  cuvees,
  qrColor,
  bgColor,
  size,
  onCuveeChange,
  onQRColorChange,
  onBGColorChange,
  onSizeChange,
  userPlan = 'basic'
}) => {
  const hasPremiumAccess = userPlan === 'premium';
  const showUpgradeMessage = userPlan === 'basic' || userPlan === 'standard';
  const planPrice = '648';
  const planName = 'Premium';

  const getQRCodeValue = (cuveeId: string) => {
    const cuvee = cuvees.find(c => c.id === cuveeId);
    if (!cuvee) return '';
    const cuveeSlug = cuvee.name.toLowerCase().replace(/[^a-z0-9]+/g, '-');
    return `${window.location.origin}/${cuveeSlug}?qr=true`;
  };

  return (
    <div className="space-y-6">
      <div>
        <div className="flex items-center space-x-2 mb-4">
          <QrCode className="h-5 w-5 text-gray-400" />
          <label htmlFor="cuvee" className="block text-sm font-medium text-gray-700">
            Sélectionner une cuvée
          </label>
        </div>
        <div className="relative">
          <select
            id="cuvee"
            value={selectedCuvee}
            onChange={(e) => onCuveeChange(e.target.value)}
            className="mt-1 block w-full pl-3 pr-10 py-3 text-base border border-gray-300 bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent rounded-lg shadow-sm transition duration-150 ease-in-out"
          >
            <option value="">Choisir une cuvée</option>
            {cuvees
              .filter(cuvee => cuvee.status === 'published')
              .map((cuvee) => (
              <option key={cuvee.id} value={cuvee.id}>
                {cuvee.name} ({cuvee.year})
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="space-y-6">
        <div className="flex items-center space-x-2 mb-4">
          <Settings className="h-5 w-5 text-gray-400" />
          <h3 className="text-lg font-medium text-gray-900">Personnalisation</h3>
        </div>

        {showUpgradeMessage ? (
          <div className="bg-gradient-to-br from-purple-50 to-white border border-purple-100 rounded-lg p-8 space-y-6">
            <div className="text-center">
              <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-purple-100 mb-4">
                <Lock className="h-6 w-6 text-purple-600" />
              </div>
              <h4 className="text-lg font-semibold text-gray-900 mb-2">
                Débloquez la Personnalisation Premium
              </h4>
              <p className="text-sm text-gray-600 max-w-md mx-auto mb-4">
                Passez au plan Premium pour personnaliser vos QR codes avec vos propres couleurs et styles.
              </p>
              <p className="text-xs text-purple-600 font-medium mb-4">
                Passez au plan Premium pour 25€/mois
              </p>
              <div className="mt-6 space-y-4">
                <div className="flex items-center justify-center space-x-2 text-sm text-purple-700">
                  <span className="flex items-center">
                    <span className="w-2 h-2 bg-purple-600 rounded-full mr-2"></span>
                    Couleurs personnalisées
                  </span>
                  <span className="flex items-center">
                    <span className="w-2 h-2 bg-purple-600 rounded-full mr-2"></span>
                    Taille ajustable
                  </span>
                  <span className="flex items-center">
                    <span className="w-2 h-2 bg-purple-600 rounded-full mr-2"></span>
                    Design professionnel
                  </span>
                </div>
                <Link
                  to="/upsells"
                  className="inline-flex items-center justify-center px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors duration-200 whitespace-nowrap"
                >
                  Passer au plan Premium pour 25€/mois
                  <ArrowRight className="ml-2 h-4 w-4" />
                </Link>
              </div>

              <div className="space-y-6 opacity-50 pointer-events-none mt-8 border-t border-purple-100 pt-8">
                <div>
                  <label htmlFor="qr-color" className="block text-sm font-medium text-gray-700 mb-2">
                    Couleur du QR Code
                  </label>
                  <div className="flex items-center space-x-2">
                    <div className="relative flex-shrink-0">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <Palette className="h-5 w-5 text-gray-400" />
                      </div>
                      <input
                        type="color"
                        id="qr-color"
                        value={qrColor}
                        className="sr-only"
                        disabled
                      />
                      <div 
                        className="w-10 h-10 rounded-lg border-2 border-gray-200 cursor-not-allowed shadow-sm"
                        style={{ backgroundColor: qrColor }}
                      />
                    </div>
                    <input
                      type="text"
                      value={qrColor}
                      disabled
                      className="flex-1 pl-3 pr-3 py-3 border-2 border-gray-200 bg-gray-50 rounded-lg shadow-sm cursor-not-allowed"
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="bg-color" className="block text-sm font-medium text-gray-700 mb-2">
                    Couleur de fond
                  </label>
                  <div className="flex items-center space-x-2">
                    <div className="relative flex-shrink-0">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <Palette className="h-5 w-5 text-gray-400" />
                      </div>
                      <input
                        type="color"
                        id="bg-color"
                        value={bgColor}
                        className="sr-only"
                        disabled
                      />
                      <div 
                        className="w-10 h-10 rounded-lg border border-gray-300 cursor-not-allowed"
                        style={{ backgroundColor: bgColor }}
                      />
                    </div>
                    <input
                      type="text"
                      value={bgColor}
                      disabled
                      className="flex-1 pl-3 pr-3 py-3 border border-gray-300 bg-gray-50 rounded-lg shadow-sm cursor-not-allowed"
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="size" className="block text-sm font-medium text-gray-700 mb-2">
                    Taille (px)
                  </label>
                  <input
                    type="number"
                    id="size"
                    value={size}
                    disabled
                    min="128"
                    max="1024"
                    step="32"
                    className="block w-full pl-3 pr-3 py-3 border border-gray-300 bg-gray-50 rounded-lg shadow-sm cursor-not-allowed"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="space-y-4">
            <div>
              <label htmlFor="qr-color" className="block text-sm font-medium text-gray-700 mb-2">
                Couleur du QR Code
              </label>
              <div className="flex items-center space-x-2">
                <div className="relative flex-shrink-0">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Palette className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="color"
                    id="qr-color"
                    value={qrColor}
                    onChange={(e) => onQRColorChange(e.target.value)}
                    className="sr-only"
                  />
                  <div 
                    className="w-10 h-10 rounded-lg border border-gray-300 cursor-pointer"
                    style={{ backgroundColor: qrColor }}
                    onClick={() => document.getElementById('qr-color')?.click()}
                  />
                </div>
                <input
                  type="text"
                  value={qrColor}
                  onChange={(e) => onQRColorChange(e.target.value)}
                  className="flex-1 pl-3 pr-3 py-3 border border-gray-300 bg-gray-50 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                />
              </div>
            </div>

            <div>
              <label htmlFor="bg-color" className="block text-sm font-medium text-gray-700 mb-2">
                Couleur de fond
              </label>
              <div className="flex items-center space-x-2">
                <div className="relative flex-shrink-0">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Palette className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="color"
                    id="bg-color"
                    value={bgColor}
                    onChange={(e) => onBGColorChange(e.target.value)}
                    className="sr-only"
                  />
                  <div 
                    className="w-10 h-10 rounded-lg border border-gray-300 cursor-pointer"
                    style={{ backgroundColor: bgColor }}
                    onClick={() => document.getElementById('bg-color')?.click()}
                  />
                </div>
                <input
                  type="text"
                  value={bgColor}
                  onChange={(e) => onBGColorChange(e.target.value)}
                  className="flex-1 pl-3 pr-3 py-3 border border-gray-300 bg-gray-50 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                />
              </div>
            </div>

            <div>
              <label htmlFor="size" className="block text-sm font-medium text-gray-700 mb-2">
                Taille (px)
              </label>
              <input
                type="number"
                id="size"
                value={size}
                onChange={(e) => onSizeChange(e.target.value)}
                min="128"
                max="1024"
                step="32"
                className="block w-full pl-3 pr-3 py-3 border border-gray-300 bg-gray-50 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
              />
              <p className="mt-2 text-sm text-gray-500">
                Taille recommandée entre 128px et 1024px
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QRCodeSettings;