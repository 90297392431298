import React from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

interface WelcomeHeaderProps {
  userName: string;
}

const WelcomeHeader: React.FC<WelcomeHeaderProps> = ({ userName }) => {
  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return 'Bonjour';
    if (hour < 18) return 'Bon après-midi';
    return 'Bonsoir';
  };

  return (
    <div className="text-center">
      <h1 className="text-3xl font-bold text-gray-900 mb-2">
        {getGreeting()}, {userName} 👋
      </h1>
      <p className="text-gray-600">
        {format(new Date(), "EEEE d MMMM yyyy", { locale: fr })}
      </p>
    </div>
  );
};

export default WelcomeHeader;