import { collection, query, where, getDocs, addDoc, deleteDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import slugify from 'slugify';
import type { Cuvee } from '../hooks/useCuvees';
import type { PublishedPage } from '../hooks/usePublishedPages';

export class PagePublisher {
  static generatePageSlug(text: string): string {
    return slugify(text, {
      lower: true,
      strict: true,
      locale: 'fr',
      trim: true
    });
  }

  static async handleCuveePublication(cuvee: Cuvee, userId: string): Promise<string | null> {
    if (!cuvee || !userId) {
      console.warn('Missing cuvee or userId for publication');
      return null;
    }

    if (cuvee.status !== 'published') {
      console.log(`Skipping publication for cuvee ${cuvee.id} - not published`);
      return null;
    }

    try {
      console.log(`Starting publication process for cuvee ${cuvee.id}`);

      const userDoc = await getDoc(doc(db, 'users', userId));
      if (!userDoc.exists()) {
        console.error('User not found');
        return null;
      }

      // La vérification des limites est maintenant gérée par le hook usePlanLimits
      // Nous faisons confiance au composant parent pour vérifier les limites

      let design;
      try {
        design = await this.getUserDesign(userId);
      } catch (err: any) {
        throw new Error(`Configuration du design requise : ${err.message}`);
      }

      let companyInfo;
      try {
        companyInfo = await this.getUserCompanyInfo(userId);
      } catch (err: any) {
        throw new Error(`Configuration de l'entreprise requise : ${err.message}`);
      }

      const pageSlug = this.generatePageSlug(cuvee.name);
      const companySlug = this.generatePageSlug(companyInfo.name);
      const fullUrl = `${companySlug}/${pageSlug}`;
      
      // Vérifier si une page existe déjà
      const existingPage = await this.getPageByCuveeId(cuvee.id);
      if (existingPage) {
        console.log(`Updating existing page ${existingPage.id}`);
        await this.updatePublishedPage(existingPage.id, cuvee, design, companyInfo);
        return existingPage.fullUrl;
      }

      console.log(`Creating new published page for cuvee ${cuvee.id}`);
      const pageData = {
        userId,
        cuveeId: cuvee.id,
        companySlug,
        pageSlug,
        fullUrl,
        content: {
          cuvee,
          design,
          company: companyInfo
        },
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };

      const pagesRef = collection(db, 'publishedPages');
      await addDoc(pagesRef, pageData);
      console.log(`Successfully published page for cuvee ${cuvee.id}`);

      return fullUrl;
    } catch (err: any) {
      console.error('Error handling cuvee publication:', err);
      throw err;
    }
  }

  static async handleCuveeUnpublication(cuveeId: string): Promise<void> {
    try {
      console.log(`Starting unpublication process for cuvee ${cuveeId}`);
      
      const pagesRef = collection(db, 'publishedPages');
      
      // Récupérer toutes les pages publiées pour cette cuvée
      const q = query(pagesRef, where('cuveeId', '==', cuveeId));
      const snapshot = await getDocs(q);
      
      if (snapshot.empty) {
        console.log('No published pages found to unpublish');
        return;
      }
      
      // Supprimer les pages une par une avec gestion d'erreur individuelle
      for (const doc of snapshot.docs) {
        try {
          await deleteDoc(doc.ref);
          console.log(`Successfully deleted page ${doc.id}`);
        } catch (err) {
          console.warn(`Failed to delete page ${doc.id}:`, err);
          // Continue avec les autres pages même si une suppression échoue
        }
      }

      console.log('Unpublication process completed');
    } catch (err: any) {
      console.error('Error handling cuvee unpublication:', err);
      // Ne pas propager l'erreur pour éviter de bloquer le processus
    }
  }

  static async handleCuveeBlocking(cuveeId: string): Promise<void> {
    try {
      // Dépublier la page lorsque la cuvée est bloquée
      await this.handleCuveeUnpublication(cuveeId);
    } catch (err: any) {
      console.error('Error handling cuvee blocking:', err);
      throw new Error(err.message || 'Erreur lors du blocage de la cuvée');
    }
  }

  static async handleCuveeDeletion(cuveeId: string): Promise<void> {
    try {
      // Supprimer la page publiée si elle existe
      await this.handleCuveeUnpublication(cuveeId);
    } catch (err: any) {
      console.error('Error handling cuvee deletion:', err);
      throw new Error(err.message || 'Erreur lors de la suppression de la cuvée');
    }
  }

  static async handleCuveeUnblocking(cuvee: Cuvee, userId: string, shouldPublish: boolean): Promise<void> {
    try {
      // Si la cuvée doit être publiée après le déblocage
      if (shouldPublish) {
        await this.handleCuveePublication(cuvee, userId);
      }
    } catch (err: any) {
      console.error('Error handling cuvee unblocking:', err);
      throw new Error(err.message || 'Erreur lors du déblocage de la cuvée');
    }
  }

  static async getUserDesign(userId: string): Promise<any> {
    if (!userId) {
      throw new Error('ID utilisateur requis');
    }

    try {
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (!userDoc.exists()) {
        throw new Error('Utilisateur non trouvé');
      }

      const userData = userDoc.data();
      
      // Créer un design par défaut si aucun n'existe
      const defaultDesign = {
        colors: {
          primary: '#7e22ce',
          secondary: '#9333ea',
          text: '#1f2937',
          background: '#ffffff'
        },
        typography: {
          headingFont: 'Inter',
          bodyFont: 'Inter'
        },
        footer: {
          text: '',
          showSocials: true
        },
        display: {
          showFooter: true,
          showCompanyName: true,
          showLogo: true,
          showCertifications: true
        }
      };

      // Si aucun design n'est configuré, utiliser le design par défaut
      if (!userData.design) {
        return defaultDesign;
      }

      // Fusionner avec le design par défaut pour s'assurer que tous les champs requis sont présents
      return {
        colors: {
          ...defaultDesign.colors,
          ...userData.design?.colors
        },
        typography: {
          ...defaultDesign.typography,
          ...userData.design?.typography
        },
        footer: {
          ...defaultDesign.footer,
          ...userData.design?.footer
        },
        display: {
          ...defaultDesign.display,
          ...userData.design?.display
        }
      };
    } catch (err: any) {
      console.error('Error getting user design:', err);
      throw new Error(err.message || 'Erreur lors de la récupération du design');
    }
  }

  static async getUserCompanyInfo(userId: string): Promise<any> {
    if (!userId) {
      throw new Error('ID utilisateur requis');
    }

    try {
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (!userDoc.exists()) {
        throw new Error('Utilisateur non trouvé');
      }

      const userData = userDoc.data();
      
      // Créer des informations d'entreprise par défaut si aucune n'existe
      const defaultCompanyInfo = {
        name: 'Mon Entreprise',
        certifications: [],
        address: {
          street: '',
          city: '',
          postalCode: '',
          country: ''
        }
      };

      // Si aucune information d'entreprise n'est configurée, utiliser les valeurs par défaut
      if (!userData.company) {
        return defaultCompanyInfo;
      }

      // Fusionner avec les informations par défaut
      return {
        ...defaultCompanyInfo,
        ...userData.company,
        certifications: userData.certifications || [],
        address: {
          ...defaultCompanyInfo.address,
          ...userData.company.address
        }
      };
    } catch (err: any) {
      console.error('Error getting company info:', err);
      throw new Error(err.message || 'Erreur lors de la récupération des informations de l\'entreprise');
    }
  }

  static async getPageByCuveeId(cuveeId: string): Promise<PublishedPage | null> {
    try {
      const pagesRef = collection(db, 'publishedPages');
      const q = query(pagesRef, where('cuveeId', '==', cuveeId));
      const snapshot = await getDocs(q);

      if (snapshot.empty) {
        return null;
      }

      const pageDoc = snapshot.docs[0];
      return {
        id: pageDoc.id,
        ...pageDoc.data()
      } as PublishedPage;
    } catch (err: any) {
      console.error('Error getting page by cuvee ID:', err);
      throw new Error('Erreur lors de la récupération de la page');
    }
  }

  static async getPageByUrl(companySlug: string, pageSlug: string): Promise<PublishedPage | null> {
    try {
      const pagesRef = collection(db, 'publishedPages');
      const q = query(pagesRef,
        where('companySlug', '==', companySlug),
        where('pageSlug', '==', pageSlug)
      );

      const snapshot = await getDocs(q);

      if (snapshot.empty) {
        return null;
      }

      // Vérifier que la cuvée associée est toujours publiée
      const pageData = snapshot.docs[0].data();
      const cuveeDoc = await getDoc(doc(db, 'cuvees', pageData.cuveeId));
      
      // Si la cuvée n'existe plus ou n'est plus publiée, supprimer la page publiée
      if (!cuveeDoc.exists() || cuveeDoc.data().status !== 'published') {
        await this.handleCuveeUnpublication(pageData.cuveeId);
        return null;
      }

      return { id: snapshot.docs[0].id, ...pageData } as PublishedPage;
    } catch (err: any) {
      console.error('Error getting page by URL:', err);
      throw new Error('Erreur lors de la récupération de la page');
    }
  }

  static async updatePublishedPage(
    pageId: string,
    cuvee: Cuvee,
    design: any,
    companyInfo: any
  ): Promise<void> {
    try {
      const pageDoc = await getDoc(doc(db, 'publishedPages', pageId));
      if (!pageDoc.exists()) {
        console.warn('Published page not found');
        return;
      }

      // Vérifier que l'utilisateur est bien le propriétaire
      const pageData = pageDoc.data();
      if (pageData.userId !== cuvee.userId) {
        console.warn('User does not own this page');
        return;
      }

      // Get latest user design settings
      const userDoc = await getDoc(doc(db, 'users', cuvee.userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (userData.design?.display) {
          design.display = {
            ...design.display,
            ...userData.design.display
          };
        }
      }
      const pageRef = doc(db, 'publishedPages', pageId);
      await updateDoc(pageRef, {
        'content.cuvee': cuvee,
        'content.design': design,
        'content.company': companyInfo,
        updatedAt: new Date().toISOString()
      });

      console.log(`Successfully updated page ${pageId}`);
    } catch (err: any) {
      console.error('Error updating published page:', err);
      // Ne pas propager l'erreur pour éviter de bloquer le processus
    }
  }
}