import { initializeApp } from 'firebase/app';
import { 
  getFirestore, 
  doc, 
  getDoc, 
  updateDoc, 
  collection,
  query,
  where,
  getDocs,
  addDoc,
  deleteDoc,
  serverTimestamp,
  setDoc
} from 'firebase/firestore';
import { 
  getStorage, 
  ref, 
  uploadBytes, 
  getDownloadURL, 
  deleteObject 
} from 'firebase/storage';
import { 
  getAuth,
  fetchSignInMethodsForEmail,
  sendPasswordResetEmail,
  confirmPasswordReset,
  verifyPasswordResetCode,
  updateEmail,
  EmailAuthProvider,
  reauthenticateWithCredential,
  verifyBeforeUpdateEmail,
  createUserWithEmailAndPassword,
  signInWithCredential,
  applyActionCode
} from 'firebase/auth';
import { FIREBASE_CONFIG } from './config';
import { 
  sendPasswordResetEmail as sendBrevoResetEmail, 
  send2FAEmail, 
  send2FAEmail as sendBrevo2FAEmail,
  send2FASetupEmail, 
  sendVerificationEmail,
  sendDeletionVerificationEmail,
  sendQRCodeDeletionVerificationEmail,
  sendSuspendedUserEmail,
  sendBlockedCuveeEmail
} from './brevo';
import { 
  onAuthStateChanged,
  onIdTokenChanged
} from 'firebase/auth';

// Initialize Firebase
const app = initializeApp(FIREBASE_CONFIG);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

// Check if email exists
const checkEmailExists = async (email: string): Promise<boolean> => {
  try {
    const normalizedEmail = email.toLowerCase();

    try {
      // Check Firebase Auth first
      const signInMethods = await fetchSignInMethodsForEmail(auth, normalizedEmail); 
      return signInMethods.length > 0;
    } catch (err: any) {
      // If error is auth/invalid-email, return false
      if (err.code === 'auth/invalid-email') {
        return false;
      }
      throw err;
    }

    // Then check Firestore as backup
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('email', '==', normalizedEmail));
    const snapshot = await getDocs(q);
    
    return !snapshot.empty;
  } catch (error) {
    console.error('Error checking email:', error);
    throw error;
  }
};

// Generate a 6-digit verification code
const generateVerificationCode = () => {
  return Math.floor(100000 + Math.random() * 900000).toString();
};

// Generate a URL-friendly slug
const generateSlug = (text: string): string => {
  return text
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/(^-|-$)/g, '');
};

// Send email verification code
const sendEmailVerificationCode = async (userId: string, newEmail: string) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      throw new Error('Utilisateur non trouvé');
    }

    const userData = userDoc.data();
    const code = generateVerificationCode();
    const expiresAt = new Date();
    expiresAt.setMinutes(expiresAt.getMinutes() + 10);

    await updateDoc(doc(db, 'users', userId), {
      emailVerificationCode: code,
      emailVerificationCodeExpiresAt: expiresAt.toISOString(),
      pendingEmail: newEmail,
      pendingEmailTimestamp: new Date().toISOString()
    });

    await send2FAEmail(newEmail, userData.firstName, code);

    return { success: true, message: 'Code envoyé avec succès' };
  } catch (err: any) {
    console.error('Error sending email verification code:', err);
    return { success: false, message: err.message };
  }
};

// Send suspended user notification
const sendSuspendedUserNotification = async (userId: string) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      throw new Error('Utilisateur non trouvé');
    }

    const userData = userDoc.data();
    await sendSuspendedUserEmail(userData.email, userData.firstName);

    return { success: true };
  } catch (err: any) {
    console.error('Error sending suspension notification:', err);
    return { success: false, message: err.message };
  }
};

// Set admin role
const setAdminRole = async (email: string) => {
  try {
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('email', '==', email));
    const snapshot = await getDocs(q);

    if (snapshot.empty) {
      return { success: false, message: 'Utilisateur non trouvé' };
    }

    const userDoc = snapshot.docs[0];
    await updateDoc(doc(db, 'users', userDoc.id), {
      role: 'admin',
      roleUpdatedAt: new Date().toISOString()
    });

    return { success: true, message: 'Rôle administrateur défini avec succès' };
  } catch (err: any) {
    console.error('Error setting admin role:', err);
    return { success: false, message: err.message };
  }
};

// Password Reset Functions
const initiatePasswordReset = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
    await sendBrevoResetEmail(email, '', '');
    return { success: true };
  } catch (error) {
    console.error('Error initiating password reset:', error);
    throw error;
  }
};

const verifyResetCode = async (code: string) => {
  try {
    await verifyPasswordResetCode(auth, code);
    return { success: true };
  } catch (error) {
    console.error('Error verifying reset code:', error);
    throw error;
  }
};

const completePasswordReset = async (code: string, newPassword: string) => {
  try {
    await confirmPasswordReset(auth, code, newPassword);
    return { success: true };
  } catch (error) {
    console.error('Error completing password reset:', error);
    throw error;
  }
};

// Email verification
const sendVerificationCode = async (userId: string) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      throw new Error('Utilisateur non trouvé');
    }

    const userData = userDoc.data();
    const code = generateVerificationCode();
    const expiresAt = new Date();
    expiresAt.setHours(expiresAt.getHours() + 24);

    await updateDoc(doc(db, 'users', userId), {
      emailVerificationCode: code,
      emailVerificationCodeExpiresAt: expiresAt.toISOString()
    });

    await sendVerificationEmail(userData.email, userData.firstName, code);

    return { success: true, message: 'Code envoyé avec succès' };
  } catch (err: any) {
    console.error('Error sending verification code:', err);
    return { success: false, message: err.message };
  }
};

const verifyEmailCode = async (userId: string, code: string) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      throw new Error('Utilisateur non trouvé');
    }

    const userData = userDoc.data();
    const now = new Date();
    const expiresAt = new Date(userData.emailVerificationCodeExpiresAt);

    if (now > expiresAt) {
      throw new Error('Le code a expiré');
    }

    if (code !== userData.emailVerificationCode) {
      throw new Error('Code invalide');
    }

    await updateDoc(doc(db, 'users', userId), {
      isEmailVerified: true,
      emailVerifiedAt: now.toISOString(),
      emailVerificationCode: null,
      emailVerificationCodeExpiresAt: null
    });

    return { success: true, message: 'Email vérifié avec succès' };
  } catch (err: any) {
    console.error('Error verifying code:', err);
    return { success: false, message: err.message };
  }
};

// 2FA Setup
const sendTwoFactorSetupCode = async (userId: string) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      throw new Error('Utilisateur non trouvé');
    }

    const userData = userDoc.data();
    const code = generateVerificationCode();
    const expiresAt = new Date();
    expiresAt.setMinutes(expiresAt.getMinutes() + 10);

    await updateDoc(doc(db, 'users', userId), {
      twoFactorSetupCode: code,
      twoFactorSetupCodeExpiresAt: expiresAt.toISOString()
    });

    await send2FASetupEmail(userData.email, userData.firstName, code);

    return { success: true, message: 'Code envoyé avec succès' };
  } catch (err: any) {
    console.error('Error sending 2FA setup code:', err);
    return { success: false, message: err.message };
  }
};

const verifyTwoFactorSetupCode = async (userId: string, code: string) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      throw new Error('Utilisateur non trouvé');
    }

    const userData = userDoc.data();
    const now = new Date();
    const expiresAt = new Date(userData.twoFactorSetupCodeExpiresAt);

    if (now > expiresAt) {
      throw new Error('Le code a expiré');
    }

    if (code !== userData.twoFactorSetupCode) {
      throw new Error('Code invalide');
    }

    await updateDoc(doc(db, 'users', userId), {
      twoFactorEnabled: true,
      twoFactorEnabledAt: now.toISOString(),
      twoFactorSetupCode: null,
      twoFactorSetupCodeExpiresAt: null
    });

    return { success: true, message: '2FA activé avec succès' };
  } catch (err: any) {
    console.error('Error verifying 2FA setup code:', err);
    return { success: false, message: err.message };
  }
};

// Fonction pour initialiser le document admin s'il n'existe pas
const initializeAdminDocument = async () => {
  const adminRef = doc(db, 'users', 'admin');
  const adminDoc = await getDoc(adminRef);
  
  if (!adminDoc.exists()) {
    await setDoc(adminRef, {
      email: 'contact@jrinformatique.fr',
      firstName: 'Admin',
      lastName: 'System',
      role: 'admin',
      createdAt: new Date().toISOString(),
      lastLoginAt: new Date().toISOString()
    });
  }
};

// Fonction pour envoyer un code 2FA
const sendTwoFactorCode = async (userId: string): Promise<{ success: boolean; message?: string }> => {
  try {
    // Cas spécial pour l'administrateur
    if (userId === 'admin') {
      const adminDoc = await getDoc(doc(db, 'users', 'admin'));
      if (!adminDoc.exists()) {
        // Créer le document admin s'il n'existe pas
        await setDoc(doc(db, 'users', 'admin'), {
          email: 'contact@jrinformatique.fr',
          firstName: 'Admin',
          lastName: 'System',
          role: 'admin',
          createdAt: new Date().toISOString(),
          lastLoginAt: new Date().toISOString()
        });
      }

      const code = generateVerificationCode();
      const expiresAt = new Date();
      expiresAt.setMinutes(expiresAt.getMinutes() + 10);

      // Stocker le code pour l'admin
      await updateDoc(doc(db, 'users', 'admin'), {
        twoFactorCode: code,
        twoFactorCodeExpiresAt: expiresAt.toISOString()
      });

      // Envoyer l'email à l'admin
      await sendBrevo2FAEmail('contact@jrinformatique.fr', 'Admin', code);
      return { success: true };
    }

    // Pour les autres utilisateurs
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      throw new Error('Utilisateur non trouvé');
    }

    const userData = userDoc.data();
    const code = generateVerificationCode();
    const expiresAt = new Date();
    expiresAt.setMinutes(expiresAt.getMinutes() + 10);

    await updateDoc(doc(db, 'users', userId), {
      twoFactorCode: code,
      twoFactorCodeExpiresAt: expiresAt.toISOString()
    });

    await sendBrevo2FAEmail(userData.email, userData.firstName, code);
    return { success: true };
  } catch (err: any) {
    console.error('Error sending 2FA code:', err);
    return { success: false, message: err.message || 'Erreur lors de l\'envoi du code' };
  }
};

// Fonction pour vérifier le code 2FA
const verifyTwoFactorCode = async (userId: string, code: string): Promise<{ success: boolean; message?: string }> => {
  try {
    // Si c'est l'administrateur
    if (userId === 'admin') {
      const adminDoc = await getDoc(doc(db, 'users', 'admin'));
      if (!adminDoc.exists()) {
        throw new Error('Admin non trouvé');
      }

      const adminData = adminDoc.data();
      if (code !== adminData.twoFactorCode) {
        throw new Error('Code invalide');
      }

      const expiresAt = new Date(adminData.twoFactorCodeExpiresAt);
      if (new Date() > expiresAt) {
        throw new Error('Code expiré');
      }

      await updateDoc(doc(db, 'users', 'admin'), {
        twoFactorCode: null,
        twoFactorCodeExpiresAt: null
      });

      return { success: true };
    }

    // Pour les autres utilisateurs
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      throw new Error('Utilisateur non trouvé');
    }

    const userData = userDoc.data();
    if (code !== userData.twoFactorCode) {
      throw new Error('Code invalide');
    }

    const expiresAt = new Date(userData.twoFactorCodeExpiresAt);
    if (new Date() > expiresAt) {
      throw new Error('Code expiré');
    }

    await updateDoc(doc(db, 'users', userId), {
      twoFactorCode: null,
      twoFactorCodeExpiresAt: null
    });

    return { success: true };
  } catch (err: any) {
    console.error('Error verifying 2FA code:', err);
    return { success: false, message: err.message || 'Erreur lors de la vérification du code' };
  }
};

// Account deletion verification
const sendDeletionCode = async (userId: string) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      throw new Error('Utilisateur non trouvé');
    }

    const userData = userDoc.data();
    const code = generateVerificationCode();
    const expiresAt = new Date();
    expiresAt.setMinutes(expiresAt.getMinutes() + 10);

    await updateDoc(doc(db, 'users', userId), {
      deletionCode: code,
      deletionCodeExpiresAt: expiresAt.toISOString()
    });

    await sendDeletionVerificationEmail(userData.email, userData.firstName, code);

    return { success: true, message: 'Code envoyé avec succès' };
  } catch (err: any) {
    console.error('Error sending deletion code:', err);
    return { success: false, message: err.message };
  }
};

const verifyDeletionCode = async (userId: string, code: string) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      throw new Error('Utilisateur non trouvé');
    }

    const userData = userDoc.data();
    const now = new Date();
    const expiresAt = new Date(userData.deletionCodeExpiresAt);

    if (now > expiresAt) {
      throw new Error('Le code a expiré');
    }

    if (code !== userData.deletionCode) {
      throw new Error('Code invalide');
    }

    await updateDoc(doc(db, 'users', userId), {
      deletionCode: null,
      deletionCodeExpiresAt: null
    });

    return { success: true, message: 'Code vérifié avec succès' };
  } catch (err: any) {
    console.error('Error verifying deletion code:', err);
    return { success: false, message: err.message };
  }
};

// QR Code deletion verification
const sendQRCodeDeletionCode = async (userId: string) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      throw new Error('Utilisateur non trouvé');
    }

    const userData = userDoc.data();
    const code = generateVerificationCode();
    const expiresAt = new Date();
    expiresAt.setMinutes(expiresAt.getMinutes() + 10);

    await updateDoc(doc(db, 'users', userId), {
      qrCodeDeletionCode: code,
      qrCodeDeletionCodeExpiresAt: expiresAt.toISOString()
    });

    await sendQRCodeDeletionVerificationEmail(userData.email, userData.firstName, code);

    return { success: true, message: 'Code envoyé avec succès' };
  } catch (err: any) {
    console.error('Error sending QR code deletion code:', err);
    return { success: false, message: err.message };
  }
};

const verifyQRCodeDeletionCode = async (userId: string, code: string) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      throw new Error('Utilisateur non trouvé');
    }

    const userData = userDoc.data();
    const now = new Date();
    const expiresAt = new Date(userData.qrCodeDeletionCodeExpiresAt);

    if (now > expiresAt) {
      throw new Error('Le code a expiré');
    }

    if (code !== userData.qrCodeDeletionCode) {
      throw new Error('Code invalide');
    }

    await updateDoc(doc(db, 'users', userId), {
      qrCodeDeletionCode: null,
      qrCodeDeletionCodeExpiresAt: null
    });

    return { success: true, message: 'Code vérifié avec succès' };
  } catch (err: any) {
    console.error('Error verifying QR code deletion code:', err);
    return { success: false, message: err.message };
  }
};

// Create user account
const createUserAccount = async (email: string, password: string, userData: any) => {
  try {
    // Double-check email existence before creating account
    const emailExists = await checkEmailExists(email);
    if (emailExists) {
      throw new Error('Un compte existe déjà avec cet email');
    }

    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const userId = userCredential.user.uid;

    await setDoc(doc(db, 'users', userId), {
      ...userData,
      email: email.toLowerCase(),
      createdAt: new Date().toISOString(),
      lastLoginAt: new Date().toISOString()
    });

    return userCredential;
  } catch (error) {
    console.error('Error creating user account:', error);
    throw error;
  }
};

// Ajouter cette fonction pour synchroniser l'email
const syncEmailWithFirestore = async (user: any) => {
  if (!user) return;

  try {
    const userRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userRef);
    
    // Vérifier si le document existe avant de tenter la mise à jour
    if (userDoc.exists()) {
      await updateDoc(userRef, {
        email: user.email?.toLowerCase(),
        emailUpdatedAt: new Date().toISOString(),
        isEmailVerified: user.emailVerified,
        pendingEmail: null,
        pendingEmailTimestamp: null,
        pendingEmailCredential: null
      });
      console.log('Email synchronized successfully in Firestore');
    } else {
      console.log('User document not found - skipping email sync');
    }
  } catch (error) {
    // Ignorer l'erreur "not-found" car elle est attendue pour les nouveaux comptes
    if (error.code !== 'not-found') {
      console.error('Error syncing email:', error);
    }
  }
};

// Ajouter un listener pour synchroniser les changements d'email
onAuthStateChanged(auth, async (user) => {
  if (user) {
    try {
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);
      
      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (userData.email !== user.email?.toLowerCase()) {
          await forceEmailSync(user.uid, user.email!);
        }
      }
    } catch (error) {
      console.error('Error in onAuthStateChanged:', error);
    }
  }
});


// Ajouter cette nouvelle fonction pour une synchronisation forcée
const forceEmailSync = async (userId: string, newEmail: string): Promise<boolean> => {
  try {
    // 1. Mise à jour dans Firestore
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      email: newEmail.toLowerCase(),
      emailUpdatedAt: new Date().toISOString(),
      isEmailVerified: true,
      pendingEmail: null,
      pendingEmailTimestamp: null,
      pendingEmailCredential: null
    });

    // 2. Vérifier la mise à jour
    const updatedDoc = await getDoc(userRef);
    if (!updatedDoc.exists() || updatedDoc.data().email !== newEmail.toLowerCase()) {
      throw new Error('La synchronisation de l\'email a échoué');
    }

    console.log('Email synchronized successfully');
    return true;
  } catch (error) {
    console.error('Force sync error:', error);
    throw error;
  }
};

// Verify and update email
const verifyAndUpdateEmail = async (userId: string, newEmail: string): Promise<void> => {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    
    if (!userDoc.exists()) {
      throw new Error('Utilisateur non trouvé');
    }

    const userData = userDoc.data();
    const pendingEmail = userData.pendingEmail;
    const pendingEmailCredential = userData.pendingEmailCredential;

    if (!pendingEmail || !pendingEmailCredential || pendingEmail !== newEmail) {
      throw new Error('Informations de vérification invalides');
    }

    const user = auth.currentUser;
    if (!user) throw new Error('Utilisateur non connecté');

    // 1. Réauthentifier l'utilisateur
    const credential = EmailAuthProvider.credential(
      pendingEmailCredential.email,
      pendingEmailCredential.password
    );
    await reauthenticateWithCredential(user, credential);

    // 2. Mettre à jour l'email dans Firebase Auth
    await updateEmail(user, newEmail);

    // 3. Forcer la synchronisation avec Firestore
    await forceEmailSync(userId, newEmail);

    // 4. Réauthentifier avec le nouvel email
    const newCredential = EmailAuthProvider.credential(
      newEmail,
      pendingEmailCredential.password
    );
    await signInWithCredential(auth, newCredential);

    // 5. Double vérification de la synchronisation
    const finalCheck = await getDoc(userRef);
    if (finalCheck.data()?.email !== newEmail.toLowerCase()) {
      // Si la synchronisation a échoué, réessayer une dernière fois
      await forceEmailSync(userId, newEmail);
    }

  } catch (err: any) {
    console.error('Error verifying and updating email:', err);
    throw new Error(err.message || 'Erreur lors de la mise à jour de l\'email');
  }
};

// Send email verification link
const sendEmailVerificationLink = async (userId: string, newEmail: string): Promise<void> => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('Utilisateur non connecté');

    // Envoyer l'email de vérification
    await verifyBeforeUpdateEmail(user, newEmail);

  } catch (err: any) {
    console.error('Error sending verification email:', err);
    throw new Error(err.message || 'Erreur lors de l\'envoi de l\'email de vérification');
  }
};

// Ajouter un listener pour les changements de token
onIdTokenChanged(auth, async (user) => {
  if (user) {
    try {
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);
      
      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (userData.email !== user.email?.toLowerCase()) {
          await forceEmailSync(user.uid, user.email!);
        }
      }
    } catch (error) {
      console.error('Error in onIdTokenChanged:', error);
    }
  }
});

// Fonction pour gérer la mise à jour de l'email
const handleEmailUpdate = async (userId: string, newEmail: string): Promise<void> => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('Utilisateur non connecté');

    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) throw new Error('Utilisateur non trouvé');

    const userData = userDoc.data();
    const pendingEmail = userData.pendingEmail;
    const pendingEmailCredential = userData.pendingEmailCredential;

    if (!pendingEmail || !pendingEmailCredential || pendingEmail !== newEmail) {
      throw new Error('Informations de vérification invalides');
    }

    // 1. Réauthentifier l'utilisateur avec les anciennes informations
    const credential = EmailAuthProvider.credential(
      pendingEmailCredential.email,
      pendingEmailCredential.password
    );
    await reauthenticateWithCredential(user, credential);

    // 2. Mettre à jour l'email dans Firebase Auth
    await updateEmail(user, newEmail);

    // 3. Mettre à jour Firestore
    await updateDoc(doc(db, 'users', userId), {
      email: newEmail.toLowerCase(),
      emailUpdatedAt: new Date().toISOString(),
      isEmailVerified: true,
      pendingEmail: null,
      pendingEmailTimestamp: null,
      pendingEmailCredential: null
    });

    // 4. Réauthentifier avec le nouvel email
    const newCredential = EmailAuthProvider.credential(
      newEmail,
      pendingEmailCredential.password
    );
    await signInWithCredential(auth, newCredential);

  } catch (err: any) {
    console.error('Error updating email:', err);
    throw new Error(err.message || 'Erreur lors de la mise à jour de l\'email');
  }
};

// Fonction pour initier le changement d'email
const initiateEmailChange = async (userId: string, newEmail: string, currentPassword: string): Promise<void> => {
  try {
    const user = auth.currentUser;
    if (!user) {
      throw new Error('Utilisateur non connecté');
    }

    // 1. Vérifier le mot de passe actuel
    try {
      const credential = EmailAuthProvider.credential(user.email!, currentPassword);
      await reauthenticateWithCredential(user, credential);
    } catch (error) {
      console.error('Erreur de vérification mot de passe:', error);
      throw new Error('Mot de passe incorrect');
    }

    // 2. Vérifier si le nouvel email existe déjà
    const emailExists = await checkEmailExists(newEmail);
    if (emailExists) {
      throw new Error('Cette adresse email est déjà utilisée');
    }

    // 3. Stocker les informations dans Firestore
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      pendingEmail: newEmail.toLowerCase(),
      pendingEmailTimestamp: new Date().toISOString(),
      pendingEmailCredential: {
        email: user.email,
        password: currentPassword
      }
    });

    // 4. Envoyer l'email de vérification
    await verifyBeforeUpdateEmail(user, newEmail, {
      url: 'https://app.qvee.fr/__/auth/action',
      handleCodeInApp: true
    });

  } catch (error: any) {
    console.error('Error in initiateEmailChange:', error);
    throw error;
  }
};

// Modifier la fonction handleEmailActionURL pour utiliser la nouvelle synchronisation
const handleEmailActionURL = async (mode: string, actionCode: string): Promise<{ success: boolean; error?: string }> => {
  try {
    if (mode !== 'verifyAndChangeEmail') {
      return { success: false, error: 'Mode non supporté' };
    }

    // 1. Récupérer l'ID utilisateur du localStorage
    const userId = localStorage.getItem('pendingEmailUserId');
    if (!userId) {
      throw new Error('Session expirée');
    }

    // 2. Récupérer les données utilisateur de Firestore
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      throw new Error('Utilisateur non trouvé');
    }

    const userData = userDoc.data();
    const pendingEmail = userData.pendingEmail;
    const pendingEmailCredential = userData.pendingEmailCredential;

    if (!pendingEmail || !pendingEmailCredential) {
      throw new Error('Aucun email en attente trouvé');
    }

    // 3. Appliquer le code d'action pour vérifier l'email
    await applyActionCode(auth, actionCode);

    // 4. Mettre à jour Firestore avec le nouvel email
    await updateDoc(doc(db, 'users', userId), {
      email: pendingEmail.toLowerCase(),
      emailUpdatedAt: new Date().toISOString(),
      isEmailVerified: true,
      pendingEmail: null,
      pendingEmailTimestamp: null,
      pendingEmailCredential: null
    });

    // 5. Nettoyer le localStorage
    localStorage.removeItem('pendingEmailUserId');

    return { success: true };
  } catch (error: any) {
    console.error('Error in handleEmailActionURL:', error);
    return { 
      success: false, 
      error: error.message || 'Une erreur est survenue lors de la mise à jour de l\'email'
    };
  }
};

// Fonction pour envoyer un email de vérification 2FA
const send2FAEmail = async (userId: string): Promise<{ success: boolean; message?: string }> => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      throw new Error('Utilisateur non trouvé');
    }

    const userData = userDoc.data();
    const code = generateVerificationCode();
    const expiresAt = new Date();
    expiresAt.setMinutes(expiresAt.getMinutes() + 10);

    await updateDoc(doc(db, 'users', userId), {
      twoFactorCode: code,
      twoFactorCodeExpiresAt: expiresAt.toISOString()
    });

    // Utiliser la fonction de Brevo pour envoyer l'email
    await sendBrevo2FAEmail(userData.email, userData.firstName, code);

    return { success: true };
  } catch (err: any) {
    console.error('Error sending 2FA email:', err);
    return { success: false, message: err.message || 'Erreur lors de l\'envoi du code' };
  }
};

// Fonction pour envoyer un email de vérification 2FA à l'administrateur
const send2FACode = async (userId: string): Promise<{ success: boolean; message?: string }> => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      throw new Error('Utilisateur non trouvé');
    }

    const userData = userDoc.data();
    const code = generateVerificationCode();
    const expiresAt = new Date();
    expiresAt.setMinutes(expiresAt.getMinutes() + 10);

    await updateDoc(doc(db, 'users', userId), {
      twoFactorCode: code,
      twoFactorCodeExpiresAt: expiresAt.toISOString()
    });

    await send2FAEmail(userData.email, userData.firstName, code);

    return { success: true };
  } catch (err: any) {
    console.error('Error sending 2FA code:', err);
    return { success: false, message: err.message || 'Erreur lors de l\'envoi du code' };
  }
};

// Export all functions and instances
export {
  db,
  auth,
  storage,
  generateVerificationCode,
  generateSlug,
  sendSuspendedUserNotification,
  initiatePasswordReset,
  verifyResetCode,
  completePasswordReset,
  setAdminRole,
  sendVerificationCode,
  verifyEmailCode,
  sendTwoFactorCode,
  verifyTwoFactorCode,
  sendTwoFactorSetupCode,
  verifyTwoFactorSetupCode,
  createUserAccount,
  updateEmail,
  EmailAuthProvider,
  reauthenticateWithCredential,
  verifyBeforeUpdateEmail,
  verifyAndUpdateEmail,
  sendEmailVerificationLink,
  doc,
  getDoc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  deleteDoc,
  serverTimestamp,
  checkEmailExists,
  setDoc,
  sendEmailVerificationCode,
  signInWithCredential,
  sendDeletionCode,
  verifyDeletionCode,
  sendPasswordResetEmail,
  sendQRCodeDeletionCode,
  verifyQRCodeDeletionCode,
  handleEmailUpdate,
  initiateEmailChange,
  verifyCurrentPassword,
  syncEmailWithFirestore,
  forceEmailSync,
  handleEmailActionURL,
  send2FAEmail,
  send2FACode,
  initializeAdminDocument
};