import React, { forwardRef, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { RECAPTCHA_ENABLED, RECAPTCHA_SITE_KEY } from '../lib/config';

interface ReCaptchaProps {
  onChange: (token: string | null) => void;
  className?: string;
}

const ReCaptcha = forwardRef<ReCAPTCHA, ReCaptchaProps>(({ onChange, className = '' }, ref) => {
  if (!RECAPTCHA_ENABLED) {
    // Call onChange immediately with 'disabled' when component mounts
    useEffect(() => {
      onChange('disabled');
    }, []);
    // Don't render anything when disabled
    return null;
  }

  return (
    <div className={className}>
      <ReCAPTCHA
        ref={ref}
        sitekey={RECAPTCHA_SITE_KEY}
        onChange={onChange}
        theme="light"
        size="normal"
      />
    </div>
  );
});

ReCaptcha.displayName = 'ReCaptcha';

export default ReCaptcha;