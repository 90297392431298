import { GOOGLE_TRANSLATE_API_KEY } from '../lib/config';

interface TranslationResult {
  success: boolean;
  translatedText?: string;
  error?: string;
}

export class TranslationService {
  private static readonly API_URL = 'https://translation.googleapis.com/language/translate/v2';
  private static readonly BATCH_SIZE = 50;
  private static readonly CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours
  private static readonly REQUEST_DELAY = 50; // ms between requests
  private static translationCache = new Map<string, { text: string; timestamp: number }>();
  private static initialRetryDelay = 1000; // Initial retry delay in ms
  private static maxRetries = 3;
  private static activeRequests = 0;
  private static MAX_CONCURRENT_REQUESTS = 5;

  private static getCacheKey(text: string, targetLang: string): string {
    return `${text}:${targetLang}`;
  }

  private static getCachedTranslation(text: string, targetLang: string): string | null {
    const key = this.getCacheKey(text, targetLang);
    const cached = this.translationCache.get(key);
    
    if (cached && Date.now() - cached.timestamp < this.CACHE_DURATION) {
      return cached.text;
    }
    
    return null;
  }

  private static setCachedTranslation(text: string, targetLang: string, translatedText: string): void {
    const key = this.getCacheKey(text, targetLang);
    this.translationCache.set(key, {
      text: translatedText,
      timestamp: Date.now()
    });
  }

  private static async retryWithBackoff(
    fn: () => Promise<any>, 
    retries: number = this.maxRetries,
    retryDelay: number = this.initialRetryDelay,
    onError?: (error: any) => void
  ): Promise<any> {
    try {
      return await fn();
    } catch (error) {
      if (onError) {
        onError(error);
      }
      
      if (retries === 0) throw error;
      
      await new Promise(resolve => setTimeout(resolve, retryDelay));
      const nextDelay = retryDelay * 2; // Exponential backoff
      
      return this.retryWithBackoff(fn, retries - 1, nextDelay, onError);
    }
  }

  private static async waitForAvailableSlot(): Promise<void> {
    while (this.activeRequests >= this.MAX_CONCURRENT_REQUESTS) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
    this.activeRequests++;
  }

  private static releaseSlot(): void {
    this.activeRequests = Math.max(0, this.activeRequests - 1);
  }

  static async translateText(text: string, targetLang: string): Promise<TranslationResult> {
    if (!GOOGLE_TRANSLATE_API_KEY) {
      return {
        success: false,
        error: 'API key missing'
      };
    }

    // Skip empty text
    if (!text?.trim()) {
      return {
        success: true,
        translatedText: text || ''
      };
    }

    // Skip translation for elements with specific classes/attributes
    const skipTranslation = (text: string): boolean => {
      // Skip empty text or numbers
      if (!text?.trim() || /^\d+([.,]\d+)?$/.test(text)) {
        return true;
      }

      // Skip French language
      if (targetLang === 'fr') {
        return true;
      }

      // Skip elements with notranslate class or translate="no" attribute
      const noTranslateElements = document.querySelectorAll('.notranslate, [translate="no"]');
      for (const el of noTranslateElements) {
        if (el.textContent?.trim() === text.trim()) {
          return true;
        }
      }

      return false;
    };

    if (skipTranslation(text)) {
      return {
        success: true,
        translatedText: text
      };
    }

    // Check cache first
    const cached = this.getCachedTranslation(text, targetLang);
    if (cached) {
      return {
        success: true,
        translatedText: cached
      };
    }

    try {
      const translate = async () => {
        const response = await fetch(`${this.API_URL}?key=${GOOGLE_TRANSLATE_API_KEY}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            q: text,
            target: targetLang,
            format: 'text'
          })
        });

        if (!response.ok) {
          const errorData = await response.json().catch(() => ({}));
          throw new Error(errorData.error?.message || `HTTP error ${response.status}`);
        }

        const data = await response.json();
        
        if (!data?.data?.translations?.[0]?.translatedText) {
          throw new Error('Invalid translation response');
        }

        return data.data.translations[0].translatedText;
      };

      const translatedText = await this.retryWithBackoff(translate);
      
      // Cache successful translation
      this.setCachedTranslation(text, targetLang, translatedText);

      return {
        success: true,
        translatedText
      };

    } catch (error) {
      // Log error but don't show to user unless critical
      if (process.env.NODE_ENV === 'development') {
        console.error('Translation error:', error);
      }
      return {
        success: false,
        translatedText: text // Return original text on error
      };
    }
  }

  static async translateBatch(
    texts: string[], 
    targetLang: string,
    onProgress?: (progress: number) => void,
    onError?: (error: any) => void
  ): Promise<TranslationResult[]> {
    // Filter out empty/invalid texts
    const validTexts = texts.filter(text => text?.trim());
    
    if (validTexts.length === 0) {
      return [];
    }

    // Process in smaller batches to avoid rate limits
    const results: TranslationResult[] = new Array(validTexts.length);
    const totalTexts = validTexts.length;
    let processedTexts = 0;
    
    for (let i = 0; i < validTexts.length; i += this.BATCH_SIZE) {
      const batch = validTexts.slice(i, i + this.BATCH_SIZE);
      
      try {
        // Wait for available request slot
        await this.waitForAvailableSlot();

        // Translate batch with controlled concurrency
        const batchPromises = batch.map(async (text, index) => {
          try {
            await new Promise(resolve => setTimeout(resolve, index * this.REQUEST_DELAY));
            const result = await this.translateText(text, targetLang);
            processedTexts++;
            onProgress?.(Math.round((processedTexts / totalTexts) * 100));
            results[i + index] = result;
          } catch (error) {
            onError?.(error);
            results[i + index] = {
              success: false,
              error: 'Translation failed'
            };
          }
        });

        await Promise.all(batchPromises);
      } finally {
        this.releaseSlot();
      }
    }

    return results;
  }

  static async detectLanguage(text: string): Promise<string> {
    if (!text?.trim()) return 'fr';

    try {
      const detect = async () => {
        const response = await fetch(`${this.API_URL}/detect`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            q: text,
            key: GOOGLE_TRANSLATE_API_KEY
          })
        });

        if (!response.ok) {
          throw new Error(`Language detection failed: ${response.status}`);
        }

        const data = await response.json();
        
        if (!data?.data?.detections?.[0]?.[0]?.language) {
          throw new Error('Invalid language detection response');
        }
        
        return data.data.detections[0][0].language;
      };

      return await this.retryWithBackoff(detect);
    } catch (error) {
      console.error('Language detection error:', error);
      return 'fr'; // Default to French if detection fails
    }
  }
}