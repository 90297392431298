import React, { useState, useEffect, useMemo } from 'react';
import { BarChart3, ArrowUp, ArrowDown, ChevronDown, Calendar, Wine } from 'lucide-react';
import { useAuthContext } from '../../contexts/AuthContext';
import { useStatistics } from '../../hooks/useStatistics';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import CuveeStatsModal from './CuveeStatsModal';
import { WINE_TYPES } from '../../constants/wineData';

interface CuveeData {
  id: string;
  name: string;
  type: string;
  appellation: string;
  vintage: number;
  totalViews: number;
  qrScans: number;
  directViews: number;
  trend: number;
}

const CuveeVisitsChart: React.FC = () => {
  const { user } = useAuthContext();
  const { stats, loading, error } = useStatistics(user?.uid);
  const [cuveeData, setCuveeData] = useState<CuveeData[]>([]);
  const [yearFilter, setYearFilter] = useState<string>('all');
  const [wineTypeFilter, setWineTypeFilter] = useState<string>('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCuvee, setSelectedCuvee] = useState<CuveeData | null>(null);
  const [monthlyStats, setMonthlyStats] = useState<any[]>([]);
  
  // Récupérer les années disponibles
  const years = useMemo(() => {
    const uniqueYears = new Set<string>();
    cuveeData.forEach(cuvee => {
      const year = new Date().getFullYear().toString();
      uniqueYears.add(year);
    });
    return Array.from(uniqueYears).sort((a, b) => b.localeCompare(a));
  }, [cuveeData]);

  const onCuveeClick = async (cuvee: CuveeData) => {
    setSelectedCuvee(cuvee);
  };

  useEffect(() => {
    const fetchCuveeDetails = async () => {
      if (!user?.uid || !stats.length) return;

      try {
        // Récupérer les détails des cuvées
        const cuveesRef = collection(db, 'cuvees');
        const q = query(
          cuveesRef,
          where('userId', '==', user.uid)
        );
        const cuveesSnapshot = await getDocs(q);
        
        // Mapper les données
        const data: CuveeData[] = cuveesSnapshot.docs.map(doc => {
          const cuvee = doc.data();
          const cuveeStats = stats.find(s => s.cuveeId === doc.id);
          
          // Calculer la tendance (% de changement sur les 7 derniers jours)
          let trend = 0;
          if (cuveeStats) {
            const today = new Date();
            const last7Days = Array.from({length: 7}, (_, i) => {
              const date = new Date(today);
              date.setDate(date.getDate() - i);
              return date.toISOString().split('T')[0];
            });

            const recentViews = last7Days.reduce((sum, date) => 
              sum + (cuveeStats.dailyStats[date]?.views || 0), 0
            );
            
            const previousViews = last7Days.reduce((sum, date) => {
              const prevDate = new Date(date);
              prevDate.setDate(prevDate.getDate() - 7);
              const prevDateStr = prevDate.toISOString().split('T')[0];
              return sum + (cuveeStats.dailyStats[prevDateStr]?.views || 0);
            }, 0);

            trend = previousViews ? ((recentViews - previousViews) / previousViews) * 100 : 0;
          }

          return {
            id: doc.id,
            name: cuvee.name,
            type: cuvee.type,
            appellation: cuvee.appellation,
            vintage: cuvee.vintage,
            totalViews: cuveeStats?.totalViews || 0,
            qrScans: cuveeStats?.qrScans || 0,
            directViews: cuveeStats?.directViews || 0,
            trend
          };
        });

        // Trier par nombre total de vues
        data.sort((a, b) => b.totalViews - a.totalViews);
        setCuveeData(data);
      } catch (err) {
        console.error('Error fetching cuvee details:', err);
      }
    };

    fetchCuveeDetails();
  }, [user, stats]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-80">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-600 py-8">
        Une erreur est survenue lors du chargement des statistiques
      </div>
    );
  }

  if (!cuveeData.length) {
    return (
      <div className="text-center py-8">
        <BarChart3 className="h-12 w-12 mx-auto text-gray-400 mb-4" />
        <p className="text-gray-500">
          {searchTerm ? 'Aucune cuvée trouvée' : 'Aucune donnée disponible'}
        </p>
      </div>
    );
  }

  const maxViews = Math.max(...cuveeData.map(d => d.totalViews));

  // Filtrer les cuvées en fonction du terme de recherche
  const filteredCuvees = cuveeData.filter(cuvee =>
    cuvee.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (wineTypeFilter === 'all' || cuvee.type === wineTypeFilter) &&
    (yearFilter === 'all' || cuvee.vintage.toString() === yearFilter)
  );
  return (
    <div className="space-y-4">
      {selectedCuvee && (
        <CuveeStatsModal
          isOpen={!!selectedCuvee}
          onClose={() => setSelectedCuvee(null)}
          cuveeName={selectedCuvee.name}
          cuveeId={selectedCuvee.id}
        />
      )}

      <div className="flex items-center justify-between mb-6">
        <h3 className="text-lg font-medium text-gray-900">
          Visites par cuvée
        </h3>
        <div className="flex flex-wrap gap-3 items-center">
          <div className="relative flex-grow sm:flex-grow-0 min-w-[200px] sm:min-w-0">
            <input
              type="text"
              placeholder="Rechercher une cuvée..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-3 pr-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
            />
          </div>
          <div className="relative flex-grow sm:flex-grow-0 min-w-[150px] sm:min-w-0">
            <select
              value={wineTypeFilter}
              onChange={(e) => setWineTypeFilter(e.target.value)}
              className="w-full appearance-none pl-3 pr-10 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
            >
              <option value="all">Tous les types</option>
              {WINE_TYPES.map(type => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <Wine className="h-4 w-4 text-gray-400" />
            </div>
          </div>
          <div className="relative flex-grow sm:flex-grow-0 min-w-[150px] sm:min-w-0">
            <select
              value={yearFilter}
              onChange={(e) => setYearFilter(e.target.value)}
              className="w-full appearance-none pl-3 pr-10 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
            >
              <option value="all">Toutes les années</option>
              {years.map(year => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <Calendar className="h-4 w-4 text-gray-400" />
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredCuvees.map((cuvee) => (
          <div 
            key={cuvee.id} 
            className="bg-white rounded-lg p-6 border border-gray-200 hover:border-purple-300 hover:shadow-md transition-all duration-200 cursor-pointer"
            onClick={() => onCuveeClick(cuvee)}
          >
            <div className="mb-4">
              <h4 className="font-medium text-gray-900 hover:text-purple-600 transition-colors mb-2">
                {cuvee.name}
              </h4>
              <div className="flex items-center justify-between">
                <div className="flex flex-wrap gap-2">
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800">
                    {cuvee.vintage}
                  </span>
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                    {cuvee.type}
                  </span>
                  {cuvee.appellation && (
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                      {cuvee.appellation}
                    </span>
                  )}
                </div>
                <div className={`flex items-center px-2 py-1 rounded-full text-sm ${
                  cuvee.trend > 0 
                    ? 'bg-green-100 text-green-800' 
                    : cuvee.trend < 0 
                    ? 'bg-red-100 text-red-800' 
                    : 'bg-gray-100 text-gray-800'
                }`}>
                  {cuvee.trend > 0 ? <ArrowUp className="h-4 w-4 mr-1" /> : 
                   cuvee.trend < 0 ? <ArrowDown className="h-4 w-4 mr-1" /> : null}
                  <span>{Math.abs(Math.round(cuvee.trend))}%</span>
                </div>
              </div>
            </div>

            <div className="space-y-4">
              <div>
                <div className="flex justify-between text-sm text-gray-600 mb-1">
                  <span className="font-medium">Visites totales</span>
                  <span className="font-medium">{cuvee.totalViews.toLocaleString()}</span>
                </div>
                <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
                  <div
                    className="h-full bg-gradient-to-r from-purple-500 to-purple-600 rounded-full transition-all duration-500"
                    style={{ width: `${(cuvee.totalViews / maxViews) * 100}%` }}
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <div className="text-2xl font-bold bg-gradient-to-r from-purple-500 to-purple-600 bg-clip-text text-transparent">
                    {cuvee.qrScans.toLocaleString()}
                  </div>
                  <div className="text-sm text-gray-500">Scans QR</div>
                </div>
                <div>
                  <div className="text-2xl font-bold bg-gradient-to-r from-purple-500 to-purple-600 bg-clip-text text-transparent">
                    {cuvee.directViews.toLocaleString()}
                  </div>
                  <div className="text-sm text-gray-500">Vues directes</div>
                </div>
              </div>

              <div className="pt-4 border-t border-gray-100">
                <div className="text-sm text-gray-500">
                  Taux de conversion QR : {((cuvee.qrScans / cuvee.totalViews) * 100).toFixed(1)}%
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CuveeVisitsChart;