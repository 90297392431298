import React from 'react';
import { Link } from 'react-router-dom';
import { 
  BarChart3, 
  Palette, 
  QrCode, 
  LifeBuoy, 
  Zap, 
  CheckCircle2,
  ArrowRight,
  Wine
} from 'lucide-react';

const features = [
  {
    icon: BarChart3,
    title: 'Statistiques avancées',
    description: 'Suivez les performances de vos cuvées avec des analyses détaillées et des rapports personnalisés'
  },
  {
    icon: Palette,
    title: 'Personnalisation complète',
    description: 'Créez des pages uniques pour vos cuvées avec des designs entièrement personnalisables'
  },
  {
    icon: QrCode,
    title: 'QR Codes premium',
    description: 'Générez des QR codes personnalisés avec vos couleurs et votre style'
  },
  {
    icon: LifeBuoy,
    title: 'Support prioritaire',
    description: "Bénéficiez d'une assistance dédiée et d'un support technique 24/7"
  }
];

const Upsells: React.FC = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-50 to-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <div className="text-center mb-16">
          <div className="flex justify-center mb-6">
            <div className="bg-purple-100 p-3 rounded-full">
              <Zap className="h-8 w-8 text-purple-600" />
            </div>
          </div>
          <h1 className="text-4xl font-extrabold text-gray-900 mb-4">
            Passez au niveau supérieur avec Premium
          </h1>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Débloquez toutes les fonctionnalités premium pour une gestion professionnelle de vos cuvées
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
          {features.map((feature) => (
            <div 
              key={feature.title}
              className="bg-white rounded-xl p-6 shadow-sm border border-gray-100 hover:border-purple-200 transition-colors duration-200"
            >
              <div className="flex items-center space-x-4">
                <div className="flex-shrink-0">
                  <div className="bg-purple-100 p-3 rounded-lg">
                    <feature.icon className="h-6 w-6 text-purple-600" />
                  </div>
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-1">
                    {feature.title}
                  </h3>
                  <p className="text-gray-600">
                    {feature.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="bg-gradient-to-br from-purple-600 to-purple-800 rounded-2xl p-8 text-white shadow-xl">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="mb-6 md:mb-0">
              <h2 className="text-3xl font-bold mb-4">Plan Premium</h2>
              <div className="flex items-baseline mb-4">
                <span className="text-5xl font-extrabold">25€</span>
                <span className="text-xl ml-2">/mois</span>
              </div>
              <ul className="space-y-3">
                <li className="flex items-center">
                  <CheckCircle2 className="h-5 w-5 mr-2 text-purple-200" />
                  <span>Cuvées illimitées</span>
                </li>
                <li className="flex items-center">
                  <CheckCircle2 className="h-5 w-5 mr-2 text-purple-200" />
                  <span>Traduction 24 langues</span>
                </li>
                <li className="flex items-center">
                  <CheckCircle2 className="h-5 w-5 mr-2 text-purple-200" />
                  <span>Design des cuvées personnalisées</span>
                </li>
                <li className="flex items-center">
                  <CheckCircle2 className="h-5 w-5 mr-2 text-purple-200" />
                  <span>Options avancées de QR Codes</span>
                </li>
                <li className="flex items-center">
                  <CheckCircle2 className="h-5 w-5 mr-2 text-purple-200" />
                  <span>Support en ligne 7j/7</span>
                </li>
                <li className="flex items-center">
                  <CheckCircle2 className="h-5 w-5 mr-2 text-purple-200" />
                  <span>Assistance technique dédiée</span>
                </li>
              </ul>
            </div>
            <div className="text-center md:text-right">
              <Link
                to="/subscription"
                className="inline-flex items-center justify-center px-8 py-4 bg-white text-purple-600 rounded-lg font-semibold hover:bg-purple-50 transition-colors duration-200"
              >
                Passer au Premium
                <ArrowRight className="ml-2 h-5 w-5" />
              </Link>
              <p className="mt-4 text-sm text-purple-200">
                Sans engagement - Annulez à tout moment
              </p>
            </div>
          </div>
        </div>

        <div className="mt-16 text-center">
          <div className="inline-flex items-center space-x-2 text-purple-600 hover:text-purple-700">
            <Wine className="h-5 w-5" />
            <Link to="/cuvees" className="text-sm font-medium">
              Retour à mes cuvées
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Upsells;