// Configuration Brevo
export const BREVO_API_KEY = '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';
export const BREVO_API_URL = 'https://api.brevo.com/v3/smtp/email';
export const LOGO_URL = 'https://qvee.fr/assets/logo-full.png';
export const FAVICON_URL = 'https://firebasestorage.googleapis.com/v0/b/qvee-1651e.appspot.com/o/qvee%2Ffavicon-qvee.png?alt=media';

// Configuration Firebase
export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyDQKSaKo7xU-VRDGdMEaLn4mv32W_x0CV4",
  authDomain: "qvee-1651e.firebaseapp.com",
  projectId: "qvee-1651e",
  storageBucket: "qvee-1651e.firebasestorage.app",
  messagingSenderId: "622152058291",
  appId: "1:622152058291:web:68713ddee7250819ccb7c6",
  functionsRegion: "europe-west1",
  stripeExtensionEndpoint: "https://europe-west1-qvee-1651e.cloudfunctions.net/ext-stripe-createCheckoutSession",
  actionCodeSettings: {
    url: 'https://app.qvee.fr/__/auth/action',
    handleCodeInApp: true,
    iOS: {
      bundleId: 'com.qvee.app'
    },
    android: {
      packageName: 'com.qvee.app',
      installApp: true,
      minimumVersion: '12'
    },
    dynamicLinkDomain: 'qvee.page.link'
  }
};

// Configuration reCAPTCHA
export const RECAPTCHA_ENABLED = import.meta.env.VITE_RECAPTCHA_ENABLED === 'true';
export const RECAPTCHA_SITE_KEY = import.meta.env.VITE_RECAPTCHA_SITE_KEY;

// Google Translate Configuration
export const GOOGLE_TRANSLATE_API_KEY = import.meta.env.VITE_GOOGLE_TRANSLATE_API_KEY;

// Anti-spam Configuration
export const ANTISPAM_ENABLED = import.meta.env.VITE_ANTISPAM_ENABLED === 'true';
export const MAX_LOGIN_ATTEMPTS = 5;
export const COOLDOWN_DURATION = 10 * 60 * 1000; // 10 minutes

// Stripe Configuration
export const STRIPE_PUBLIC_KEY = 'pk_test_RLJCuPyWHP87lTjzSqQVg9G9';
export const STRIPE_SECRET_KEY = 'sk_test_rMYGd09orOfE28VV9OMWQ1rI';
export const STRIPE_WEBHOOK_SECRET = 'whsec_yDJSd59bvcAP8ieK1dOf26gliIJ3ZNgY';
export const STRIPE_PORTAL_CONFIGURATION_ID = 'pc_1QXkwBEuyuu7S8hYobesK3mo';

export const STRIPE_PRODUCTS = {
  basic: {
    priceId: 'price_1QKoA5Euyuu7S8hY93k6fZuM',
    productId: 'prod_RDEdwGg1Kykgil',
    price: 1000, // 10€
    name: 'Basic',
    type: 'payment',
    description: 'Pour démarrer avec la gestion de vos cuvées',
    features: [
      'Jusqu\'à 5 cuvées',
      'Génération de QR codes',
      'Traduction 24 langues',
      'Design simplifié',
      'Support par email'
    ]
  },
  standard: {
    priceId: 'price_1QXkwMEuyuu7S8hY9vZ5uxXz',
    productId: 'prod_RQcA6KGJCQFu4g',
    price: 1500, // 15€/mois
    name: 'Standard',
    type: 'subscription',
    description: 'Pour les vignerons qui veulent plus de flexibilité',
    features: [
      'Cuvées illimitées',
      'Génération de QR Codes',
      'Traduction 24 langues',
      'Design simplifié',
      'Support par email'
    ]
  },
  premium: {
    priceId: 'price_1QXkwBEuyuu7S8hYobesK3mo',
    productId: 'prod_RQcA7cdN3pJi2x',
    price: 2500, // 25€/mois
    name: 'Premium',
    type: 'subscription',
    description: 'Pour une gestion professionnelle complète',
    features: [
      'Cuvées illimitées',
      'Génération de QR Codes',
      'Traduction 24 langues',
      'Support en ligne 7j/7',
      'Design des cuvées personnalisées',
      'Options avancées de QR Codes',
      'Statistiques avancées',
      'Assistance technique dédiée',
      'Mises à jour en avant-première'
    ]
  },
  gold: {
    priceId: 'gold_plan',
    productId: 'gold_product',
    price: 0,
    name: 'Gold',
    type: 'subscription',
    description: 'Plan partenaire exclusif',
    features: [
      'Cuvées illimitées',
      'Génération de QR Codes',
      'Traduction 24 langues',
      'Support en ligne 7j/7',
      'Design des cuvées personnalisées',
      'Options avancées de QR Codes',
      'Statistiques avancées',
      'Assistance technique dédiée',
      'Mises à jour en avant-première'
    ],
    hidden: true,
    partnerOnly: true
  }
};


// Firebase Configuration