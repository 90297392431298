import { useState, useEffect } from 'react';
import { doc, getDoc, collection, query, where, getDocs, updateDoc, onSnapshot, orderBy } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { CuveeStatus } from '../types/cuvee';
import { PagePublisher } from '../services/pagePublisher';

export interface PlanLimits {
  basic: number;
  standard: number;
  premium: number;
  gold: number;
}

export const PLAN_LIMITS: PlanLimits = {
  basic: 5,
  standard: Infinity,
  premium: Infinity,
  gold: Infinity
};

export type PlanType = 'basic' | 'standard' | 'premium' | 'gold';

export const PLAN_FEATURES = {
  basic: {
    customQrCodes: false,
    unlimitedCuvees: false,
    dashboard: false,
    support: false
  },
  standard: {
    customQrCodes: false,
    unlimitedCuvees: true,
    dashboard: false,
    support: false
  },
  premium: {
    customQrCodes: true,
    unlimitedCuvees: true,
    dashboard: true,
    design: true,
    goldAccess: true,
    support: true
  },
  gold: {
    customQrCodes: true,
    unlimitedCuvees: true,
    dashboard: true,
    design: true,
    goldAccess: true,
    support: true
  }
};
export const usePlanLimits = (userId: string | undefined) => {
  const [currentPlan, setCurrentPlan] = useState<PlanType>('basic');
  const [publishedCount, setPublishedCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [exceededCount, setExceededCount] = useState(0);
  const [isRepublishing, setIsRepublishing] = useState(false);
  const [hasCheckedStatus, setHasCheckedStatus] = useState(false);
  const [isProcessingPlanChange, setIsProcessingPlanChange] = useState(false);

  const hasUnlimitedCuvees = (plan: string) => {
    return ['gold', 'standard', 'premium'].includes(plan);
  };

  // Handle cuvees that exceed plan limits
  const handleExceededCuvees = async (userId: string) => {
    if (!userId || isProcessingPlanChange) return;

    // Check user's plan
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) return;
    const userPlan = userDoc.data().plan || 'basic';
    
    // Si l'utilisateur a un plan illimité, on ne fait rien
    if (hasUnlimitedCuvees(userPlan)) {
      setExceededCount(0);
      setHasCheckedStatus(true);
      return;
    }
    // Sinon on continue avec la logique du plan Basic
    
    try {
      console.log('Starting exceeded cuvees check...');
      const cuveesRef = collection(db, 'cuvees');
      
      // First, get all published cuvees
      const publishedSnapshot = await getDocs(query(
        cuveesRef,
        where('userId', '==', userId),
        where('status', '==', CuveeStatus.PUBLISHED)
      ));
      
      const totalPublished = publishedSnapshot.size;
      console.log(`Found ${totalPublished} published cuvees`);

      if (totalPublished <= 5) {
        console.log('Less than or equal to 5 cuvees, no action needed');
        setExceededCount(0);
        setHasCheckedStatus(true);
        return;
      }

      console.log('More than 5 cuvees found, processing excess...');
      // Sort cuvees by creation date
      const sortedCuvees = publishedSnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

      // Keep only the newest ones that exceed the limit
      const exceededCount = totalPublished - 5;
      const cuveesToExceed = sortedCuvees.slice(0, exceededCount);
      console.log(`Marking ${exceededCount} cuvees as exceeded`);
      
      for (const cuvee of cuveesToExceed) {
        try {
          const cuveeRef = doc(db, 'cuvees', cuvee.id);
          const updateData = {
            status: CuveeStatus.EXCEEDED,
            lastModified: new Date().toISOString(),
            exceededAt: new Date().toISOString()
          };
          
          await updateDoc(cuveeRef, updateData);
          console.log(`Updated cuvee ${cuvee.id} status to exceeded`);
          
          // Handle unpublication separately to avoid blocking on errors
          PagePublisher.handleCuveeUnpublication(cuvee.id).catch(err => {
            console.error(`Error unpublishing cuvee ${cuvee.id}:`, err);
          });

        } catch (err) {
          console.error(`Error marking cuvee ${cuvee.id} as exceeded:`, err);
        }
      }

      console.log(`Successfully processed ${exceededCount} exceeded cuvees`);
      setExceededCount(exceededCount);
      setHasCheckedStatus(true);

    } catch (err) {
      console.error('Error handling exceeded cuvées:', err);
      // Don't throw - we want to continue execution
      setHasCheckedStatus(true);
    }
  };

  useEffect(() => {
    if (!userId) {
      setLoading(false);
      return;
    }

    // Écouter les changements du plan utilisateur
    const userUnsubscribe = onSnapshot(doc(db, 'users', userId), async (userDoc) => {
      if (userDoc.exists()) {
        const newPlan = userDoc.data().plan || 'basic';
        const oldPlan = currentPlan;
        
        if (oldPlan !== newPlan) {
          setIsProcessingPlanChange(true);
          
          try {
            if (newPlan === 'basic') {
              await handleExceededCuvees(userId);
            }
          } catch (error) {
            console.error('Error handling plan change:', error);
          } finally {
            setIsProcessingPlanChange(false);
          }
        }
        
        setCurrentPlan(newPlan);
      }
    });

    // Écouter les changements dans les cuvées
    const cuveesUnsubscribe = onSnapshot(
      query(collection(db, 'cuvees'), where('userId', '==', userId)),
      async (snapshot) => {
        if (isProcessingPlanChange) return; // Skip cuvee updates during plan changes
        
        const publishedCount = snapshot.docs.filter(
          doc => doc.data().status === CuveeStatus.PUBLISHED
        ).length;
        
        setPublishedCount(publishedCount);
        
        // Vérifier les cuvées dépassées si plan Basic
        if (currentPlan === 'basic' && !isProcessingPlanChange) {
          await handleExceededCuvees(userId);
        }
        
        setLoading(false);
        setHasCheckedStatus(true);
      }
    );

    return () => {
      userUnsubscribe();
      cuveesUnsubscribe();
    };
  }, [userId]);

  const handlePlanDowngrade = async (newPlan: 'basic' | 'standard') => {
    if (!userId) return;

    const limit = PLAN_LIMITS[newPlan];

    try {
      // Get all published cuvées sorted by creation date (ascending)
      const cuveesRef = collection(db, 'cuvees');
      const q = query(
        cuveesRef,
        where('userId', '==', userId),
        where('status', '==', CuveeStatus.PUBLISHED),
        orderBy('createdAt', 'asc')
      );
      const snapshot = await getDocs(q);
      const publishedCuvees = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Keep the oldest published cuvées up to the limit
      const toKeep = publishedCuvees.slice(0, limit);
      const toMarkAsExceeded = publishedCuvees.slice(limit);

      // Mark excess cuvées as exceeded and unpublish them
      for (const cuveeDoc of toMarkAsExceeded) {
        const cuveeRef = doc(db, 'cuvees', cuveeDoc.id);
        await updateDoc(cuveeRef, {
          status: CuveeStatus.EXCEEDED,
          lastModified: new Date().toISOString()
        });

        // Unpublish the associated page
        await PagePublisher.handleCuveeUnpublication(cuveeDoc.id);
      }

      return toMarkAsExceeded.length;
    } catch (err) {
      console.error('Error handling plan downgrade:', err);
      throw err;
    }
  };

  const canPublishMore = () => {
    // Users with unlimited plans can always publish more
    if (hasUnlimitedCuvees(currentPlan)) {
      console.log('User has unlimited plan, can publish more');
      return true;
    }

    const limit = PLAN_LIMITS[currentPlan];
    console.log('User has basic plan, checking limit:', limit, 'current count:', publishedCount);
    return publishedCount < limit;
  };

  const getRemainingSlots = () => {
    // Users with unlimited plans have unlimited slots
    if (hasUnlimitedCuvees(currentPlan)) return Infinity;

    const limit = PLAN_LIMITS[currentPlan];
    if (limit === Infinity) return Infinity;
    return Math.max(0, limit - publishedCount);
  };

  const hasFeature = (feature: keyof typeof PLAN_FEATURES[keyof typeof PLAN_FEATURES]) => {
    return PLAN_FEATURES[currentPlan][feature];
  };
  const getCurrentLimit = () => {
    return PLAN_LIMITS[currentPlan];
  };

  return {
    currentPlan,
    publishedCount,
    exceededCount,
    loading,
    canPublishMore,
    getRemainingSlots,
    getCurrentLimit,
    handlePlanDowngrade,
    hasFeature,
    hasCheckedStatus,
    hasUnlimitedCuvees,
    PLAN_LIMITS
  };
};