import { STRIPE_PRODUCTS } from '../lib/config';

export interface CreateCheckoutSessionData {
  plan: string;
  email: string;
  successUrl: string;
  cancelUrl: string;
  customerData: {
    userId: string;
    firstName: string;
    lastName: string;
    companyName: string;
    currentSubscriptionId?: string;
  };
}

export class StripeService {
  static async createCheckoutSession(data: CreateCheckoutSessionData): Promise<{ url: string; sessionId: string }> {
    try {
      // Validation des données
      if (!data.plan || !data.email || !data.successUrl || !data.cancelUrl || !data.customerData) {
        throw new Error('Données manquantes pour la création de la session');
      }

      const priceId = STRIPE_PRODUCTS[data.plan]?.priceId;
      if (!priceId) {
        throw new Error('Plan invalide');
      }

      console.log('Creating checkout session:', {
        priceId,
        email: data.email,
        customerData: data.customerData,
        plan: data.plan
      });

      const response = await fetch('/api/stripe/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          priceId,
          email: data.email,
          successUrl: data.successUrl,
          cancelUrl: data.cancelUrl,
          customerData: {
            ...data.customerData,
            plan: data.plan,
            currentSubscriptionId: data.customerData.currentSubscriptionId
          }
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Stripe API error response:', errorData);
        throw new Error(errorData.error || 'Erreur lors de la création de la session');
      }

      const session = await response.json();
      console.log('Checkout session created:', session);

      if (!session.url) {
        throw new Error('URL de paiement non disponible');
      }

      return {
        url: session.url,
        sessionId: session.id
      };
    } catch (error) {
      console.error('Error creating checkout session:', error);
      throw error;
    }
  }
}