import React, { useState } from 'react';
import { Calendar, ChevronDown } from 'lucide-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fr } from 'date-fns/locale';
import { format } from 'date-fns';
import TutorialPopover from './TutorialPopover';

interface Period {
  label: string;
  value: number;
  unit: 'days' | 'months' | 'years';
}

const periods: Period[] = [
  { label: '7 derniers jours', value: 7, unit: 'days' },
  { label: '30 derniers jours', value: 30, unit: 'days' },
  { label: '3 derniers mois', value: 3, unit: 'months' },
  { label: '12 derniers mois', value: 1, unit: 'years' }
];

const StatisticsHeader: React.FC = () => {
  const [selectedPeriod, setSelectedPeriod] = useState<Period>(periods[0]);
  const [showCustomPeriod, setShowCustomPeriod] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [customPeriodKey, setCustomPeriodKey] = useState(0);

  const handlePeriodSelect = (period: Period) => {
    setSelectedPeriod(period);
    setIsDropdownOpen(false);
    setShowCustomPeriod(false);
    setCustomPeriodKey(prev => prev + 1);
  };

  const handleCustomPeriodClick = () => {
    setShowCustomPeriod(true);
    setIsDropdownOpen(false);
    setCustomPeriodKey(prev => prev + 1);
  };

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <div className="flex flex-col md:flex-row md:items-center md:justify-between">
      <div>
        <div className="flex items-center space-x-2">
          <h1 className="text-2xl font-bold text-gray-900">Statistiques</h1>
          <TutorialPopover />
        </div>
        <p className="mt-1 text-sm text-gray-600">
          Suivez la performance de vos cuvées
        </p>
      </div>

      <div className="mt-4 md:mt-0 flex items-center space-x-4">
        <div className="relative">
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="inline-flex items-center justify-between w-48 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 z-10"
          >
            <span>{selectedPeriod.label}</span>
            <ChevronDown className="ml-2 h-4 w-4" />
          </button>

          {isDropdownOpen && (
            <div className="absolute z-10 mt-1 w-48 bg-white rounded-lg shadow-lg border border-gray-200">
              <div className="py-1">
                {periods.map((period) => (
                  <button
                    key={period.label}
                    onClick={() => handlePeriodSelect(period)}
                    className={`block w-full px-4 py-2 text-sm text-left hover:bg-gray-100 ${
                      selectedPeriod === period ? 'bg-purple-50 text-purple-700' : 'text-gray-700'
                    }`}
                  >
                    {period.label}
                  </button>
                ))}
                <button
                  onClick={handleCustomPeriodClick}
                  className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100"
                >
                  Période personnalisée
                </button>
              </div>
            </div>
          )}
        </div>

        {showCustomPeriod && (
          <div className="relative">
            <DatePicker
              selected={startDate}
              onChange={handleDateChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              locale={fr}
              dateFormat="dd/MM/yyyy"
              placeholderText="Sélectionner une période"
              className="w-64 px-4 py-2 text-sm border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
              customInput={
                <button className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-lg shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
                  <Calendar className="h-4 w-4 mr-2" />
                  {startDate && endDate
                    ? `${format(startDate, 'dd/MM/yyyy')} - ${format(endDate, 'dd/MM/yyyy')}`
                    : 'Sélectionner une période'}
                </button>
              }
              popperClassName="z-[1000]"
              popperPlacement="bottom-end"
              popperModifiers={[
                {
                  name: "offset",
                  options: {
                    offset: [0, 8]
                  }
                }
              ]}
              onChange={handleDateChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              locale={fr}
              dateFormat="dd/MM/yyyy"
              placeholderText="Sélectionner une période"
              className="w-64 px-4 py-2 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
              customInput={
                <button className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-lg text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
                  <Calendar className="h-4 w-4 mr-2" />
                  {startDate && endDate
                    ? `${format(startDate, 'dd/MM/yyyy')} - ${format(endDate, 'dd/MM/yyyy')}`
                    : 'Sélectionner une période'}
                </button>
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default StatisticsHeader;