import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { CuveeStats } from '../types/statistics';

export const useStatistics = (userId: string | undefined) => {
  const [stats, setStats] = useState<CuveeStats[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!userId) {
      setLoading(false);
      setStats([]);
      return;
    }

    try {
      // 1. D'abord, récupérer toutes les cuvées de l'utilisateur
      const fetchStats = async () => {
        setLoading(true);
        setError(null);

        const cuveesRef = collection(db, 'cuvees');
        const q = query(cuveesRef, where('userId', '==', userId));
        const cuveesSnapshot = await getDocs(q);
        const cuveeIds = cuveesSnapshot.docs.map(doc => doc.id);

        if (cuveeIds.length === 0) {
          setStats([]);
          setLoading(false);
          return () => {};
        }

        // 2. Écouter les changements des statistiques pour ces cuvées
        const statsRef = collection(db, 'cuveeStats');
        const unsubscribe = onSnapshot(
          query(statsRef, where('cuveeId', 'in', cuveeIds)),
          (snapshot) => {
            const statsData = snapshot.docs.map(doc => ({
              ...doc.data(),
              id: doc.id
            })) as CuveeStats[];
            
            setStats(statsData);
            setLoading(false);
            setError(null);
          },
          (err) => {
            console.error('Error fetching statistics:', err);
            setError('Erreur lors du chargement des statistiques');
            setLoading(false);
          }
        );

        return unsubscribe;
      };

      const unsubscribe = fetchStats();
      return () => {
        if (typeof unsubscribe === 'function') {
          unsubscribe();
        }
      };
    } catch (err) {
      console.error('Error in useStatistics:', err);
      setError('Erreur lors du chargement des statistiques');
      setLoading(false);
    }
  }, [userId]);

  return {
    stats,
    loading,
    error
  };
};