import React, { useState } from 'react';
import { ChevronLeft, CreditCard, Lock, AlertTriangle, Phone } from 'lucide-react';
import { SignupData } from '..';
import { STRIPE_PRODUCTS } from '../../../lib/config';

const DEV_PAY_ENABLED = import.meta.env.VITE_DEV_PAY_ENABLE === 'true';
const DEV_PAY_PASSWORD = import.meta.env.VITE_DEV_PAY_PASSWORD;

interface PaymentProps {
  data: SignupData;
  onBack: () => void;
  onComplete: (data: Partial<SignupData>) => void;
}

const Payment: React.FC<PaymentProps> = ({ data, onBack, onComplete }) => {
  const [error, setError] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<'stripe' | 'dev'>('stripe');
  const [devPassword, setDevPassword] = useState('');

  const handlePayment = async () => {
    if (isProcessing) return;
    setIsProcessing(true);
    setError(null);

    try {
      if (paymentMethod === 'dev') {
        if (!DEV_PAY_ENABLED) {
          throw new Error('Mode développement désactivé');
        }

        if (devPassword !== DEV_PAY_PASSWORD) {
          throw new Error('Mot de passe de développement incorrect');
        }

        // Complete signup with dev password
        onComplete(data);
        return;
      }

      if (!data.plan || !data.email || !data.firstName || !data.lastName || !data.companyName) {
        throw new Error('Informations manquantes pour la création du paiement');
      }

      const tempUserId = `temp_${Date.now()}`;
      
      sessionStorage.setItem('signupData', JSON.stringify({
        ...data,
        tempUserId,
        paymentMethod: 'stripe'
      }));

      // Redirect to Stripe Checkout
      window.location.href = '/signup?step=success';

    } catch (err) {
      console.error('Payment error:', err);
      const errorMessage = err instanceof Error ? err.message : 'Une erreur est survenue lors de l\'initialisation du paiement';
      setError(errorMessage);
      setIsProcessing(false);
    }
  };

  // Determine if the payment button should be disabled
  const isPaymentDisabled = isProcessing || 
    (paymentMethod === 'dev' && !devPassword) || 
    (paymentMethod === 'stripe'); // Disable when stripe is selected

  return (
    <div className="space-y-6">
      <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 rounded-lg">
        <div className="flex">
          <div className="flex-shrink-0">
            <AlertTriangle className="h-5 w-5 text-yellow-400" />
          </div>
          <div className="ml-3">
            <p className="text-sm text-yellow-700">
              Le paiement par carte bancaire n'est pas encore disponible. Veuillez contacter notre service client pour finaliser la création de votre compte.
            </p>
            <p className="mt-2 text-sm text-yellow-700 flex items-center">
              <Phone className="h-4 w-4 mr-2" />
              <a href="tel:+33608424680" className="font-medium hover:text-yellow-800">
                06 08 42 46 80
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 p-6 rounded-lg">
        <h3 className="text-lg font-medium text-gray-900 mb-4">
          Récapitulatif de la commande
        </h3>
        
        <div className="flex justify-between items-center">
          <div>
            <p className="text-sm font-medium text-gray-900">
              Plan {STRIPE_PRODUCTS[data.plan].name}
            </p>
            <p className="text-sm text-gray-500">
              Facturation mensuelle
            </p>
          </div>
          <p className="text-lg font-medium text-gray-900">
            {(STRIPE_PRODUCTS[data.plan].price / 100).toFixed(2)}€/mois
          </p>
        </div>
      </div>

      <div className="space-y-4">
        <div className="flex items-center space-x-4">
          <button
            onClick={() => setPaymentMethod('stripe')}
            className={`flex-1 p-4 rounded-lg border-2 transition-colors ${
              paymentMethod === 'stripe'
                ? 'border-purple-500 bg-purple-50'
                : 'border-gray-200 hover:border-purple-200'
            }`}
          >
            <div className="flex items-center space-x-3">
              <CreditCard className="h-5 w-5 text-gray-400" />
              <div className="text-left">
                <p className="font-medium text-gray-900">Carte bancaire</p>
                <p className="text-sm text-gray-500">Paiement sécurisé par Stripe</p>
              </div>
            </div>
          </button>

          {DEV_PAY_ENABLED && (
            <button
              onClick={() => setPaymentMethod('dev')}
              className={`flex-1 p-4 rounded-lg border-2 transition-colors ${
                paymentMethod === 'dev'
                  ? 'border-purple-500 bg-purple-50'
                  : 'border-gray-200 hover:border-purple-200'
              }`}
            >
              <div className="flex items-center space-x-3">
                <Lock className="h-5 w-5 text-gray-400" />
                <div className="text-left">
                  <p className="font-medium text-gray-900">Mode développeur</p>
                  <p className="text-sm text-gray-500">Accès avec mot de passe</p>
                </div>
              </div>
            </button>
          )}
        </div>

        {paymentMethod === 'dev' && DEV_PAY_ENABLED && (
          <div>
            <label htmlFor="devPassword" className="block text-sm font-medium text-gray-700 mb-2">
              Mot de passe développeur
            </label>
            <input
              type="password"
              id="devPassword"
              value={devPassword}
              onChange={(e) => setDevPassword(e.target.value)}
              className="block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
            />
          </div>
        )}
      </div>

      {error && (
        <div className="p-4 bg-red-50 border border-red-200 rounded-lg text-red-700 text-sm">
          {error}
        </div>
      )}

      <button
        onClick={handlePayment}
        disabled={isPaymentDisabled}
        className={`w-full btn flex justify-center items-center space-x-2 ${
          paymentMethod === 'stripe' ? 'opacity-50 cursor-not-allowed' : ''
        }`}
      >
        {paymentMethod === 'stripe' ? (
          <>
            <CreditCard className="h-4 w-4" />
            <span>
              {isProcessing ? 'Redirection...' : 'Procéder au paiement'}
            </span>
          </>
        ) : (
          <>
            <Lock className="h-4 w-4" />
            <span>
              {isProcessing ? 'Vérification...' : 'Valider'}
            </span>
          </>
        )}
      </button>

      <div className="flex justify-between pt-4">
        <button
          type="button"
          onClick={onBack}
          className="btn-secondary flex items-center space-x-2"
          disabled={isProcessing}
        >
          <ChevronLeft className="h-4 w-4" />
          <span>Retour</span>
        </button>
      </div>
    </div>
  );
};

export default Payment;