import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  Lock, 
  ArrowRight, 
  Calendar, 
  Wine, 
  AlertTriangle,
  Crown,
  CheckCircle2
} from 'lucide-react';
import { useAuthContext } from '../contexts/AuthContext';
import { collection, query, where, getDocs, orderBy, doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { CuveeStatus } from '../types/cuvee';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

const Exceeded = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [exceededCuvees, setExceededCuvees] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [userPlan, setUserPlan] = useState<string>('basic');

  useEffect(() => {
    const fetchExceededCuvees = async () => {
      if (!user?.uid) return;
      try {
        setLoading(true);
        
        try {
          // Vérifier d'abord le plan de l'utilisateur
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            const plan = userDoc.data().plan || 'basic';
            setUserPlan(plan);
            
            // Si l'utilisateur a un plan illimité, rediriger vers les cuvées
            if (['gold', 'standard', 'premium'].includes(plan)) {
              navigate('/cuvees');
              return;
            }
          }
        } catch (err) {
          console.error('Error fetching user plan:', err);
          // Continue with basic plan if error occurs
        }

        const cuveesRef = collection(db, 'cuvees');
        try {
          // Create a compound query with proper ordering
          const exceededQuery = query(
            cuveesRef,
            where('userId', '==', user.uid),
            where('status', '==', CuveeStatus.EXCEEDED),
            orderBy('exceededAt', 'desc')
          );

          const snapshot = await getDocs(exceededQuery);
          const cuvees = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));

          setExceededCuvees(cuvees);
        } catch (err) {
          console.error('Error fetching exceeded cuvees:', err);
          if (err.code === 'failed-precondition') {
            console.error('Index missing - please ensure the composite index is created');
          }
          // Set empty array to prevent UI issues
          setExceededCuvees([]);
        }
      } catch (error) {
        console.error('Error fetching exceeded cuvees:', error);
        setExceededCuvees([]);
      } finally {
        setLoading(false);
      }
    };

    fetchExceededCuvees();
  }, [user, navigate]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {/* Header Section */}
      <div className="text-center mb-12">
        <div className="mx-auto w-20 h-20 bg-yellow-100 rounded-full flex items-center justify-center mb-6">
          <Lock className="h-10 w-10 text-yellow-600" />
        </div>
        <h1 className="text-3xl font-bold text-gray-900 mb-4">
          Cuvées dépassées
        </h1>
        <p className="text-lg text-gray-600 max-w-2xl mx-auto space-y-2">
          <span className="block">
            {exceededCuvees.length} cuvée{exceededCuvees.length > 1 ? 's' : ''} n'est{exceededCuvees.length > 1 ? ' sont' : ''} plus accessible{exceededCuvees.length > 1 ? 's' : ''} en ligne
          </span>
          <span className="block text-sm text-yellow-600">
            Seules les 5 cuvées les plus anciennes restent publiées avec le plan Basic
          </span>
        </p>
      </div>

      {/* Explanation Card */}
      <div className="bg-gradient-to-br from-yellow-50 to-amber-50 rounded-xl shadow-sm border border-yellow-200 p-8 mb-12">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h2 className="text-xl font-semibold text-yellow-900 mb-4">
              Que s'est-il passé ?
            </h2>
            <div className="prose prose-yellow">
              <p className="text-yellow-800">
                Le plan Basic permet de publier jusqu'à 5 cuvées. Les cuvées dépassant cette limite ont été automatiquement désactivées et ne sont plus accessibles en ligne.
              </p>
              <div className="mt-6 space-y-4">
                <div className="flex items-start">
                  <AlertTriangle className="h-5 w-5 text-yellow-600 mt-0.5 mr-2 flex-shrink-0" />
                  <p className="text-yellow-700 text-sm">
                    Les QR codes associés à ces cuvées ne sont plus fonctionnels
                  </p>
                </div>
                <div className="flex items-start">
                  <Calendar className="h-5 w-5 text-yellow-600 mt-0.5 mr-2 flex-shrink-0" />
                  <p className="text-yellow-700 text-sm">
                    Les statistiques sont conservées et seront restaurées lors de la réactivation
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-xl p-6 shadow-sm">
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              Comment réactiver mes cuvées ?
            </h3>
            <ul className="space-y-4">
              <li className="flex items-start">
                <div className="flex-shrink-0 h-6 w-6 rounded-full bg-purple-100 flex items-center justify-center mr-3">
                  <Crown className="h-4 w-4 text-purple-600" />
                </div>
                <div>
                  <p className="text-gray-700">Passez à un plan supérieur</p>
                  <p className="text-sm text-gray-500">Standard ou Premium pour un nombre illimité de cuvées</p>
                </div>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 h-6 w-6 rounded-full bg-green-100 flex items-center justify-center mr-3">
                  <CheckCircle2 className="h-4 w-4 text-green-600" />
                </div>
                <div>
                  <p className="text-gray-700">Publication illimitée</p>
                  <p className="text-sm text-gray-500">Publiez autant de cuvées que vous le souhaitez</p>
                </div>
              </li>
            </ul>
            <div className="mt-6">
              <Link
                to="/subscription"
                className="w-full btn flex items-center justify-center space-x-2"
              >
                <span>Voir les plans disponibles</span>
                <ArrowRight className="h-4 w-4" />
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Exceeded Cuvees List */}
      <div className="bg-white rounded-lg shadow-sm">
        <div className="px-6 py-5 border-b border-gray-200">
          <h2 className="text-lg font-medium text-gray-900">
            Liste des cuvées dépassées
          </h2>
        </div>

        <div className="divide-y divide-gray-200">
          {exceededCuvees.map((cuvee) => (
            <div key={cuvee.id} className="p-6 hover:bg-gray-50">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-4">
                  <div className="h-12 w-12 bg-yellow-100 rounded-lg flex items-center justify-center">
                    <Wine className="h-6 w-6 text-yellow-600" />
                  </div>
                  <div>
                    <h3 className="text-lg font-medium text-gray-900">
                      {cuvee.name}
                    </h3>
                    <div className="mt-1 flex items-center space-x-2 text-sm text-gray-500">
                      <span>{cuvee.vintage}</span>
                      <span>•</span>
                      <span>{cuvee.type}</span>
                      {cuvee.appellation && (
                        <>
                          <span>•</span>
                          <span>{cuvee.appellation}</span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="text-right">
                  <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                    <Lock className="h-3 w-3 mr-1" />
                    Dépassée
                  </div>
                  <p className="mt-1 text-sm text-gray-500">
                    Depuis le {format(new Date(cuvee.exceededAt), 'dd MMMM yyyy', { locale: fr })}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Exceeded;