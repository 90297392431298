import { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';

interface WelcomeStats {
  firstName: string;
  publishedCuvees: number;
  totalQRCodes: number;
}

export const useWelcomeStats = (userId: string | undefined) => {
  const [stats, setStats] = useState<WelcomeStats>({
    firstName: '',
    publishedCuvees: 0,
    totalQRCodes: 0
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStats = async () => {
      if (!userId) {
        setLoading(false);
        return;
      }

      try {
        // Fetch user data for first name
        const userDoc = await getDocs(query(
          collection(db, 'users'),
          where('__name__', '==', userId)
        ));

        if (!userDoc.empty) {
          const userData = userDoc.docs[0].data();
          stats.firstName = userData.firstName || '';
        }

        // Fetch published cuvees count
        const cuveesSnapshot = await getDocs(query(
          collection(db, 'cuvees'),
          where('userId', '==', userId),
          where('status', '==', 'published')
        ));
        stats.publishedCuvees = cuveesSnapshot.size;

        // Fetch QR codes count
        const qrCodesSnapshot = await getDocs(query(
          collection(db, 'qrCodes'),
          where('userId', '==', userId)
        ));
        stats.totalQRCodes = qrCodesSnapshot.size;

        setStats({ ...stats });
      } catch (error) {
        console.error('Error fetching welcome stats:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [userId]);

  return { stats, loading };
};