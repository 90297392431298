import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { 
  Wine, 
  LogOut, 
  BarChart3, 
  QrCode, 
  Settings, 
  Palette, 
  Users,
  Shield,
  CreditCard,
  FileText,
  Bell,
  Lock,
  ArrowRight,
  LifeBuoy,
  Crown
} from 'lucide-react';
import { useAuthContext } from '../contexts/AuthContext';
import LogoutConfirmation from './LogoutConfirmation';
import { PLAN_FEATURES, PlanType } from '../hooks/usePlanLimits';
import { useTickets } from '../hooks/useTickets';
import { getDoc, doc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { CuveeStatus } from '../types/cuvee';

interface MobileMenuProps {
  isOpen: boolean;
  onClose: () => void;
  isAdmin: boolean;
  userProfile: any;
  userPlan: PlanType;
}

const MobileMenu: React.FC<MobileMenuProps> = ({
  isOpen,
  onClose,
  isAdmin,
  userProfile,
  userPlan = 'basic'
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuthContext();
  const { user } = useAuthContext();
  const { unreadCount } = useTickets(userProfile?.id, isAdmin);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [exceededCount, setExceededCount] = useState(0);
  const [exceededCuvees, setExceededCuvees] = useState(0);

  const adminMenuItems = [
    { icon: <BarChart3 size={20} />, label: 'Dashboard', path: '/admin/dashboard' },
    { icon: <Wine size={20} />, label: 'Cuvées', path: '/admin/cuvees' },
    { icon: <Users size={20} />, label: 'Utilisateurs', path: '/users' },
    { 
      icon: <LifeBuoy size={20} />, 
      label: 'Support', 
      path: '/admin/support',
      badge: unreadCount > 0 ? unreadCount : undefined
    },
    { icon: <Bell size={20} />, label: 'Notifications', path: '/admin/notifications' },
    { icon: <FileText size={20} />, label: 'Logs serveur', path: '/admin/logs' }
  ];

  const mainMenuItems = [
    { 
      icon: <BarChart3 size={20} />,
      label: 'Statistiques',
      path: '/statistics',
      requiresFeature: 'dashboard',
      redirectTo: '/upsells'
    },
    { icon: <Wine size={20} />, label: 'Mes Cuvées', path: '/cuvees' },
    { icon: <QrCode size={20} />, label: 'QR Codes', path: '/qr-codes' },
    { 
      icon: <Palette size={20} />, 
      label: 'Design', 
      path: '/design',
      requiresFeature: 'design',
      redirectTo: '/upsells'
    }
  ];

  const bottomMenuItems = [
    { 
      icon: <LifeBuoy size={20} />, 
      label: 'Support', 
      path: '/support',
      badge: unreadCount > 0 ? unreadCount : undefined,
      requiresFeature: 'support',
      redirectTo: '/upsells'
    },
    { icon: <CreditCard size={20} />, label: 'Abonnement', path: '/subscription' },
    { icon: <Settings size={20} />, label: 'Paramètres', path: '/settings' }
  ];

  useEffect(() => {
    if (!user?.uid) return;
    const checkAdminStatus = async () => {
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        // Vérifier les cuvées dépassées pour le plan Basic
        if (userDoc.data().plan === 'basic') {
          const cuveesRef = collection(db, 'cuvees');
          const q = query(
            cuveesRef,
            where('userId', '==', user.uid),
            where('status', '==', CuveeStatus.EXCEEDED)
          );
          const snapshot = await getDocs(q);
          setExceededCuvees(snapshot.size);
        }
      }
    };
    checkAdminStatus();
  }, [user]);

  const handleLogoutClick = () => {
    setShowLogoutConfirmation(true);
  };

  const handleLogoutConfirm = async () => {
    try {
      await logout();
      setShowLogoutConfirmation(false);
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 md:hidden">
      <div 
        className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
        onClick={onClose}
      />

      <div className="fixed inset-y-0 left-0 w-64 bg-white shadow-lg transform transition-transform duration-300 ease-in-out">
        <div className="h-16 flex items-center justify-between px-4 bg-purple-700">
          <div className="flex items-center space-x-2">
            {isAdmin ? (
              <>
                <Shield className="h-8 w-8 text-white" />
                <span className="text-white text-xl font-semibold">Admin</span>
              </>
            ) : (
              <>
                <Wine className="h-8 w-8 text-white" />
                <span className="text-white text-xl font-semibold">Qvee</span>
              </>
            )}
          </div>
        </div>

        <div className="p-4 border-b border-gray-200">
          <div>
            <div className="flex items-center justify-between mb-1">
              <p className="font-medium text-gray-900">
                {userProfile.firstName} {userProfile.lastName}
              </p>
              {exceededCuvees > 0 && userPlan === 'basic' && (
                <div className="flex items-center text-yellow-600">
                  <Lock className="h-4 w-4 mr-1" />
                  <span className="text-xs font-medium">
                    {exceededCuvees} cuvée{exceededCuvees > 1 ? 's' : ''} dépassée{exceededCuvees > 1 ? 's' : ''}
                  </span>
                </div>
              )}
              {isAdmin ? (
                <div className="flex items-center text-red-600">
                  <Shield className="h-4 w-4 mr-1" />
                  <span className="text-xs font-medium">Admin</span>
                </div>
              ) : userProfile.plan && (
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                  userProfile.plan === 'premium' 
                    ? 'bg-purple-100 text-purple-800'
                    : userProfile.plan === 'standard'
                    ? 'bg-blue-100 text-blue-800'
                    : userProfile.plan === 'gold'
                    ? 'bg-yellow-100 text-yellow-800 flex items-center'
                    : 'bg-gray-100 text-gray-800'
                }`}>
                  {userProfile.plan === 'gold' && (
                    <Crown className="h-3 w-3 mr-1" />
                  )}
                  {userProfile.plan.charAt(0).toUpperCase() + userProfile.plan.slice(1)}
                </span>
              )}
            </div>
            <p className="text-sm text-gray-500">{userProfile.email}</p>
          </div>
        </div>

        <nav className="flex-1 overflow-y-auto p-4 space-y-1">
          {(isAdmin ? adminMenuItems : mainMenuItems).map((item) => {
            const isActive = location.pathname === item.path;
            const isLocked = !isAdmin && item.requiresFeature && !PLAN_FEATURES[userPlan][item.requiresFeature as keyof typeof PLAN_FEATURES[PlanType]];

            return (
              <Link
                key={item.path}
                to={isLocked ? (item.redirectTo || '#') : item.path}
                onClick={onClose}
                className={`${
                  isActive
                    ? 'bg-purple-50 text-purple-700'
                    : isLocked
                    ? 'text-gray-400 bg-gray-50 cursor-not-allowed'
                    : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                } group flex items-center justify-between px-2 py-2 text-sm font-medium rounded-md transition-colors duration-150`}
              >
                <div className="flex items-center">
                  <div className="mr-3">{item.icon}</div>
                  <span>{item.label}</span>
                </div>
                {isLocked && <Lock className="h-4 w-4 text-[#9333EA]" />}
                {item.badge !== undefined && !isLocked && (
                  <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                    {item.badge}
                  </span>
                )}
              </Link>
            );
          })}
        </nav>

        {!isAdmin && (
          <div className="border-t border-gray-200 p-4 space-y-1">
            {bottomMenuItems.map((item) => {
              const isActive = location.pathname === item.path;
              const isLocked = !isAdmin && item.requiresFeature && !PLAN_FEATURES[userPlan][item.requiresFeature as keyof typeof PLAN_FEATURES[PlanType]];

              return (
                <Link
                  key={item.path}
                  to={isLocked ? (item.redirectTo || '#') : item.path}
                  onClick={onClose}
                  className={`${
                    isActive
                      ? 'bg-purple-50 text-purple-700'
                      : isLocked
                      ? 'text-gray-400 bg-gray-50 cursor-not-allowed'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                  } group flex items-center justify-between px-2 py-2 text-sm font-medium rounded-md transition-colors duration-150`}
                >
                  <div className="flex items-center">
                    <div className="mr-3">{item.icon}</div>
                    <span>{item.label}</span>
                    {item.path === '/cuvees' && exceededCount > 0 && ['standard', 'premium'].includes(userPlan) && (
                      <div className="ml-2 bg-yellow-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
                        {exceededCount}
                      </div>
                    )}
                  </div>
                  {isLocked && <Lock className="h-4 w-4 text-[#9333EA]" />}
                  {item.badge !== undefined && !isLocked && (
                    <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                      {item.badge}
                    </span>
                  )}
                </Link>
              );
            })}
          </div>
        )}

        <div className="p-4 border-t border-gray-200">
          <button
            onClick={handleLogoutClick}
            className="w-full flex items-center px-2 py-2 text-sm font-medium text-red-600 hover:bg-red-50 rounded-md transition-colors duration-150"
          >
            <LogOut className="mr-3 h-5 w-5" />
            Se déconnecter
          </button>
        </div>
      </div>

      <LogoutConfirmation
        isOpen={showLogoutConfirmation}
        onClose={() => setShowLogoutConfirmation(false)}
        onConfirm={handleLogoutConfirm}
      />
    </div>
  );
};

export default MobileMenu;